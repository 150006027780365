import Cookies from "universal-cookie";

import { instance } from "./authAPI";
import { TSystemInfo } from "../redux/types/system.types";

export const systemAPI = {
    getSystemInfo(cookies: Cookies) {
        return instance.get<TSystemInfo>(`/tenants/system-info/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};
