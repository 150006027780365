import Cookies from "universal-cookie";

import { instance } from "./authAPI";
import { processRequestQueryParams } from "./utils/apiUtils";
import { TWarehouse } from "../redux/types/WarehouseTypes/warehouse.types";
import { TGenericResponseFormat, TRequestLazySearchParams, TRequestQueryParams } from "../redux/types/common/common.types";
import {
    IncomingIntegrationResponseType,
    PrepareToShip,
    PrepareToShipResult,
    TBulkCommercialInvoiceItem,
    TBulkInboundUploadFileData,
} from "../redux/types/IncomingTypes/incoming.types";

export const incomingProduct = {
    getPrepareToShip(cookies: Cookies, integrationId: string, searchTerm: string, items: number, offset: number) {
        return instance.get<PrepareToShip>(
            `/shipments/prepare-to-ship/?integration_id=${integrationId}&limit=${items ? items : 20}&offset=${offset}${searchTerm ? `&search=${window.encodeURIComponent(searchTerm)}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    postPrepareToShip(cookies: Cookies, data: FormData) {
        return instance.post(`/shipments/prepare-to-ship/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
                "Content-Type": "multipart/form-data",
            },
        });
    },
    getIncomingIntegrations(cookies: Cookies, queryParams: Partial<{ limit: number }> & TRequestQueryParams) {
        return instance.get<unknown, { data: IncomingIntegrationResponseType }>(`/shipments/available_integrations_to_ship/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getBulkCommercialInvoiceProducts(cookies: Cookies, queryParams: TRequestLazySearchParams & Partial<{ integration_id: string }>) {
        return instance.get<TGenericResponseFormat<TBulkCommercialInvoiceItem>>(`/shipments/upload_commercial_invoice/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postBulkCommercialInvoice(cookies: Cookies, formData: FormData) {
        return instance.post<PrepareToShipResult[]>(`/shipments/upload_commercial_invoice/`, formData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getBulkSkuInboundTemplate(cookies: Cookies, integrationId: string) {
        return instance.get<string>(`/shipments/order-to-prep-file/?integration_id=${integrationId}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postBulkSkuInboundTemplate(cookies: Cookies, integrationId: string, formData: FormData) {
        return instance.post<TBulkInboundUploadFileData>(`/shipments/order-to-prep-file/?integration_id=${integrationId}`, formData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getIncomingAvailableWarehouses(cookies: Cookies) {
        return instance.get<TGenericResponseFormat<TWarehouse>>(`/warehouses/user-warehouse-storage/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};
