import axios from "axios";

import { displayError } from "../hooks/useErrorHandler";
import { supportRequestsAPI } from "../api/supportRequestsAPI";
import { TPlatformMember } from "./types/WarehouseTypes/warehouse.types";
import { TSetAdministrators, TSupportRequestsLoadingStates, TSupportRequestStateAction, TSupportRequestThunk } from "./types/supportRequests.types";

export const SET_ADMINISTRATORS = "SET_ADMINISTRATORS";
export const SET_SUPPORT_REQUESTS_LOADING_STATES = "SET_SUPPORT_REQUESTS_LOADING_STATES";

export type TSupportRequestsInitialState = {
    administrators: TPlatformMember[];
    loadingStates: TSupportRequestsLoadingStates;
};

const initialState: TSupportRequestsInitialState = {
    administrators: [],
    loadingStates: {
        isLoadingAdministrators: false,
    },
};

const supportRequestsReducer = (state = initialState, action: TSupportRequestStateAction): TSupportRequestsInitialState => {
    switch (action.type) {
        case SET_ADMINISTRATORS: {
            return {
                ...state,
                administrators: action.data,
            };
        }
        case SET_SUPPORT_REQUESTS_LOADING_STATES: {
            return {
                ...state,
                loadingStates: { ...state.loadingStates, ...action.data },
            };
        }
        default:
            return state;
    }
};

// Action Creators
export const SetAdministrators = (data: TPlatformMember[]): TSetAdministrators => ({
    type: SET_ADMINISTRATORS,
    data,
});

export const SetLoadingStates = (data: Partial<{ [key in keyof TSupportRequestsLoadingStates]: boolean }>): TSupportRequestStateAction => ({
    type: SET_SUPPORT_REQUESTS_LOADING_STATES,
    data,
});

// Thunks

export const getPlatformAdministrators = (): TSupportRequestThunk => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            dispatch(SetLoadingStates({ isLoadingAdministrators: true }));

            const response = await supportRequestsAPI.getAdministrators(cookies, { role: "ADMINISTRATOR", limit: 1000 });

            dispatch(SetAdministrators(response.data.results));

            dispatch(SetLoadingStates({ isLoadingAdministrators: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingAdministrators: false }));

                displayError(e.response.data);
            }
        }
    };
};

export default supportRequestsReducer;
