import { thunk } from "redux-thunk";
import { combineReducers } from "redux";
import { Tuple, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import authReducer from "./authReducer";
import portalReducer from "./portalReducer";
import trackingReducer from "./trackingReducer";
import affiliateReducer from "./affiliateReducer";
import productsReducer from "./addProductReducer";
import warehouseReducer from "./warehouseReducer";
import statisticsReducer from "./statisticsReducer";
import settingsProductReducer from "./settingsReducer";
import sendToAmazonReducer from "./sendToAmazonReducer";
import chargingRateReducer from "./chargingRatesReducer";
import manualQuotingReducer from "./manualQuotingReducer";
import warehouseSetUpReducer from "./adminWarehouseReducer";
import incomingProductReducer from "./incomingProductReducer";
import allShipmentsReducer from "./allShipmentsReducer";
import tenantDashboardReducer from "./tenantDashboardReducer";
import outboundShipmentsReducer from "./outboundShipmentsReducer";
import supportRequestsReducer from "./supportRequestsReducer";

let reducer = combineReducers({
    auth: authReducer,
    incomingProduct: incomingProductReducer,
    addProduct: productsReducer,
    warehouse: warehouseReducer,
    tenantDashboard: tenantDashboardReducer,
    settings: settingsProductReducer,
    warehouseSetUp: warehouseSetUpReducer,
    sendToAmazon: sendToAmazonReducer,
    rates: chargingRateReducer,
    portal: portalReducer,
    statistics: statisticsReducer,
    tracking: trackingReducer,
    affiliate: affiliateReducer,
    manualQuoting: manualQuotingReducer,
    allShipments: allShipmentsReducer,
    outboundShipments: outboundShipmentsReducer,
    supportRequests: supportRequestsReducer,
});

export type AppStateType = ReturnType<typeof reducer>;
export type AppDispatch = typeof store.dispatch;

let store = configureStore({ reducer, devTools: process.env.NODE_ENV !== "production", middleware: () => new Tuple(thunk) });

export const useAppSelector: TypedUseSelectorHook<AppStateType> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
