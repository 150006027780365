import skudropLogo from "../../../assets/img/LandingPage/FooterSection/SKUdrop_logo_light.png";
import { FaFacebookF } from "react-icons/fa";
import { IoLogoLinkedin } from "react-icons/io";
import { BsYoutube } from "react-icons/bs";
import { Link } from "react-scroll";
import { Link as LinkR, useNavigate } from "react-router-dom";
import { sendEmail } from "../../DashboardWarehouse/common/Constants";

const Footer = () => {
    const navigate = useNavigate();

    const margins = [
        { id: 1, title: "Features", link: "features" },
        { id: 2, title: "Case Studies", link: "case-study" },
        { id: 3, title: "Our Fees", link: "fees" },
        { id: 5, title: "Get a quote", link: "calculator" },
        { id: 4, title: "FAQ’s", link: "faq" },
    ];
    const toSection = (section: number) => {
        setTimeout(() => {
            document.getElementById(section.toString())?.click();
        }, 200);
    };
    const openLink = (link: string) => {
        window.open(link, "_blank");
    };

    return (
        <footer className="footer-bg d-flex justify-content-center align-items-end">
            <div className="pl-section w-100 ">
                <div className="w-100 footer">
                    <div className="footer-container-contacts">
                        <a href="/" className="footer-logo">
                            <img src={skudropLogo} width="305" alt="footer" />
                        </a>
                        <div className="footer-contacts-container">
                            <p className="footer-item ">We store & ship your goods directly to Amazon. Saving you money.</p>
                        </div>
                        <div className="footer-contacts-container">
                            <div className="contact-block" onClick={() => openLink("https://www.facebook.com/people/SKUDrop/100083516717950/")}>
                                <FaFacebookF className="footer-icon" color="#072447" style={{ width: 10, cursor: "pointer" }} />
                            </div>
                            <div className="contact-block ml-2" onClick={() => openLink("https://www.youtube.com/@skudrop/videos")}>
                                <BsYoutube className="footer-icon" color="#072447" style={{ width: 20, cursor: "pointer" }} />
                            </div>
                            <div className="contact-block ml-2" onClick={() => openLink("https://www.linkedin.com/company/skudrop/")}>
                                <IoLogoLinkedin className="footer-icon" color="#072447" style={{ width: 20, cursor: "pointer" }} />
                            </div>
                        </div>
                    </div>
                    <div className="footer-container">
                        <h3 className="footer-title">Useful Links</h3>
                        <ul className="footer-list p-0">
                            {window.location.pathname === "/"
                                ? margins.map((item) => (
                                      <li className="footer-item" key={item.id}>
                                          <Link
                                              to={item.link}
                                              smooth={true}
                                              duration={1000}
                                              offset={0}
                                              style={{
                                                  textDecoration: "none",
                                                  cursor: "pointer",
                                              }}
                                          >
                                              {item.title}
                                          </Link>
                                      </li>
                                  ))
                                : margins.map((item) => (
                                      <li className="footer-item" key={item.id}>
                                          <LinkR
                                              to="/"
                                              style={{
                                                  textDecoration: "none",
                                                  cursor: "pointer",
                                              }}
                                              onClick={() => toSection(item.id)}
                                          >
                                              {item.title}
                                          </LinkR>
                                      </li>
                                  ))}
                        </ul>
                    </div>
                    <div className="footer-container">
                        <h3 className="footer-title">Help</h3>
                        <ul className="footer-list p-0">
                            <li className="footer-item footer-link" onClick={() => navigate("/privacy-policy")}>
                                Privacy Policy
                            </li>
                            <li className="footer-item footer-link" onClick={() => navigate("/terms-conditions")}>
                                Terms and Conditions
                            </li>
                        </ul>
                    </div>
                    <div className="footer-container">
                        <h3 className="footer-title">Contact Us</h3>
                        <ul className="footer-list p-0">
                            <li className="footer-item footer-link" onClick={() => sendEmail("info@skudrop.com")}>
                                info@skudrop.com
                            </li>
                            <li className="footer-item footer-link" onClick={() => openLink("https://www.facebook.com/people/SKUDrop/100083516717950/")}>
                                Facebook
                            </li>
                            <li className="footer-item footer-link" onClick={() => openLink("https://www.linkedin.com/company/skudrop/")}>
                                Linked In
                            </li>
                            <li className="footer-item footer-link" onClick={() => openLink("https://www.youtube.com/@skudrop/videos")}>
                                YouTube
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="copyright-line w-80 mt-5">
                    <p className="footer-item">SKUdrop Copyright © 2022</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
