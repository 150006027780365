import { ReactNode, useState } from "react";
import { ToastOptions, toast } from "react-toastify";

export default function useErrorHandler(): [string | null | string[] | boolean, (data: string | null | string[] | boolean) => void, (data: any) => void] {
    const [error, setError] = useState<string | null | string[] | boolean>(null);

    const handleError = (data: any) => {
        if (typeof data.message === "string") {
            setError(data.message);
        }
        if (typeof data.non_field_errors === "string") {
            setError(data.non_field_errors);
        }
        if (Array.isArray(data.non_field_errors)) {
            setError(data.non_field_errors[0]);
        }
        if (typeof data === "string") {
            setError(data);
        }
        if (Array.isArray(data)) {
            setError(data);
        }
        console.log(data);
    };

    return [error, setError, handleError];
}

export function extractRequestErrors(data: any): string {
    if (!data) return "Something went wrong...";

    if (typeof data === "string") {
        return data;
    }

    if (Array.isArray(data)) {
        return typeof data[0] === "string" ? data[0] : "Something went wrong...";
    }

    if (typeof data === "object") {
        if (typeof data.message === "string") {
            return data.message;
        }

        if (typeof data.non_field_errors === "string") {
            return data.non_field_errors;
        }

        if (Array.isArray(data.non_field_errors)) {
            return data.non_field_errors.join(" \n");
        }

        if (data.detail) {
            return typeof data.detail === "string" ? data.detail : JSON.stringify(data.detail);
        }

        const errorMessages: string[] = [];

        const processErrors = (obj: any, path = ""): void => {
            Object.entries(obj).forEach(([key, value]) => {
                const fullPath = path ? `${path}.${key}` : key;
                if (Array.isArray(value) && value.every((item) => typeof item === "string")) {
                    errorMessages.push(`${fullPath}: ${value.join(", ")}`);
                } else if (typeof value === "object" && value !== null) {
                    processErrors(value, fullPath);
                }
            });
        };

        processErrors(data);

        if (errorMessages.length) return errorMessages.join(" | ");
    }

    // Handle server exceptions with HTML responses
    if (typeof data === "string" && /<[a-z][\s\S]*>/i.test(data)) {
        const tempHTML = document.createElement("div");
        tempHTML.innerHTML = data;
        const exceptionValue = tempHTML.querySelector("pre.exception_value")?.textContent;
        const exceptionTitle = tempHTML.querySelector("title")?.textContent;
        if (exceptionValue || exceptionTitle) {
            return `Server exception: "${exceptionValue || exceptionTitle}"`;
        }
    }

    return "Something went wrong...";
}

export const displayToastMessage = ({ data, tostOptions }: { data: ReactNode; tostOptions: { type: "info" | "success" | "warning" | "error"; options?: ToastOptions<{}> } }) => {
    toast[tostOptions.type](data, {
        position: "top-right",
        autoClose: tostOptions.type !== "success" ? false : undefined,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
        ...tostOptions.options,
    });
};

export const displayFormattedToastMessage = ({
    data,
    tostOptions,
}: {
    data: ReactNode;
    tostOptions: { type: "info" | "success" | "warning" | "error"; options?: ToastOptions<{}> };
}) => {
    displayToastMessage({ data: <div style={{ whiteSpace: "pre-line" }}>{data}</div>, tostOptions });
};

export const displayError = (data: any, extraMessage?: string, component?: { isAWS?: boolean }) => {
    const error = extractRequestErrors(data);

    toast.error(String(error || extraMessage || "Something went wrong..."), {
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
};

export const displaySuccess = (data: any) => {
    let error: null | string = null;
    if (typeof data?.message === "string") {
        error = data.message;
    }
    if (typeof data?.non_field_errors === "string" || Array.isArray(data?.non_field_errors)) {
        error = data.non_field_errors;
    }
    if (typeof data === "string") {
        error = data;
    }
    if (Array.isArray(data)) {
        error = data[0];
    }
    toast.success(error, {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
};

export const displayInfo = (data: string, extra?: { toastId?: string }) => {
    toast.info(data, {
        autoClose: false,
        position: "top-right",
        closeOnClick: true,
        draggable: false,
        theme: "colored",
        toastId: extra?.toastId,
    });
};
