import Cookies from "universal-cookie";

import { instance } from "./authAPI";
import { processRequestQueryParams } from "./utils/apiUtils";
import { StoredProductList, TDashboardTotals, TSupplierDetails } from "../redux/types/tenantDashboard.types";
import { TGenericResponseFormat, TRequestLazySearchParams, TSKUdropVendorMarkets } from "../redux/types/common/common.types";
import { EditProductDataType, ProductActionResult, ProductType, TPatchVendorProductReqData } from "../redux/types/AddProductTypes/AddProduct.types";
import { TAvailableOutboundOptions } from "../redux/types/outboundShipments.types";

export const tenantDashboardAPI = {
    getStoredProducts(cookies: Cookies, queryParams?: TRequestLazySearchParams & Partial<{ integration__country_code: TSKUdropVendorMarkets; brand: string }>) {
        return instance.get<StoredProductList>(`/shipments/stored/dashboard/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getSuppliers(cookies: Cookies, queryParams?: Partial<{ brand: string }>) {
        return instance.get<TSupplierDetails[]>(`/shipments/suppliers/dashboard/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getUploadedProducts(cookies: Cookies, queryParams?: TRequestLazySearchParams & Partial<{ integration__country_code: TSKUdropVendorMarkets; brand: string }>) {
        return instance.get<TGenericResponseFormat<ProductType>>(`/shipments/uploaded/dashboard/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getRequireActionProducts(cookies: Cookies, queryParams?: TRequestLazySearchParams & Partial<{ integration__country_code: TSKUdropVendorMarkets; brand: string }>) {
        return instance.get<TGenericResponseFormat<ProductActionResult>>(`/shipments/requires-actions/dashboard/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    patchEditUploadedProduct(token: string, id: string, data: EditProductDataType | TPatchVendorProductReqData) {
        return instance.patch(`/shipments/uploaded/dashboard/${id}/`, data, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    deleteUploadedProduct(token: string, id: string) {
        return instance.delete(`/shipments/uploaded/dashboard/${id}/`, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    deleteProductHSCode(cookies: Cookies, id: string) {
        return instance.delete(`/shipments/hs-codes/${id}/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postRemapVendorProduct(cookies: Cookies, formData: FormData) {
        return instance.post(`/shipments/vendor-products-map/`, formData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getTenantDashboardTotals(cookies: Cookies, queryParams?: Partial<{ brand: string }>) {
        return instance.get<TDashboardTotals>(`/shipments/totals/dashboard/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};

export const outboundShipmentsAPI = {
    getOutboundWarehouses(cookies: Cookies, country_code: string) {
        return instance.get(`/shipments/get-warehouse-by-county-code/${country_code}/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getOutboundShipmentsAvailableOptions(cookies: Cookies, queryParams?: Partial<{ warehouse_id: string }>) {
        return instance.get<TAvailableOutboundOptions>(`/shipments/available-outbound-options/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};
