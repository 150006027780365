import axios from "axios";

import { displayError } from "../hooks/useErrorHandler";
import { TQueryOnlyId } from "./types/common/common.types";
import {
    AllShipmentsActionTypes,
    TAllShipmentsDetails,
    TAllShipmentsDetailsItems,
    TAllShipmentsDetailsOptions,
    TAllShipmentsDetailsState,
    TAllShipmentsLoadingStates,
    ThunkType,
    TSetLoadingStates,
} from "./types/AllShipmentsTypes/allShipments.types";
import { userAllShipmentsApi } from "../api/allShipmentsApi";

export const SET_ALL_SHIPMENTS_DETAILS = "SET_PAST_SHIPMENTS_DETAILS";
export const SET_LOADING_STATES = "SET_LOADING_STATES";

export type InitialStateType = {
    allShipmentsDetails: TAllShipmentsDetailsState;
    loadingStates: TAllShipmentsLoadingStates;
};

let initialState: InitialStateType = {
    allShipmentsDetails: {
        quote: null,
        skudrop: null,
        own_ff: null,
    },
    loadingStates: {
        isLoadingSKUdropShipmentDetails: false,
        isLoadingOwnFFShipmentDetails: false,
        isLoadingQuoteShipmentDetails: false,
    },
};

const allShipmentsReducer = (state = initialState, action: AllShipmentsActionTypes): InitialStateType => {
    switch (action.type) {
        case SET_ALL_SHIPMENTS_DETAILS: {
            return {
                ...state,
                allShipmentsDetails: { ...state.allShipmentsDetails, [action.option]: action.data },
            };
        }
        case SET_LOADING_STATES: {
            return {
                ...state,
                loadingStates: { ...state.loadingStates, ...action.data },
            };
        }
        default:
            return state;
    }
};

// Action Creators
export const SetPastShipmentsDetails = (option: TAllShipmentsDetailsOptions, data: TAllShipmentsDetailsItems): TAllShipmentsDetails => ({
    type: SET_ALL_SHIPMENTS_DETAILS,
    option,
    data,
});

export const SetLoadingStates = (data: Partial<{ [key in keyof TAllShipmentsLoadingStates]: boolean }>): TSetLoadingStates => ({
    type: SET_LOADING_STATES,
    data,
});

// Thunks

// All Shipments Details
export const getAllShipmentsDetails = (option: TAllShipmentsDetailsOptions, queryParams: TQueryOnlyId): ThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            if (option === "quote") {
                dispatch(SetLoadingStates({ isLoadingQuoteShipmentDetails: true }));

                const response = await userAllShipmentsApi.getQuoteShipmentDetails(cookies, queryParams);

                dispatch(SetPastShipmentsDetails(option, response.data));

                dispatch(SetLoadingStates({ isLoadingQuoteShipmentDetails: false }));
            } else if (option === "skudrop") {
                dispatch(SetLoadingStates({ isLoadingSKUdropShipmentDetails: true }));

                const response = await userAllShipmentsApi.getSKUdropShipmentDetails(cookies, queryParams);

                dispatch(SetPastShipmentsDetails(option, response.data));

                dispatch(SetLoadingStates({ isLoadingSKUdropShipmentDetails: false }));
            } else if (option === "own_ff") {
                dispatch(SetLoadingStates({ isLoadingOwnFFShipmentDetails: true }));

                const response = await userAllShipmentsApi.getOwnFFShipmentDetails(cookies, queryParams);

                dispatch(SetPastShipmentsDetails(option, response.data));

                dispatch(SetLoadingStates({ isLoadingOwnFFShipmentDetails: false }));
            }
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                if (option === "quote") {
                    dispatch(SetLoadingStates({ isLoadingQuoteShipmentDetails: false }));
                }

                displayError(e.response.data);
            }
        }
    };
};

export default allShipmentsReducer;
