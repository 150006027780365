import Cookies from "universal-cookie";
import {
    AddProductFromAmazonCategoriesType,
    AddProductFromAmazonRequest,
    TAddProductIntegration,
    ProductsFromAmazonRootObject,
    TPostUploadBulkFileResp,
    TBulkSkuUploadProductData,
    TVendorProduct,
    TAmazonMapProduct,
    TIntegrationSourceTypes,
    TVendorMappingIntegration,
    TPostBulkVendorProductsUploadData,
    TBulkVendorUploadProductData,
    TPostVendorProductHSDetailsReqData,
} from "../redux/types/AddProductTypes/AddProduct.types";
import {
    AddNotesIncomingShipmentsRequest,
    TPatchSubmitFinalWeightOrCbmReqData,
    TPostUkTariffAmountReqData,
    UpdateDownloadInfoRequest,
} from "../redux/types/WarehouseTypes/warehouse.types";
import { instance } from "./authAPI";
import { processRequestQueryParams } from "./utils/apiUtils";
import { TGenericResponseFormat, TRequestLazySearchParams, TRequestQueryParams } from "../redux/types/common/common.types";

export const dashboardAPI = {
    getIntegrationsList(token: string) {
        return instance.get<TGenericResponseFormat<TAddProductIntegration>>("/integrations/products-integration/?limit=20", {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    getBrands(token: string) {
        return instance.get<string[]>(`/shipments/brand/dashboard/`, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    getBrandProduct(token: string, brand: string) {
        return instance.get(`/shipments/brand-details/dashboard/?brand=${window.encodeURIComponent(brand)}`, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    ConfirmProductAction(formData: FormData, cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.post(`/shipments/admin/incoming-shipments/${processRequestQueryParams(queryParams)}`, formData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    AddNotesIncomingShipments(data: AddNotesIncomingShipmentsRequest, token: string) {
        return instance.post(`/shipments/notes/`, data, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    UpdateDownloadInfo(cookies: Cookies, id: string, data: UpdateDownloadInfoRequest, queryParams: TRequestQueryParams) {
        return instance.patch(`/shipments/admin/incoming-shipments/${id}/${processRequestQueryParams(queryParams)}`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    patchSubmitFinalWeightOrCbm(cookies: Cookies, data: TPatchSubmitFinalWeightOrCbmReqData, queryParams?: TRequestQueryParams) {
        return instance.patch(`/amazon_mws/inbound-shipment/${data.id}/${processRequestQueryParams(queryParams || {})}`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getProductsFromAmazon(cookies: Cookies, queryParams?: TRequestQueryParams) {
        return instance.get<ProductsFromAmazonRootObject>(`/amazon_mws/amazon/import-templates/${processRequestQueryParams(queryParams || {})}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getUpdatedProductFromAmazon(token: string) {
        return instance.get("/amazon_mws/amazon/import-templates-updated/", {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    updateProductFromAmazon(token: string, data: { fast_mode: boolean; integration_id: string }) {
        return instance.post("/amazon_mws/amazon/import-templates/", data, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    importCustomProductAmazon(token: string, data: { sku_list: string[]; integration_id: string }) {
        return instance.post("/amazon_mws/amazon/import-templates/", data, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    addProductFromAmazon(products: AddProductFromAmazonRequest[] | null, token: string) {
        return instance.post(
            "/amazon_mws/amazon/import-templates-to-skudrop/",
            {
                products: products,
            },
            {
                headers: {
                    Authorization: "Bearer " + token,
                },
            }
        );
    },
    createAndConfirmPaymentIntent(cookies: Cookies, formData: FormData, queryParams: TRequestQueryParams) {
        return instance.post(`/shipments/admin/tariff-management/${processRequestQueryParams(queryParams)}`, formData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postChargeUKContainerTariff(cookies: Cookies, data: FormData, queryParams: TRequestQueryParams) {
        return instance.post(`/shipments/admin/tariff-management/${processRequestQueryParams(queryParams)}`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    calculateTariff(cookies: Cookies, id: string | string[], filterType: string) {
        return instance.post("shipments/admin/upload-tariff/", filterType === "AIR" ? { outgoing_shipment_ids: id } : { container_id: id[0] }, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    patchTariffAmount(cookies: Cookies, id: string | string[], tariff_amount: string, queryParams: TRequestQueryParams) {
        return instance.patch(
            `/shipments/admin/tariff-management/${id}/${processRequestQueryParams(queryParams)}`,
            { tariff_amount },
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    postTariffUkAmount(cookies: Cookies, data: TPostUkTariffAmountReqData, queryParams: TRequestQueryParams) {
        return instance.post(`/shipments/admin/tariff-container-update/${processRequestQueryParams(queryParams)}`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    checkOutgoingShipment(token: string, id: string) {
        return instance.get(`/shipments/check/outgoing-shipment/${id}/`, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    addProductFromAmazonCategories(data: AddProductFromAmazonCategoriesType[], token: string) {
        return instance.post("/shipments/upload_shipping_declaration/", data, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    getTariffsForContainer(cookies: Cookies, id: string, queryParams: TRequestQueryParams) {
        return instance.get(`/shipments/admin/list_container_tariffs/${id}/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getHSCodeInfo(token: string, query: string, countryCode: string) {
        return instance.get<unknown, { data: boolean }>(`/shipments/validate-hs-code/${countryCode}/${query}/`, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    getUploadSkusTemplate(integrationId: string, token: string) {
        return instance.get<unknown, { data: string }>(`/amazon_mws/bulk-product-import/?integration__id=${integrationId}`, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    postUploadBulkFile(integrationId: string, token: string, formData: FormData) {
        return instance.post<TPostUploadBulkFileResp>(`/amazon_mws/bulk-product-import/?integration__id=${integrationId}`, formData, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    getAsyncBulkUploadData(cookies: Cookies, queryParams?: TRequestQueryParams) {
        return instance.get<TBulkSkuUploadProductData[]>(`/amazon_mws/bulk-product-upload-data/${processRequestQueryParams(queryParams || {})}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    deleteAsyncBulkUploadData(cookies: Cookies, queryParams?: TRequestQueryParams) {
        return instance.post(
            `/amazon_mws/bulk-product-upload-data/${processRequestQueryParams(queryParams || {})}`,
            {},
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
};

export const addProductsOrBrandsAPI = {
    postCreateVendorTemplateProduct(cookies: Cookies, formData: FormData) {
        return instance.post(`/shipments/import-vendor-products/`, formData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    patchEditVendorTemplateProduct(cookies: Cookies, product_id: string, formData: FormData) {
        return instance.patch(`/shipments/import-vendor-products/${product_id}/`, formData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getVendorTemplateProducts(cookies: Cookies, queryParams?: TRequestQueryParams) {
        return instance.get<TGenericResponseFormat<TVendorProduct>>(`/shipments/import-vendor-products-to-skudrop/${processRequestQueryParams(queryParams || {})}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};

export const vendorProductsAPI = {
    getProductsForMapping(cookies: Cookies, queryParams?: Partial<{ vendor_product_id: string; integration_id: string } & TRequestLazySearchParams & TRequestQueryParams>) {
        return instance.get<TGenericResponseFormat<TAmazonMapProduct>>(`/shipments/vendor-products-map/${processRequestQueryParams(queryParams || {})}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getVendorMappingIntegrations(cookies: Cookies, queryParams: Partial<{ source_type: TIntegrationSourceTypes } & TRequestQueryParams> = { limit: 20 }) {
        return instance.get<TGenericResponseFormat<TVendorMappingIntegration>>(`/shipments/integrations-uploaded-products/${processRequestQueryParams(queryParams || {})}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postImportVendorProductsToPlatform(cookies: Cookies, formData: FormData) {
        return instance.post(`/shipments/import-vendor-products-to-skudrop/`, formData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getBulkVendorProductsUploadFile(cookies: Cookies, queryParams: { integration__id: string }) {
        return instance.get<string>(`/shipments/vendor-bulk-product-import/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postBulkVendorProductsUpload(cookies: Cookies, queryParams: { integration__id: string }, formData: FormData) {
        return instance.post<TPostBulkVendorProductsUploadData>(`/shipments/vendor-bulk-product-import/${processRequestQueryParams(queryParams)}`, formData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getAsyncBulkVendorProductsUploadData(cookies: Cookies, queryParams?: TRequestQueryParams) {
        return instance.get<TBulkVendorUploadProductData[]>(`/amazon_mws/bulk-product-upload-data/${processRequestQueryParams(queryParams || {})}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    deleteAsyncBulkVendorProductsUploadData(cookies: Cookies, queryParams?: TRequestQueryParams) {
        return instance.post(
            `/amazon_mws/bulk-product-upload-data/${processRequestQueryParams(queryParams || {})}`,
            {},
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    postVendorProductHSDetails(cookies: Cookies, data: TPostVendorProductHSDetailsReqData) {
        return instance.post(`/shipments/vendor-product-details/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};
