import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import React, { FC, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Col, Container, Button, Form, Row, Modal } from "react-bootstrap";

import { schemaFirstStep } from "./validation/validation";
import { registerFirstStep } from "../../../redux/authReducer";
import { useAppDispatch, useAppSelector } from "../../../redux/ReduxStore";

import ReCAPTCHA from "react-google-recaptcha";
import preloader from "../../../assets/video/preloader.webp";

type SignUpFirstStepPropsType = {
    logo: string;
};

type FirstStepSubmitData = {
    firstName: string;
    lastName: string;
    email: string;
};

const SignUpFirstStep: FC<SignUpFirstStepPropsType> = ({ logo }) => {
    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const isLoading = useAppSelector((state) => state.auth.isLoading);
    const registerError = useAppSelector((state) => state.auth.registerError);

    const [terms, setTerms] = useState(false);
    const [selectTerms, setSelectTerms] = useState(false);
    const [captcha, setCaptcha] = useState(process.env.REACT_APP_CAPTCHA_ENABLED === "false" ? true : false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FirstStepSubmitData>({
        resolver: yupResolver(schemaFirstStep),
    });

    const onSubmit = (data: FirstStepSubmitData) => {
        dispatch(registerFirstStep(data.firstName, data.lastName, data.email.toLowerCase()));
    };
    const onCheckTerms = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.target.value === "on" ? setSelectTerms(true) : setSelectTerms(false);
    };

    const handleCloseTerms = () => {
        setTerms(false);
    };

    const onHaveAccount = () => {
        navigate("/login");
    };

    function onChange(e: string | null) {
        e ? setCaptcha(true) : setCaptcha(false);
    }

    if (isLoading) {
        return (
            <Container className="block auth-form-container container-bg">
                <Form className="auth-form d-flex align-items-center justify-content-center shadow-sm">
                    <img src={preloader} style={{ marginLeft: 40 }} width="150" height="150" alt="Loading..."></img>
                </Form>
            </Container>
        );
    }
    return (
        <Container className="block auth-form-container container-bg">
            <Form className="auth-form shadow-sm" onSubmit={handleSubmit(onSubmit)} noValidate data-testid="section_Sign up to SKUdrop">
                <Row className="d-flex justify-content-center">
                    <img src={logo} className="skudrop-logo my-4" alt="logo" />
                </Row>
                <Row className="mb-2 mt-3">
                    <Col>
                        <h4>Sign up to SKUdrop</h4>
                    </Col>
                </Row>
                <Form.Group className="mb-4" controlId="formBasicFirstName">
                    <Form.Control
                        className={errors.firstName ? "p-2 input" : "p-2"}
                        type="text"
                        placeholder="First Name"
                        {...register("firstName")}
                        data-testid="input_First Name"
                    />
                    {errors.firstName && <p className="mb-0 error-message">{errors.firstName.message}</p>}
                </Form.Group>
                <Form.Group className="mb-4" controlId="formBasicLastName">
                    <Form.Control className={errors.lastName ? "p-2 input" : "p-2"} type="text" placeholder="Last Name" {...register("lastName")} data-testid="input_Last Name" />
                    {errors.lastName && <p className="mb-0 error-message">{errors.lastName.message}</p>}
                </Form.Group>
                <Form.Group className="mb-4" controlId="formBasicEmail">
                    <Form.Control className={errors.email ? "p-2 input" : "p-2"} type="email" placeholder="Enter email" {...register("email")} data-testid="input_Email" />
                    {errors.email && !registerError && <p className="mb-0 error-message">{errors.email.message}</p>}
                    {typeof registerError === "object" && registerError?.non_field_errors && <p className="mb-0 error-message">{registerError?.non_field_errors[0]}</p>}
                    {typeof registerError === "object" && registerError?.email && <p className="mb-0 error-message">User with this email already exists.</p>}
                </Form.Group>
                <Form.Group>
                    <div className="mb-4 ml-2 d-flex align-items-start justify-content-start">
                        <Form.Check type="checkbox" name="terms" className="radio-select" onChange={(e) => onCheckTerms(e)} data-testid="checkbox_Agreeing to the terms" />
                        <div className="mb-0 d-flex align-items-center ml-2">
                            <p className="mb-0">
                                I have read and agreed to the{" "}
                                <a href="#0" className="text-primary bg-white" onClick={() => setTerms(true)}>
                                    terms and conditions.
                                </a>
                            </p>
                        </div>
                        <Modal show={terms} fullscreen centered onHide={handleCloseTerms}>
                            <Modal.Header closeButton>
                                <Modal.Title>Terms and Conditions</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Container className="d-flex flex-column justify-content-start align-items-center">
                                    <div className="terms-container p-2">
                                        <div className="WordSection1">
                                            <div className="MsoTitle" style={{ margin: "0in", textAlign: "center", textIndent: "0in" }}>
                                                <h1 style={{ fontSize: "30.0pt" }} lang="EN-GB">
                                                    SKUdrop STANDARD TRADING TERMS AND CONDITIONS
                                                    <span style={{ fontSize: "10.0pt", color: "#F26626" }} lang="EN-GB">
                                                        v.21.01
                                                    </span>
                                                </h1>
                                            </div>
                                            <h3
                                                style={{
                                                    marginTop: "24.0pt",
                                                    marginRight: "0in",
                                                    marginBottom: "0in",
                                                    marginLeft: "0in",
                                                    textIndent: "0in",
                                                }}
                                            >
                                                <span lang="EN-GB">PART 1 - GENERAL TERMS</span>
                                            </h3>

                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <span lang="EN-GB">
                                                    THESE GENERAL TERMS OF USE (THE "AGREEMENT") OPERATE AS AN AGREEMENT BETWEEN YOU OR THE BUSINESS YOU REPRESENT AND SKUDROP.
                                                    PLEASE READ THESE TERMS CAREFULLY AS BY REGISTERING FOR OR USING THE SERVICES, YOU AGREE (ON YOUR BEHALF AND/OR THE BUSINESS YOU
                                                    REPRESENT) TO BE BOUND BY THE TERMS OF THIS AGREEMENT, INCLUDING THE SERVICE TERMS AND PROGRAM POLICIES.{" "}
                                                </span>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: "21.25pt", textIndent: "-14.15pt" }}>
                                                <b>
                                                    <span lang="EN-GB">
                                                        1.
                                                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
                                                    </span>
                                                </b>
                                                <b>
                                                    <span lang="EN-GB">Application of these Terms</span>
                                                </b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    1.1.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    These Terms of Service (“Terms”) apply to all services provided by SKUdrop Limited (“SKUdrop”) and operate as a contract between
                                                    you (referred to herein as “Client”, “you” or “your”) and SKUdrop.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    1.2.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">By using any of the SKUdrop Services you agree to be bound by these Terms. </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    1.3.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    Where you access the Services on behalf of a Company or organisation, you represent and warrant to SKUdrop that you have the
                                                    authority to bind that Company or organisation to these Terms.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    1.4.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    These Terms contain binding legal obligations and we recommend that you read this document and the ancillary policies carefully
                                                    and ensure that you understand their meaning before agreeing to be bound by them.{" "}
                                                </span>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: "21.25pt", textIndent: "-14.15pt" }}>
                                                <b>
                                                    <span lang="EN-GB">
                                                        2.
                                                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
                                                    </span>
                                                </b>
                                                <b>
                                                    <span lang="EN-GB">Setting up your Account</span>
                                                </b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    2.1.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    In order to use the SKUdrop services you will need to create an account by providing SKUdrop with identifying information, a
                                                    password, company information and information about your Inventory (“Registration Information”).&nbsp;{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    2.2.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    All Registration Information must be true and accurate and you undertake to promptly update such Registration Information as
                                                    necessary, but in no event later than 3 Business Days after any applicable change.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    2.3.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    Upon SKUdrop acceptance of your request to register for an account (“Account”), you will be sent validation and activation
                                                    instructions.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    2.4.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    Access to, and use of, the Account is restricted to Authorised Users only. You agree not to share your passwords, Account
                                                    information, or Account access information with any person who is not and Authorised User who has all necessary powers and
                                                    approvals required to bind the Client in contract.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    2.5.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    You are responsible for maintaining the confidentiality of passwords and Account information, and you are responsible for all
                                                    activities that occur under your passwords or Account(s) or as a result of your access to the Account(s).{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    2.6.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">SKUdrop shall not be liable for any unauthorised use of your Account. </span>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: "21.25pt", textIndent: "-14.15pt" }}>
                                                <b>
                                                    <span lang="EN-GB">
                                                        3.
                                                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
                                                    </span>
                                                </b>
                                                <b>
                                                    <span lang="EN-GB">The Services</span>
                                                </b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    3.1.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    Subject to your compliance with these Terms and the Policies, SKUdrop shall perform the Services as selected and authorised by
                                                    you in your Account.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    3.2.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    All Services ordered through your account at any time are deemed incorporated into, and governed by, these Terms.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    3.3.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    By using the SKUdrop Services, you acknowledge and agree that SKUdrop is a broker of third-party warehouse and logistics
                                                    services and not the primary provider of these services. SKUdrop facilitates shipments from, and shipments to, third parties on
                                                    a ‘no deeded consignee’ basis and is an independent contractor for all purposes.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    3.4.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    SKUdrop acts as your agent only with respect to the custody of goods provided to it in connection with the Services (hereinafter
                                                    referred to as “Inventory”) and you agree and authorise SKUdrop to contract, either in its own name or as agent for you, with
                                                    any third party for the transport, storage or carriage of the Inventory or for the performance of all or any part of the
                                                    Services.
                                                </span>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: "21.25pt", textIndent: "-14.15pt" }}>
                                                <b>
                                                    <span lang="EN-GB">
                                                        4.
                                                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
                                                    </span>
                                                </b>
                                                <b>
                                                    <span lang="EN-GB">Updates and Changes</span>
                                                </b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    4.1.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    Except as provided in this Agreement, all notices to you regarding this Agreement shall be posted on the Website or sent to you
                                                    by email at the account nominated by you.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    4.2.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    The SKUdrop services are provided on an “as is” and “as available” basis. SKUdrop does not guarantee continuous or uninterrupted
                                                    access to the SKUdrop Service and operation of the SKUdrop Services may be interfered with by numerous factors outside of our
                                                    control.{" "}
                                                </span>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: "21.25pt", textIndent: "-14.15pt" }}>
                                                <b>
                                                    <span lang="EN-GB">
                                                        5.
                                                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
                                                    </span>
                                                </b>
                                                <b>
                                                    <span lang="EN-GB">Policies</span>
                                                </b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    5.1
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    In addition to these Terms, SKUdrop has policies that govern your use of the SKUdrop Services (“Policies”). The Policies below
                                                    and are hereby incorporated into these Terms by reference:{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "92.1pt", textIndent: "-21.25pt" }}>
                                                <span lang="EN-GB">
                                                    (a)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">Warehousing Policy;</span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "92.1pt", textIndent: "-21.25pt" }}>
                                                <span lang="EN-GB">
                                                    (b)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">Privacy Policy; and</span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "92.1pt", textIndent: "-21.25pt" }}>
                                                <span lang="EN-GB">
                                                    (c)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">Disputes Policy.</span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "92.1pt", textIndent: "0in" }}></p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    5.2.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    These Terms shall prevail to the extent of any conflict or inconsistency between these Terms and any of the Policies.
                                                </span>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: "21.25pt", textIndent: "-14.15pt" }}>
                                                <b>
                                                    <span lang="EN-GB">
                                                        6.
                                                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
                                                    </span>
                                                </b>
                                                <b>
                                                    <span lang="EN-GB">Payment, Fee Recovery and Changes to Fees</span>
                                                </b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    6.1.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    Where this agreement refers to SKUdrop’s Fee Schedule, standard or current rates or fees, these will be the rates or fees made
                                                    known to you by notice (which may be given by publishing such rates or fees on the SKUdrop website).{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    6.2.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    SKUdrop may vary its standard or current rates by provision of one month’s written notice by email or by publishing the amended
                                                    rates on the SKUdrop Website. If you do not agree to amended rates, you must cease using the Services prior to the expiry of the
                                                    30 day notice period.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    6.3.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">SKUdrop offers various service packages under which Fees will be charged for your use of the Service.</span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    6.4.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    Where payment by credit card is enabled, SKUdrop may charge you on a monthly recurring basis or from time to time when you use
                                                    the Service.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    6.5.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">If any credit card payment or electronic transfer is declined (or fails to be processed for any reason): </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt", textIndent: "-21.25pt" }}>
                                                <span lang="EN-GB">
                                                    (a)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">you must, within twenty four (24) hours, ensure that the relevant payment is processed; </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt", textIndent: "-21.25pt" }}>
                                                <span lang="EN-GB">
                                                    (b)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    you indemnify SKUdrop against any fees incurred in relation to the declined payment and pay any administrative fee that applies
                                                    to declined payments;{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt", textIndent: "-21.25pt" }}>
                                                <span lang="EN-GB">
                                                    (c)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    your access to the service may be interrupted or suspended and, if the credit card payment has not been approved within twenty
                                                    four (24) hours, you will have committed a material breach of this Agreement.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    6.6.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    SKUdrop shall be entitled to retain and be paid any fee, brokerage, commission, allowance or other remuneration set out in the
                                                    Fee Schedule or customarily retained by or paid to shipping and forwarding agents, including insurance commissions where
                                                    relevant.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    6.7.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    In circumstances where SKUdrop has elected to charge by weight or measurements it may reweigh or re-measure the Products to
                                                    ensure that the charges are accurate and charge additional fees where necessary.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    6.8.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <b>
                                                    <span lang="EN-GB">Quotations not Binding.</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    {" "}
                                                    Quotations for SKUdrop Services and Usage Fees are for informational purposes only and are subject to change without notice and
                                                    shall not under any circumstances be binding upon SKUdrop. Quotations accepted through SKUdrop Services’ online interface are
                                                    estimates based on the information available at the time made. The final rates and service fees may vary based upon the shipment
                                                    actually tendered, the work actually performed, or a number of factors such as carrier shipping prices, the actual
                                                    characteristics of your product, the delivery address, and services requested during the normal course of delivery. SKUdrop
                                                    reserves the right to bill the Usage Fees based on actual charges at any time after the SKUdrop Services are rendered. SKUdrop
                                                    specifically disclaims liability for any shipping rate errors due to inaccurate or incomplete information, such as dimensions
                                                    and weights.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    6.9.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    As the fees charged to your account may vary from time to time, we recommend you regularly review your account to ensure that we
                                                    can resolve any questions you may have regarding any disagreements, errors or discrepancies as to a charge. Some charges may not
                                                    be reviewable or refundable after 60 days due to third party terms of service.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    6.10.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    SKUdrop reserves the right to offset any amounts due to it by a Client against any amounts payable to that Client or a Related
                                                    Body Corporate of that Client.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    6.11.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    You acknowledge and agree that all fees, charges and any other rates or amounts charged by SKUdrop to you are exclusive of
                                                    applicable value added, sales/use or Inventory and service taxes (“Transaction Taxes”) which may be levied in connection with
                                                    the supply by SKUdrop of the SKUdrop Services to you. Where applicable, you shall pay all Transaction Taxes arising in respect
                                                    of the Usage Fees or other amounts charged by SKUdrop to you.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    6.12.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    All quotations are based on shipping from SKUdrop warehouse (China) to Amazon FBA warehouse in the destination country and is
                                                    comprised of the shipping fee only and excludes all duties and tariffs or any other import taxes.{" "}
                                                </span>
                                                <span lang="EN-GB">Unless otherwise specified, the quote shall remain valid for 7 days as of the date of the issue.</span>
                                                <span lang="EN-GB"> </span>
                                                <span lang="EN-GB">All quoted prices are based on the highest of weight or volume weight in kilograms</span>
                                                <span lang="EN-GB">. </span>
                                                <span lang="EN-GB">
                                                    The customer warrants that they have provided the correct details in relation to the products and packaging and further warrants
                                                    that the products under this quotation do not fall under the definition of dangerous or hazardous goods. Proceeding with this
                                                    quotation is authorisation by the customer for SKUdrop to charge their nominated credit card.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    6.13.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <b>
                                                    <span lang="EN-GB">Currency. </span>
                                                </b>
                                                <span lang="EN-GB">
                                                    All dollar amounts stated in these Terms and the Policy’s will be in US dollars unless otherwise specified.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    6.14.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">If you do not make full payment of any invoiced sum on its due date, the Licensor may:</span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt", textIndent: "-21.25pt" }}>
                                                <span lang="EN-GB">
                                                    (a)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    charge interest on the outstanding amount at its current bank overdraft rate plus 3% from the due date until the date of actual
                                                    payment; and/or
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt", textIndent: "-21.25pt" }}>
                                                <span lang="EN-GB">
                                                    (b)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">withhold any further training and/or technical support services until full payment is made; and/or</span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt", textIndent: "-21.25pt" }}>
                                                <span lang="EN-GB">
                                                    (c)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    deactivate the Software from your system and terminate this agreement, if full payment has not been made within 5 business days’
                                                    of notice that the Licensor intends to exercise this deactivation right.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    6.15.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <b>
                                                    <span lang="EN-GB">Storage Lien.</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    Where any Fees remain unpaid for a period of 60 days, SKUdrop reserves the right, at its sole discretion to:{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt", textIndent: "-21.25pt" }}>
                                                <span lang="EN-GB">
                                                    (a)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">classify your Account as an “Abandoned Account”;</span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt", textIndent: "-21.25pt" }}>
                                                <span lang="EN-GB">
                                                    (b)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    retain any Inventory as security until you have paid all storage charges and other costs prescribed by the Storage Liens
                                                    Legislation;{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt", textIndent: "-21.25pt" }}>
                                                <span lang="EN-GB">
                                                    (c)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    after provision of appropriate notice under the relevant the Storage Liens Legislation, sell the Inventory at public
                                                    auction.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "0in" }}>
                                                <span lang="EN-GB">
                                                    Where Fees exceed the proceeds of auction undertaken under this clause, you agree that you will remain liable for these fees.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    6.16.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <b>
                                                    <span lang="EN-GB">China custom clearance documents</span>
                                                </b>
                                                <span className="ml-1" lang="EN-GB">
                                                    SKUDrop can provide product manufacturers their custom clearance paperwork on application with our warehouse team.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt", textIndent: "-21.25pt" }}>
                                                <span lang="EN-GB">
                                                    (a)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">This will allow the manufacturer to claim their VAT tax refund.</span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt", textIndent: "-21.25pt" }}>
                                                <span lang="EN-GB">
                                                    (b)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    These documents are issued free of charge to the SKUDrop customers manufacturer, whose products are included in any shipment in
                                                    the one container. The SKUDrop customers total order must be greater than 0.6 CBM or 100 Kg to have the documents supplied free
                                                    of charge.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt", textIndent: "-21.25pt" }}>
                                                <span lang="EN-GB">
                                                    (c)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    If the SKUdrop customers individual export order totals less than 0.6 CBM and 100Kg, there is a charge of 200 RMB to the
                                                    manufacturer for the documents required for each export order in the one container.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "28.3pt", textIndent: "-28.3pt" }}>
                                                <b>
                                                    <span style={{ fontFamily: '"Arial",sans-serif' }} lang="EN-GB">
                                                        7.
                                                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    </span>
                                                </b>
                                                <b>
                                                    <span lang="EN-GB">Advisability of Insurance</span>
                                                </b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: ".75in", textIndent: "-.75in" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    7.1.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    Errors inevitably occur in the storage and shipping of Goods and it is always advisable to procure and maintain an appropriate
                                                    level of insurance over your goods.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: ".75in", textIndent: "-.75in" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    7.2.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    Unless agreed in writing, SKUdrop does not insure the Goods and it is the Client’s sole responsibility to insure its Goods
                                                    against all insurable risks (including duties and taxes).
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: ".75in", textIndent: "-.75in" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    7.3.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span style={{ color: "black" }} lang="EN-GB">
                                                    Without limiting the applicability of clause 7.2, SKUdrop may enter into various insurance arrangements with regard to goods and
                                                    inventory with third parties, as advised to you from time to time. SKUdrop will take reasonable steps to pursue insurance claims
                                                    with regards to your goods where it, in its sole discretion, believes that such action is likely to be successful. Where SKUdrop
                                                    receives Insurance Proceeds in response to such claim it will remit those Insurance Proceeds to you.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: ".75in", textIndent: "-.75in" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    7.4.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span style={{ color: "black" }} lang="EN-GB">
                                                    SKUdrop has the following insurance arrangements with our third-party suppliers:
                                                </span>
                                            </p>
                                            <p style={{ color: "black", marginLeft: ".8in", marginTop: "16px" }} lang="EN-GB">
                                                All insurance arrangements are subject to change without notice, SKUdrop will advise the customer of these changes by email
                                                registered in your account within 7 days of the change.
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "1.0in" }}>
                                                <span style={{ color: "black" }} lang="EN-GB">
                                                    (a)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span style={{ color: "black" }} lang="EN-GB">
                                                    Insurance coverage for the delivery of cartons to the SKUDrop warehouse. No cover, this is the responsibility of the customer.
                                                </span>
                                            </p>
                                            <div className="MsoNormal" style={{ marginLeft: "1.0in" }}>
                                                <span style={{ color: "black" }} lang="EN-GB">
                                                    (b)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span style={{ color: "black" }} lang="EN-GB">
                                                    Insurance coverage for warehouse storage in Yiwu, China.
                                                </span>
                                                <p style={{ marginLeft: ".25in" }}>Customer cartons are covered for the following events:</p>
                                                <ul style={{ listStyleType: "none", paddingLeft: ".5in" }}>
                                                    <li>- Fire</li>
                                                    <li>- Explosion</li>
                                                    <li>- Lightning Strikes</li>
                                                    <li>- Falling of flying objects</li>
                                                    <li>- Heavy rain and floods</li>
                                                    <li>- Acts of negligence by the warehouse staff.</li>
                                                </ul>
                                                <p style={{ color: "black", marginLeft: ".25in" }} lang="EN-GB">
                                                    Exclusion for item (b) storage:
                                                </p>
                                                <p style={{ color: "black", marginLeft: ".5in" }} lang="EN-GB">
                                                    (a)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span>
                                                    <span style={{ color: "black" }} lang="EN-GB">
                                                        Intentional or gross negligence of the insured, the insured and their representatives.
                                                    </span>
                                                </p>
                                                <p style={{ color: "black", marginLeft: ".5in" }} lang="EN-GB">
                                                    (b)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span>
                                                    <span style={{ color: "black" }} lang="EN-GB">
                                                        Administrative acts or judicial acts.
                                                    </span>
                                                </p>
                                                <p style={{ color: "black", marginLeft: ".5in" }} lang="EN-GB">
                                                    (c)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span>
                                                    <span style={{ color: "black" }} lang="EN-GB">
                                                        Wars, war-like acts, hostilities, military operations, armed conflicts, strikes, riots, riots, coups, rebellions, and
                                                        terrorist activities.
                                                    </span>
                                                </p>
                                                <p style={{ color: "black", marginLeft: ".5in" }} lang="EN-GB">
                                                    (d)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span>
                                                    <span style={{ color: "black" }} lang="EN-GB">
                                                        Earthquakes, tsunamis, and their secondary disasters.
                                                    </span>
                                                </p>
                                                <p style={{ color: "black", marginLeft: ".5in" }} lang="EN-GB">
                                                    (e)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span>
                                                    <span style={{ color: "black" }} lang="EN-GB">
                                                        Nuclear radiation, nuclear fission, nuclear fusion, nuclear pollution, and other radioactive pollution.
                                                    </span>
                                                </p>
                                                <p style={{ color: "black", marginLeft: ".5in" }} lang="EN-GB">
                                                    (f)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span>
                                                    <span style={{ color: "black" }} lang="EN-GB">
                                                        Air pollution, land pollution, water pollution and other non-radioactive pollution, but non-radioactive pollution caused by
                                                        insurance accidents is not limited to this.
                                                    </span>
                                                </p>
                                                <p style={{ color: "black", marginLeft: ".5in" }} lang="EN-GB">
                                                    (g)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span>
                                                    <span style={{ color: "black" }} lang="EN-GB">
                                                        Intrinsic or latent defects, natural wear and tear, natural wear and tear, atmospheric (climate or temperature) changes,
                                                        normal water level changes or other gradual changes, changes in the substance itself, mildew, dampness, rat bites, insect
                                                        bites, bird pecks, etc. Oxidation, rust, leakage, baking.
                                                    </span>
                                                </p>
                                                <p style={{ color: "black", marginLeft: ".5in" }} lang="EN-GB">
                                                    (h)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span>
                                                    <span style={{ color: "black" }} lang="EN-GB">
                                                        Theft and robbery.
                                                    </span>
                                                </p>
                                                <p style={{ color: "black", marginLeft: ".25in" }} lang="EN-GB">
                                                    The maximum claim is 3000 RMB per CBM or per ton.
                                                </p>
                                            </div>
                                            <div className="MsoNormal" style={{ marginLeft: "1.0in" }}>
                                                <span lang="EN-GB">
                                                    (c)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    Insurance coverage for the delivery of cartons from the SKUDrop warehouse in Yiwu, China to the freight forwarder consolidation
                                                    warehouse in Ningbo, China.
                                                </span>
                                                <p style={{ color: "black", marginLeft: ".25in", marginTop: "16px" }} lang="EN-GB">
                                                    Coverage is 40 RMB per Kg.
                                                </p>
                                            </div>
                                            <div className="MsoNormal" style={{ marginLeft: "1.0in" }}>
                                                <span lang="EN-GB">
                                                    (d)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    Insurance coverage for the period from storage in the freight forwarder warehouse to loaded into a container.
                                                </span>
                                                <p style={{ color: "black", marginLeft: ".25in", marginTop: "16px" }} lang="EN-GB">
                                                    Coverage is 40 RMB per Kg.
                                                </p>
                                            </div>
                                            <div className="MsoNormal" style={{ marginLeft: "1.0in" }}>
                                                <span lang="EN-GB">
                                                    (e)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    Shipping insurance coverage from period when the goods are inside the locked container at freight forwarders consolidation
                                                    warehouse to container opened at freight forwarders warehouse in the US at the port.
                                                </span>
                                                <p style={{ color: "black", marginLeft: ".25in", marginTop: "16px" }} lang="EN-GB">
                                                    Coverage of full commercial invoice value of goods based on the commercial invoice uploaded at the time of shipment, up to a
                                                    maximum value of $50USD per unit cost.
                                                </p>
                                            </div>
                                            <div className="MsoNormal" style={{ marginLeft: "1.0in" }}>
                                                <span lang="EN-GB">
                                                    (f)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">Insurance coverage for cartons stored freight forwarders in LA port warehouse.</span>
                                                <p style={{ color: "black", marginLeft: ".25in", marginTop: "16px" }} lang="EN-GB">
                                                    Coverage of full commercial invoice value of goods based on the commercial invoice uploaded at the time of shipment, up to a
                                                    maximum value of $50USD per unit cost.
                                                </p>
                                            </div>
                                            <div className="MsoNormal" style={{ marginLeft: "1.0in" }}>
                                                <span lang="EN-GB">
                                                    (g)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">Insurance coverage for Last Leg Delivery.</span>
                                                <p style={{ color: "black", marginLeft: ".25in", marginTop: "16px" }} lang="EN-GB">
                                                    Coverage of full commercial invoice value of goods based on the commercial invoice uploaded at the time of shipment, up to a
                                                    maximum value of $50USD per unit cost.
                                                </p>
                                            </div>
                                            <div className="MsoNormal" style={{ marginLeft: "1.0in" }}>
                                                <span lang="EN-GB">
                                                    (h)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">Exclusion for clause item (c), (d), (e), (f) and (g):</span>
                                                <ol style={{ paddingLeft: ".75in", margin: 0, marginTop: "16px" }}>
                                                    <li>Intentional or gross negligence of the insured, the insured and their representatives.</li>
                                                    <li>Administrative acts or judicial acts.</li>
                                                    <li>
                                                        Wars, war-like acts, hostilities, military operations, armed conflicts, strikes, riots, riots, coups, rebellions, and
                                                        terrorist activities.
                                                    </li>
                                                    <li>Nuclear radiation, nuclear fission, nuclear fusion, nuclear pollution, and other radioactive pollution.</li>
                                                </ol>
                                            </div>
                                            <div className="MsoNormal" style={{ marginLeft: "1.0in" }}>
                                                <span lang="EN-GB">
                                                    (i)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">Specific Conditions.</span>
                                                <p style={{ marginLeft: ".5in", marginTop: "16px" }} lang="EN-GB">
                                                    - The following are excluded for any lost, damaged, or destroyed cartons.
                                                </p>
                                                <ul style={{ paddingLeft: "1in", margin: 0 }}>
                                                    <li>Prep fees paid.</li>
                                                    <li>Tariffs paid.</li>
                                                    <li>Shipping costs paid.</li>
                                                </ul>
                                                <p style={{ marginLeft: ".5in", marginTop: "16px" }} lang="EN-GB">
                                                    - Loss of business costs and any other costs caused by of delays in delivery of shipments are specifically excluded.
                                                </p>
                                                <p style={{ marginLeft: ".5in", marginTop: "16px" }} lang="EN-GB">
                                                    - SKUDrop will have 30 days from notification to the customer of an event, to investigate the circumstances or any damage,
                                                    destroyed or lost cartons.
                                                </p>
                                                <p style={{ marginLeft: ".5in", marginTop: "16px" }} lang="EN-GB">
                                                    - Claims will be settled in 15 days once the claim has been approved by SKUDrop.
                                                </p>
                                            </div>
                                            <p className="MsoNormal" style={{ marginLeft: "28.3pt", textIndent: "-28.3pt" }}>
                                                <b>
                                                    <span lang="EN-GB">
                                                        8.
                                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        </span>
                                                    </span>
                                                </b>
                                                <b>
                                                    <span lang="EN-GB">Access to SKUdrop Portal</span>
                                                </b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    8.1.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    As part of the Services provided by SKUdrop, the Client may integrate or connect its Amazon Seller Account with SKUdrop’s
                                                    cloud-based fulfilment portal (the “SKUdrop Portal”).{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    8.2.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    When electing to use the SKUdrop Portal, you will be asked to specify one or more authorised parties to manage and use its
                                                    account on the SKUdrop Portal (each an “Administrator”).{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    8.3.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    It is your sole responsibility to ensure that those persons granted access to Administrator’s login credentials have full
                                                    authority to manage and access the client’s account via the SKUdrop Portal including having the authority to bind the Client in
                                                    contract.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    8.4.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">The Client shall maintain the confidentiality of passwords and Administrator accounts.</span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    8.5.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    The SKUdrop Portal is updated and modified regularly, often without prior notice to you. Your continued use of the SKUdrop
                                                    Portal constitutes your acceptance of such modifications. If you do not agree to any such modification you must immediately
                                                    cease use of the SKUdrop Portal.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    8.6.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    Any third party software application you use on the SKUdrop website to connect to SKUdrop Services, or related to the SKUdrop
                                                    Services (“Third Party Software”) is solely subject to any third party software provider software licences and APIs. SKUdrop
                                                    does not own, control or have any responsibility or liability for any Third Party Software.
                                                </span>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: "28.3pt", textIndent: "-28.3pt" }}>
                                                <b>
                                                    <span lang="EN-GB">
                                                        9.
                                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        </span>
                                                    </span>
                                                </b>
                                                <b>
                                                    <span lang="EN-GB">SKUdrop’s Obligations</span>
                                                </b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    9.1.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">SKUdrop will provide the Services with all due reasonable care and skill. </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    9.2.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    SKUdrop is not a broker, carrier, freight forwarder or other freight transportation provider (a “Logistics Provider”){" "}
                                                    <span style={{ color: "black" }}>and will not be the Importer of Record in relation to any goods or inventory.</span>
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    9.3.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span style={{ color: "black" }} lang="EN-GB">
                                                    The Importer of Record (IOR) will be the Third Party Shipper or their Agent SKUdrop contracts to transport the customer’s goods
                                                    as per the relevant Shipping Plan.
                                                </span>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: "27.0pt", textIndent: "-27.0pt" }}>
                                                <b>
                                                    <span lang="EN-GB">
                                                        10.
                                                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    </span>
                                                </b>
                                                <b>
                                                    <span lang="EN-GB">Your Obligations</span>
                                                </b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    10.1.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    You are solely responsible for protecting and maintaining the confidentiality and security of your account and agree to not hold
                                                    SKUdrop liable for any loss or damage resulting from your failure to put in place appropriate security measures to protect the
                                                    confidentiality and security of your account.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    10.2.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    You are solely responsible for reviewing all documents and declarations prepared and/or filed with U.S. Customs and Border
                                                    Protection, other Government Agency and/or third parties, and will immediately advise SKUdrop of any errors, misstatements or
                                                    omissions on any declaration filed on Customer’s behalf.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    10.3.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    You shall use reasonable care to ensure the correctness and completeness of all information you supply directly or indirectly
                                                    and shall indemnify and hold SKUdrop harmless from any and all claims asserted and/or liability or losses suffered by reason of
                                                    any incorrect, incomplete or false statement upon which SKUdrop or its service providers or partners reasonably relied. You
                                                    agree that you have an affirmative, non-assignable, non-delegable duty to disclose any and all information required to import,
                                                    export or enter the goods.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    10.4.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">You represent and warrant that:</span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt", textIndent: "-21.25pt" }}>
                                                <span lang="EN-GB">
                                                    (a)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    all information including Harmonised System (HS) Codes you provide to SKUdrop shall be accurate, complete and current;{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt", textIndent: "-21.25pt" }}>
                                                <span lang="EN-GB">
                                                    (b)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    cooperate with SKUdrop in any matter relating to the Services and provide such information, consents, information or materials
                                                    as are reasonably required by SKUdrop in order to provide the Services in a timely manner;
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt", textIndent: "-21.25pt" }}>
                                                <span lang="EN-GB">
                                                    (c)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    obtain all necessary licenses and comply with all relevant laws that apply to your use of SKUdrop’s services or the Platform.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    10.5.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    The customer is responsible for providing complete export declaration documents in accordance with customs regulations,
                                                    including, but not limited to contracts, commodity inspection certificates, CPC reports, toxicology Reports, verification
                                                    documents, licenses, customs declaration forms, manuals, invoices, packing lists and relevant approval documents stipulated by
                                                    the Customs Authority responsible for authenticity, legality and validity.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    10.6.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    If SKUDrop, on its own, or as an agent of its Freight Forwarder or Customs Broker requests certain documents be included as part
                                                    of a shipment, and the absence of those documents will not prevent the customers goods being shipped, the customer acknowledges
                                                    that if their goods are shipped without the requested documents they become solely responsible for any costs, penalties, charges
                                                    and fines caused by the absence of said documents.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    10.7.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    The customer indemnifies SKUDrop in accordance with clause 14.1 for any violations arising from breaches associated with clauses
                                                    10.5 and 10.6
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "0in" }}></p>
                                            <p className="MsoNormal" style={{ marginLeft: "28.3pt", textIndent: "-28.3pt" }}>
                                                <b>
                                                    <span lang="EN-GB">
                                                        11.
                                                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    </span>
                                                </b>
                                                <b>
                                                    <span lang="EN-GB">Terms Applying to Trusts</span>
                                                </b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    11.1.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    If the Client enters into this Agreement as the trustee of a trust, the Client represents and warrants that:
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt", textIndent: "-21.25pt" }}>
                                                <span lang="EN-GB">
                                                    (a)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    it enters this Agreement and undertakes and accepts all its obligations and liabilities under this Agreement in both its
                                                    personal capacity and as trustee;
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt", textIndent: "-21.25pt" }}>
                                                <span lang="EN-GB">
                                                    (b)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">it is duly authorised to enter into this agreement and bind the trust.</span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    11.2.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    SKUdrop shall have recourse to any assets of the Client or any trust upon exercise of any right under this Agreement whether
                                                    that asset is held by the Client in its personal capacity or in its capacity as the trustee of the trust.{" "}
                                                </span>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: "28.3pt", textIndent: "-28.3pt" }}>
                                                <b>
                                                    <span lang="EN-GB">
                                                        12.
                                                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    </span>
                                                </b>
                                                <b>
                                                    <span lang="EN-GB">Intellectual Property</span>
                                                </b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    12.1.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">For purposes of this Agreement, “SKUdrop IP” shall mean: </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt", textIndent: "-21.25pt" }}>
                                                <span lang="EN-GB">
                                                    (a)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">SKUdrop’s methodology for the provision of SKUdrop Services; and </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt", textIndent: "-21.25pt" }}>
                                                <span lang="EN-GB">
                                                    (b)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    SKUdrop’s web site, code, technology, software, copyrights, logos, domain names, patents, trade secrets, trademarks, products,
                                                    processes and materials.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "0in" }}>
                                                <span lang="EN-GB">
                                                    SKUdrop hereby retains all worldwide right, title and interest in and to the SKUdrop Property. Any rights not expressly granted
                                                    herein to the SKUdrop Property shall be retained by SKUdrop. You acknowledge that all right, title and interest to the SKUdrop
                                                    IP is owned by SKUdrop.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    12.2.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    You shall not (and you shall not permit others), directly or indirectly, to modify, to translate, to decompile, to disassemble,
                                                    or to reverse engineer any part of the SKUdrop IP, or otherwise to attempt to discern the functioning or operation of the
                                                    website or SKUdrop Services; or to copy, sub-licence, lease, distribute, or otherwise transfer any or the rights that you
                                                    receive hereunder.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    12.3.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    All page headers, custom graphics, button icons, and scripts are service marks, trademarks, and/or trade dress of SKUdrop and
                                                    you shall not copy, imitate, or use them without our express prior written consent.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    12.4.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">You shall not (and must not permit others to): </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt", textIndent: "-21.25pt" }}>
                                                <span lang="EN-GB">
                                                    (a)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    sublicense, assign or otherwise transfer any of your rights under this agreement in relation to SKUdrop IP;&nbsp;{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt", textIndent: "-21.25pt" }}>
                                                <span lang="EN-GB">
                                                    (b)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">copy, reproduce, redistribute or publish the SKUdrop IP; </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt", textIndent: "-21.25pt" }}>
                                                <span lang="EN-GB">
                                                    (c)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    use any data scraper, web-crawler, bot or other automated means to access the SKUdrop website or SKUdrop Services for any
                                                    purpose without SKUdrop’s express written permission;{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt", textIndent: "-21.25pt" }}>
                                                <span lang="EN-GB">
                                                    (d)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    interfere or attempt to interfere with the proper working of our website or any activities conducted on the website;{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt", textIndent: "-21.25pt" }}>
                                                <span lang="EN-GB">
                                                    (e)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    bypass, in part or whole, any measures SKUdrop may use to prevent or restrict access to the SKUdrop website or the SKUdrop
                                                    Services.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    12.5.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    Subject to your compliance with these Terms, SKUdrop grants to you a non-exclusive and non-transferable licence to use and
                                                    access the Portal and use the Software (“User Licence”).&nbsp;{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    12.6.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    All materials provided by you under any SKUdrop Services Orders shall be deemed “Client IP” for purposes of the Agreement. You
                                                    grant to SKUdrop a non-exclusive, irrevocable, worldwide licence to the Client IP solely as needed to provide the SKUdrop
                                                    Services.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    12.7.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    SKUdrop will use reasonable efforts to notify you of any unauthorised access to, or use of, Client IP that comes to our
                                                    attention. You must immediately notify SKUdrop of any suspected security breach at security@SKUdrop.com followed by contacting
                                                    SKUdrop customer support.{" "}
                                                </span>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: "28.3pt", textIndent: "-28.3pt" }}>
                                                <b>
                                                    <span lang="EN-GB">
                                                        13.
                                                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    </span>
                                                </b>
                                                <b>
                                                    <span lang="EN-GB">Confidential Information</span>
                                                </b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    13.1.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    Each party (“Recipient”) acknowledges that it may receive Confidential Information as defined herein. For purposes of these
                                                    Terms and subject to the Exclusions set forth below, Confidential Information means any information provided to it by the other
                                                    party (“Discloser”) that is marked, labelled or otherwise designated as confidential or proprietary, or that Recipient knew, or
                                                    should have known, was confidential due to the circumstances surrounding the disclosure.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    13.2.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">The following shall not be Confidential Information for the purpose of these Terms: </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt", textIndent: "-21.25pt" }}>
                                                <span lang="EN-GB">
                                                    (a)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">information publicly known at the time of the disclosure; </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt", textIndent: "-21.25pt" }}>
                                                <span lang="EN-GB">
                                                    (b)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    information received by the Recipient from a third party without any restriction regarding confidentiality;{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt", textIndent: "-21.25pt" }}>
                                                <span lang="EN-GB">
                                                    (c)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">information published or made known to the public by Discloser; </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt", textIndent: "-21.25pt" }}>
                                                <span lang="EN-GB">
                                                    (d)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    information that was generated independently by the Recipient without reference to the Discloser’s Confidential
                                                    Information;{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt", textIndent: "-21.25pt" }}>
                                                <span lang="EN-GB">
                                                    (e)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">Non-Service Confidential Information (as defined below); or</span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt", textIndent: "-21.25pt" }}>
                                                <span lang="EN-GB">
                                                    (f)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    information that is required to be disclosed under a court order or pursuant to any applicable governmental rule, regulation or
                                                    statute, provided that Recipient provide Discloser with prior written notice of such disclosure, (as permitted by law) and the
                                                    timing for response set forth in the request.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    13.3.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <b>
                                                    <span lang="EN-GB">Non-Service Confidential Information.</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    {" "}
                                                    SKUdrop does not require or desire you to provide any of your proprietary information other than that information absolutely
                                                    required for provision of the Service. Where you provide us with such information, including prototypes of new products, then
                                                    SKUdrop will not be obligated to treat such information as Confidential Information.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    13.4.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <b>
                                                    <span lang="EN-GB">Standard of Care.</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    The Recipient shall not use the Confidential Information for any purpose other than those purposes allowed or required by these
                                                    Terms. In order to ensure compliance with these Terms, the Recipient shall use at least the same standard of care with the
                                                    Discloser’s Confidential Information as it does with its own Confidential Information, but in no event with less than reasonable
                                                    care.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    13.5.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <b>
                                                    <span lang="EN-GB">Damages Insufficient.</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    {" "}
                                                    Each party acknowledges that breach of this provision would result in irreparable harm to the other party, for which monetary
                                                    damages would be an insufficient remedy. Each Party will be entitled to seek injunctive relief to enforce these provisions.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    13.6.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <b>
                                                    <span lang="EN-GB">Return or Destruction.</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    {" "}
                                                    Other than transactional information that is required to be retained by a Law or Regulation or in the ordinary course of a
                                                    party’s business, each party shall either promptly return all Confidential Information, or confirm destruction of such
                                                    Confidential Information, promptly after receipt of written request from the other party.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    13.7.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <b>
                                                    <span lang="EN-GB">Aggregate Information.</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    {" "}
                                                    Subject to these Terms and the Privacy Policy, SKUdrop may compile aggregate results from your use of the Services, provided
                                                    that SKUdrop shall not disclose any information that would individually identify you (“Aggregate Information”). Aggregate
                                                    Information shall be SKUdrop’s Confidential Information.
                                                </span>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: "21.25pt", textIndent: "-21.25pt" }}>
                                                <b>
                                                    <span lang="EN-GB">
                                                        14.
                                                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    </span>
                                                </b>
                                                <b>
                                                    <span lang="EN-GB">Indemnity</span>
                                                </b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "56.65pt", textIndent: "-56.65pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    14.1.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                </span>
                                                <span lang="EN-GB">
                                                    You agree to indemnify and keep indemnified SKUdrop, its parent corporation, and their officers, directors, employees and agents
                                                    from and against any and all claims, liabilities, damages, losses or expenses, including reasonable legal fees and costs, due to
                                                    or arising out of your use of the SKUdrop Services and/or your violation of these Terms.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    14.2.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">Without limiting the foregoing you agree to indemnify SKUdrop for: </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt" }}>
                                                <span lang="EN-GB">
                                                    (a)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
                                                </span>
                                                <span lang="EN-GB">
                                                    any and all claims for transportation, storage, handling and other charges relating to your Inventory, including undercharges,
                                                    rail demurrage, truck/intermodal detention and other charges of any nature, regardless of whether or not SKUdrop accepts your
                                                    Inventory;{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt" }}>
                                                <span lang="EN-GB">
                                                    (b)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
                                                </span>
                                                <span lang="EN-GB">any loss or damage directly or indirectly caused by the Inventory or breach of these Terms; </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt" }}>
                                                <span lang="EN-GB">
                                                    (c)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                                </span>
                                                <span lang="EN-GB">
                                                    any amounts of Customs Duty, GST, value added tax and other payments made or due to Government Authorities in relation to the
                                                    Services;{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt" }}>
                                                <span lang="EN-GB">
                                                    (d)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
                                                </span>
                                                <span lang="EN-GB">
                                                    any penalties payable by SKUdrop (whether by court order or pursuant to an infringement notice) due to the customer:
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "120.85pt" }}>
                                                <span lang="EN-GB">
                                                    (1)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                                </span>
                                                <span lang="EN-GB">providing incorrect or misleading information;</span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "120.85pt" }}>
                                                <span lang="EN-GB">
                                                    (2)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">failing to provide material information required to be given to Government Authorities; </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "120.85pt" }}>
                                                <span lang="EN-GB">
                                                    (3)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    failing to provide information in a fashion which enables SKUdrop to comply with any requirement of a Government Authority
                                                    within the prescribed period; and/or
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "120.85pt" }}>
                                                <span lang="EN-GB">
                                                    (4)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    failing to provide information or documentation reasonably requested by SKUdrop within the timeframe requested.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt" }}>
                                                <span lang="EN-GB">
                                                    (e)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
                                                </span>
                                                <span lang="EN-GB">Any penalties associated with: </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "120.85pt" }}>
                                                <span lang="EN-GB">
                                                    (1)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    A failure by the Client to maintain or provide documents or records in the manner and time required by a Government Authority;
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "120.85pt" }}>
                                                <span lang="EN-GB">
                                                    (2)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">Providing misleading or deceptive information regarding the Inventory; </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "120.85pt" }}>
                                                <span lang="EN-GB">
                                                    (3)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">Breach of any other Law or Regulation. </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt" }}>
                                                <span lang="EN-GB">
                                                    (f)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                                </span>
                                                <span lang="EN-GB">
                                                    Liabilities or costs incurred by SKUdrop on behalf of the Client associated with the provision of the Services;{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt" }}>
                                                <span lang="EN-GB">
                                                    (g)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
                                                </span>
                                                <span lang="EN-GB">
                                                    Loss or damage incurred by SKUdrop due to a error or misrepresentation of the Client or a breach of any of the Client
                                                    warranties;{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt" }}>
                                                <span lang="EN-GB">
                                                    (h)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
                                                </span>
                                                <span lang="EN-GB">
                                                    any costs or charges (including legal costs on a solicitor-client basis) incurred in recovering any amounts due to SKUdrop by
                                                    the Client.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "56.65pt", textIndent: "-56.65pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    14.3.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                </span>
                                                <span lang="EN-GB">
                                                    The Client agrees to pay any amounts claimed under this indemnity within 5 Business Days of demand by the Company.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <span lang="EN-GB">&nbsp; </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "21.25pt", textIndent: "-21.25pt" }}>
                                                <b>
                                                    <span lang="EN-GB">
                                                        15.
                                                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    </span>
                                                </b>
                                                <b>
                                                    <span lang="EN-GB">Disclaimers</span>
                                                </b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "56.65pt", textIndent: "-56.65pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    15.1.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                </span>
                                                <span lang="EN-GB">
                                                    WHILST SKUDROP WILL ENDEAVOUR TO PROVIDE THE SERVICES IN ACCORDANCE WITH THESE TERMS, THE SERVICES, THE WEBSITE AND THE PORTAL
                                                    ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS.{" "}
                                                </span>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    15.2.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    SKUdrop will make reasonable efforts to process requests for receiving or shipping merchandise in a timely manner but SKUdrop
                                                    makes no representations or warranties regarding the amount of time needed to complete processing because our Service is
                                                    dependent upon many factors outside of our control, such as delays caused by third parties.
                                                </span>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: "21.25pt", textIndent: "-21.25pt" }}>
                                                <b>
                                                    <span lang="EN-GB">
                                                        16.
                                                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    </span>
                                                </b>
                                                <b>
                                                    <span lang="EN-GB">Limitation of Liability</span>
                                                </b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "56.65pt", textIndent: "-56.65pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    16.1.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                </span>
                                                <span lang="EN-GB">
                                                    By using the SKUdrop Services, you acknowledge and agree that SKUdrop disclaims all liability for the acts or omissions
                                                    (including, without limitation, any negligence or willful misconduct) of any third party whether or not selected by or retained
                                                    by SKUdrop.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "56.65pt", textIndent: "-56.65pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    16.2.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                </span>
                                                <span lang="EN-GB">
                                                    Except where agreed in writing, SKUdrop does not insure Goods and the Customer shall make appropriate arrangements to cover the
                                                    Goods against all insurable risks up to their full value (including duties and taxes).{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "56.65pt", textIndent: "-56.65pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    16.3.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                </span>
                                                <b>
                                                    <span lang="EN-GB">Indirect &amp; Consequential Damages. </span>
                                                </b>
                                                <span lang="EN-GB">
                                                    Neither party shall be liable to the other for any indirect, incidental, special or consequential damages or loss of profits,
                                                    revenue or use incurred by the other party, whether in an action for contract or tort, arising from or in relation to this
                                                    Agreement. IN NO EVENT SHALL SKUdrop’S LIABILITY UNDER THIS AGREEMENT EXCEED THE MONIES PAID OR PAYABLE BY YOU TO SKUdrop FOR
                                                    THE APPLICABLE SKUdrop SERVICES EXCLUDING CARRIER FEES OR OTHER THIRD PARTY FEES (“DAMAGES CAP”). IN THE EVENT OF AN
                                                    UNAUTHORISED TRANSACTION BY A SKUdrop EMPLOYEE OR AGENT, SKUdrop IS ONLY LIABLE FOR THE DAMAGES CAP. SKUdrop MUST BE NOTIFIED
                                                    WITHIN THREE(3) DAYS AFTER ANY UNAUTHORISED TRANSACTION OR YOU WAIVE ALL DAMAGES FROM SKUdrop.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "56.65pt", textIndent: "-56.65pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    16.4.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                </span>
                                                <b>
                                                    <span lang="EN-GB">Exclusive Remedy.</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    SKUdrop’S LIABILITY REFERRED TO BELOW SHALL BE YOUR EXCLUSIVE REMEDY AGAINST SKUdrop FOR ANY CLAIM OR CAUSE OF ACTION WHATSOEVER
                                                    RELATING TO LOSS, DAMAGE AND/OR DESTRUCTION OF INVENTORY AND SHALL APPLY TO ALL CLAIMS INCLUDING INVENTORY SHORTAGE AND
                                                    MYSTERIOUS DISAPPEARANCE CLAIMS UNLESS YOU PROVE BY AFFIRMATIVE EVIDENCE THAT SKUdrop CONVERTED THE INVENTORY TO ITS OWN USE.
                                                    YOU WAIVE ANY RIGHTS TO RELY UPON ANY PRESUMPTION OF CONVERSION IMPOSED BY LAW.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "56.65pt", textIndent: "-56.65pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    16.5.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                </span>
                                                <b>
                                                    <span lang="EN-GB">Inventory Count Inaccuracies.</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    {" "}
                                                    IN THE EVENT OF INVENTORY LOSS IN EXCESS OF THE ANNUAL INVENTORY SHRINKAGE ALLOWANCE DUE TO INVENTORY COUNT INACCURACIES,
                                                    INACCURATE INVENTORY COUNTS DURING RECEIVING OR INVENTORY COUNT INACCURACIES AT ANY TIME THAT SKUdrop IS IN POSSESSION OF
                                                    INVENTORY FOR WHICH THE CLAUSES ABOVE IS DETERMINED TO BE INAPPLICABLE AND SKUdrop IS HELD LEGALLY LIABLE, YOU AGREE THAT IT
                                                    WILL BE CONSIDERED AN “INVENTORY LOSS” AND SKUdrop’S LIABILITY SHALL BE LIMITED AS STATED IN 16.4 ABOVE. IN NO EVENT SHALL
                                                    SKUdrop BE LIABLE FOR ANY LOST SALES REVENUE FROM THE INVENTORY LOSS DUE TO INVENTORY COUNT INACCURACIES.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "56.65pt", textIndent: "-56.65pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    16.6.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                </span>
                                                <b>
                                                    <span lang="EN-GB">Projects.</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    {" "}
                                                    IN THE EVENT OF A LOSS DUE TO A PROJECT DEFINED AS AN HOURLY OR PIECE WORK DONE ON EXPECTED OR HELD INVENTORY (“PROJECT”), YOU
                                                    DECLARE THAT SKUdrop’S LIABILITY SHALL BE LIMITED TO THE HOURLY CHARGE YOU PAID TO SKUdrop FOR THE PROJECT.
                                                </span>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: "21.25pt", textIndent: "-21.25pt" }}>
                                                <b>
                                                    <span lang="EN-GB">
                                                        17.
                                                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    </span>
                                                </b>
                                                <b>
                                                    <span lang="EN-GB">Term and Termination</span>
                                                </b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "56.65pt", textIndent: "-56.65pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    17.1.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    The term of this Agreement will commence on the date we accept your registration for use of the Service or when you first use
                                                    the Service, whichever occurs first.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "56.65pt", textIndent: "-56.65pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    17.2.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">The Agreement will continue until terminated by you or by us in accordance with these Terms. </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "56.65pt", textIndent: "-56.65pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    17.3.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    You may terminate this agreement at any time by providing us with written notice or by terminating your account via the SKUdrop
                                                    Portal.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "56.65pt", textIndent: "-56.65pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    17.4.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">We may terminate your use and access to the Service:</span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "77.95pt", textIndent: "-21.25pt" }}>
                                                <span lang="EN-GB">
                                                    (a)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">at our convenience upon provision of 30 days notice;</span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "77.95pt", textIndent: "-21.25pt" }}>
                                                <span lang="EN-GB">
                                                    (b)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    immediately where we determine that you have materially breached the Agreement or you have become Insolvent.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "56.65pt", textIndent: "-56.65pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    17.5.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">We also may temporarily suspend your use or access to the SKUdrop Portal at any time where: </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "77.95pt" }}>
                                                <span lang="EN-GB">
                                                    (a)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
                                                </span>
                                                <span lang="EN-GB">we detect any suspicious activity on or through the Account;</span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "77.95pt" }}>
                                                <span lang="EN-GB">
                                                    (b)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
                                                </span>
                                                <span lang="EN-GB">
                                                    where any person using your Account behaves in an abusive, inappropriate or threatening manner towards SKUdrop, its staff or
                                                    contractors; or
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "77.95pt" }}>
                                                <span lang="EN-GB">
                                                    (c)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                                </span>
                                                <span lang="EN-GB">
                                                    we are investigating a third party complaint of a violation of these Terms, unauthorised transaction, customer complaint,
                                                    dispute or accusation.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "56.65pt", textIndent: "-56.65pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    17.6.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    Where we terminate your use or access to your account we will provide notice to you outlining the reasons for such termination
                                                    and setting out any options you may have to appeal that decision.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "56.65pt", textIndent: "-56.65pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    17.7.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">Upon termination:</span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "77.95pt", textIndent: "-21.25pt" }}>
                                                <span lang="EN-GB">
                                                    (a)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    you must make payment, within 3 Business Days, of all current amounts outstanding for the Service, including any interest;{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "77.95pt", textIndent: "-21.25pt" }}>
                                                <span lang="EN-GB">
                                                    (b)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    any goods that remain in our control will be shipped to the address on file and, if the address is not on file, the billing
                                                    address connected to the billing method used for your account, at your sole expense. If no address is available or you do not
                                                    pay the fees for shipment of the products back to you, we may destroy or liquidate your remaining Inventory at our sole
                                                    discretion.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "56.65pt", textIndent: "-56.65pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    17.8.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    Where we terminate your use or access to your account we may retain your Inventory for up to 180 days where we reasonably
                                                    determine that such retention is required to protect against any potential liability.&nbsp;{" "}
                                                </span>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: "21.25pt", textIndent: "-21.25pt" }}>
                                                <b>
                                                    <span lang="EN-GB">
                                                        18.
                                                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    </span>
                                                </b>
                                                <b>
                                                    <span lang="EN-GB">Disputes </span>
                                                </b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "56.65pt", textIndent: "-56.65pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    18.1.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    All disputes among the Parties arising out of this Agreement or relating to the Services shall be settled or arbitrated in
                                                    accordance with the Disputes Policy.
                                                </span>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: "21.25pt", textIndent: "-21.25pt" }}>
                                                <b>
                                                    <span lang="EN-GB">
                                                        19.
                                                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    </span>
                                                </b>
                                                <b>
                                                    <span lang="EN-GB">Force Majeure </span>
                                                </b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "56.65pt", textIndent: "-56.65pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    19.1.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    SKUdrop shall not be liable for any delay, failure to perform or loss or liability due to any cause beyond its reasonable
                                                    control (“Force Majeure”).{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "56.65pt", textIndent: "-56.65pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    19.2.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    Force Majeure includes, but is not limited to, events of acts of God, strikes, lock-outs or other labour disputes, disease or
                                                    epidemic, industrial disturbance, war, criminal acts, acts of terrorism, acts of the Customer or third parties, interruptions of
                                                    all avenues of transportation, materials or facilities, delay or failure of any supplier critical to the delivery of Inventory
                                                    or performance of the Services.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "56.65pt", textIndent: "-56.65pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    19.3.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    In the event SKUdrop is unable to wholly or partially perform because of any cause beyond its reasonable control, SKUdrop may
                                                    terminate any Order without liability to Customer or its clients.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "56.65pt", textIndent: "-56.65pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    19.4.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    If SKUdrop has exercised reasonable care and is unable, due to causes beyond its control, to affect delivery before expiration
                                                    of the current storage period, the Inventory will be subject to storage charges for each succeeding storage period.
                                                </span>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: "21.25pt" }}>
                                                <b>
                                                    <span lang="EN-GB">
                                                        20.
                                                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp; </span>
                                                    </span>
                                                </b>
                                                <b>
                                                    <span lang="EN-GB">General Provisions</span>
                                                </b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "56.65pt", textIndent: "-56.65pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    20.1.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <b>
                                                    <span lang="EN-GB">No Waiver. </span>
                                                </b>
                                                <span lang="EN-GB">
                                                    No failure or delay by either Party in exercising any of its rights under this Agreement shall be deemed to be a waiver of that
                                                    right, and no waiver by either Party of a breach of any provision of this Agreement shall be deemed to be a waiver of any
                                                    subsequent breach of the same or any other provision.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "56.65pt", textIndent: "-56.65pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    20.2.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <b>
                                                    <span lang="EN-GB">Representations.</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    Each Party acknowledges that, in entering into this Agreement, it does not rely on any representation, warranty, assurance or
                                                    other provision (made innocently or negligently) except as expressly provided in this Agreement.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "56.65pt", textIndent: "-56.65pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    20.3.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <b>
                                                    <span lang="EN-GB">Counterparts.</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    This Agreement may be entered into in any number of counterparts and by the Parties to it on separate counterparts each of which
                                                    when so executed and delivered shall be an original, but all the counterparts together shall constitute one and the same
                                                    instrument.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "56.65pt", textIndent: "-56.65pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    20.4.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <b>
                                                    <span lang="EN-GB">Severance.</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    {" "}
                                                    In the event that one or more of the provisions of this Agreement is found to be unlawful, invalid or otherwise unenforceable,
                                                    those provisions shall be deemed severed from the remainder of this Agreement. The remainder of this Agreement shall be valid
                                                    and enforceable.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "56.65pt", textIndent: "-56.65pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    20.5.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <b>
                                                    <span lang="EN-GB">Time of Essence.</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    The Parties agree that the times and dates referred to in this Agreement are of the essence of this Agreement.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "56.65pt", textIndent: "-56.65pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    20.6.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <b>
                                                    <span lang="EN-GB">Relationship of the Parties.</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    {" "}
                                                    Nothing in this Agreement shall constitute or be deemed to constitute a partnership, joint venture or employment relationship
                                                    between the Parties other than the contractual relationship expressly provided for in this Agreement.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "56.65pt", textIndent: "-56.65pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    20.7.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <b>
                                                    <span lang="EN-GB">Notices to you.</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    The Client agrees that SKUdrop may provide notice to it by posting said notice on the Website, emailing to the email address
                                                    provided in your Registration Information or connected to your account, or mailing it to the street address listed in your
                                                    account. Such notice shall be deemed delivered and received by you:{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "77.95pt", textIndent: "-21.0pt" }}>
                                                <span lang="EN-GB">
                                                    (a)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">24 hours after posting on the Website or being sent by email; </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "77.95pt", textIndent: "-21.0pt" }}>
                                                <span lang="EN-GB">
                                                    (b)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">3 Business Days after sending by ordinary mail. </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "56.65pt", textIndent: "-56.65pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    20.8.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <b>
                                                    <span lang="EN-GB">Notices to SKUdrop.</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    {" "}
                                                    All notices&nbsp; to be given to SKUdrop must be in writing and signed by a duly authorised officer of the Client and be posted
                                                    by registered post to RM B3, 19/F TUNG LEE COMM BLDG, 91-97 JERVOIS ST SHEUNG WAN, Hong Kong, 999077. Notices sent by registered
                                                    post shall be deemed received upon delivery.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "56.65pt", textIndent: "-56.65pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    20.9.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <b>
                                                    <span lang="EN-GB">Severability.</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    {" "}
                                                    If any provision of these Terms is held invalid or unenforceable by a court of competent jurisdiction, such provision shall be
                                                    modified to the extent necessary to make such provision valid and enforceable and the remaining provisions of these Terms shall
                                                    remain in effect and enforceable in accordance with their terms.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "56.65pt", textIndent: "-56.65pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    20.10.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                </span>
                                                <b>
                                                    <span lang="EN-GB">Assignment.</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    {" "}
                                                    You may not transfer or assign any rights or obligations you have under this Agreement without SKUdrop’s prior written consent
                                                    and any attempted assignment without that consent will be void. SKUdrop reserves the right to transfer or assign this Agreement
                                                    or any right or obligation under this Agreement at any time.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "56.65pt", textIndent: "-56.65pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    20.11.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                </span>
                                                <b>
                                                    <span lang="EN-GB">Governing Law. </span>
                                                </b>
                                                <span lang="EN-GB">
                                                    These Terms and any associated/collateral agreements made between the Client and SKUdrop wherever made shall be construed in
                                                    accordance with the applicable laws of the State of New South Wales and shall be subject to the exclusive jurisdiction of the
                                                    courts of that State and those courts which can hear appeals from those courts. Where these Terms are held to be subject to the
                                                    laws of any jurisdiction other than the Commonwealth of Australia and the State of New South Wales, then these Terms shall
                                                    continue to apply to the maximum extent and shall be void only to the extent that they are inconsistent with the laws of that
                                                    other jurisdiction.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "56.65pt", textIndent: "-56.65pt" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    20.12.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                </span>
                                                <b>
                                                    <span lang="EN-GB">Entire Agreement.</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    This Agreement contains the entire agreement between the Parties with respect to its subject matter and may not be modified
                                                    except by a document in writing signed by the duly authorised representatives of the Parties.
                                                </span>
                                            </p>
                                            <p className="MsoNormal"></p>
                                            <p className="MsoNormal" style={{ marginLeft: "21.25pt" }}>
                                                <b>
                                                    <span lang="EN-GB">
                                                        21.
                                                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp; </span>
                                                    </span>
                                                </b>
                                                <b>
                                                    <span lang="EN-GB">Amendment of Terms</span>
                                                </b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "21.25pt", textIndent: "0in" }}>
                                                <span lang="EN-GB">
                                                    SKUdrop may alter or amend these terms and or the associated policies from time to time at our sole discretion. Your continued
                                                    use of the Services or the Platform after such a change shall constitute your acceptance of these amended terms. SKUdrop will
                                                    endeavour to provide you with notice of any changes to these Terms or the Policies, but SKUdrop’s failure to do so shall not
                                                    excuse your obligation to comply with such modified Terms and Policies.
                                                </span>
                                            </p>
                                            <h3 style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <span lang="EN-GB">PART 2 – FEE SCHEDULE</span>
                                            </h3>

                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <span lang="EN-GB">
                                                    This Fee Schedule is incorporated into the SKUDROP General Terms as published on the SKUDROP Website (“Terms”). Where there is
                                                    any conflict or material inconsistency between this SLA and the Terms, the Terms shall prevail to the extent of any
                                                    inconsistency.
                                                </span>
                                            </p>
                                            <table style={{ borderCollapse: "collapse", border: "none" }} width={607} cellSpacing={0} cellPadding={0} border={1}>
                                                <tbody>
                                                    <tr style={{ height: "21.0pt" }}>
                                                        <td
                                                            style={{
                                                                width: "151.7pt",
                                                                border: "solid black 1.0pt",
                                                                background: "#EFEFEF",
                                                                padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                                                height: "21.0pt",
                                                            }}
                                                            width={202}
                                                            valign="top"
                                                        >
                                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                                <b>
                                                                    <span lang="EN-GB">Item</span>
                                                                </b>
                                                            </p>
                                                        </td>
                                                        <td
                                                            style={{
                                                                width: "303.4pt",
                                                                border: "solid black 1.0pt",
                                                                borderLeft: "none",
                                                                background: "#EFEFEF",
                                                                padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                                                height: "21.0pt",
                                                            }}
                                                            width={405}
                                                            valign="top"
                                                        >
                                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                                <b>
                                                                    <span style={{ color: "black" }} lang="EN-GB">
                                                                        Fee
                                                                    </span>
                                                                </b>
                                                            </p>
                                                        </td>
                                                    </tr>
                                                    <tr style={{ height: "21.0pt" }}>
                                                        <td
                                                            style={{
                                                                width: "151.7pt",
                                                                border: "solid black 1.0pt",
                                                                borderTop: "none",
                                                                padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                                                height: "21.0pt",
                                                            }}
                                                            width={202}
                                                            valign="top"
                                                        >
                                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}></p>
                                                        </td>
                                                        <td
                                                            style={{
                                                                width: "303.4pt",
                                                                borderTop: "none",
                                                                borderLeft: "none",
                                                                borderBottom: "solid black 1.0pt",
                                                                borderRight: "solid black 1.0pt",
                                                                padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                                                height: "21.0pt",
                                                            }}
                                                            width={405}
                                                            valign="top"
                                                        >
                                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                                <span lang="EN-GB">Refer to our website for Rates </span>
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <h4
                                                style={{
                                                    marginTop: "24.0pt",
                                                    marginRight: "0in",
                                                    marginBottom: "0in",
                                                    marginLeft: "0in",
                                                }}
                                            >
                                                <span style={{ color: "black" }} lang="EN-GB">
                                                    Dictionary
                                                </span>
                                            </h4>

                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <b>
                                                    <span lang="EN-GB">Authorised User </span>
                                                </b>
                                                <span lang="EN-GB">
                                                    &nbsp;means the representatives of the Client who are duly empowered and authorised by the Client to use the Services, access
                                                    the SKUdrop Portal, to act for and on their behalf of the Client and bind it in Contract.
                                                </span>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <b>
                                                    <span lang="EN-GB">Business Day </span>
                                                </b>
                                                <span lang="EN-GB">
                                                    means a day that is neither a Saturday or Sunday on which banks are open for general business in New South Wales.{" "}
                                                </span>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <span lang="EN-GB">
                                                    CLP&nbsp; means the Classification, Labelling and Packaging Regulation (EC No1272/ 2008) based upon the GHS.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <b></b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <b>
                                                    <span lang="EN-GB">Competition and Consumer Act </span>
                                                </b>
                                                <span lang="EN-GB">
                                                    means the<i> Competition and Consumer Act 2010</i> (Cth) as amended from time to time.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <b></b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <b>
                                                    <span lang="EN-GB">Confidential Information</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    {" "}
                                                    means all documents, manuals, materials and/or information of a party hereto that is not generally known to the public, whether
                                                    of a technical, business or other nature (including, without limitation, trade secrets, know-how and information relating to the
                                                    technology, customers, vendors, business plans, marketing activities, finances and other business affairs of such party), that
                                                    is disclosed by such party to the other party in written, oral, electronic and/or other forms or that otherwise comes to the
                                                    knowledge of the other party in the course of Its dealings with, or its physical or electronic access, to the premises of, such
                                                    party and that has been identified as being proprietary and/or confidential or that by the nature of the circumstances
                                                    surrounding the disclosure or receipt should reasonably be construed as proprietary or confidential;
                                                </span>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <b>
                                                    <span lang="EN-GB">Consequential Loss</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    {" "}
                                                    means loss or damage arising from a breach of contract, tort, or any other basis in law or equity including, loss of profits,
                                                    loss of revenue, loss of production, loss of opportunity, indirect or remote or unforeseeable loss, loss of business reputation
                                                    or goodwill,, or any similar loss which was not contemplated by the Parties at the time of entering the Agreement.
                                                </span>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <b>
                                                    <span lang="EN-GB">Customs</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    means any authority charged with enforcement of import and export regulations wherever situated including the Australian Customs
                                                    and Border Protection Service, U.S. Customs and Border Protection Agency,{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <b></b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <b>
                                                    <span lang="EN-GB">Customs Duty</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    {" "}
                                                    means the same as “Duty” in the <i>Customs Act 1901</i> (Cth), as amended from time to time.{" "}
                                                </span>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <b>
                                                    <span lang="EN-GB">GHS</span>
                                                </b>
                                                <span lang="EN-GB"> means the Globally Harmonized System of Classification and Labelling of Chemicals published by the UNECE.</span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "63.75pt", textIndent: "0in" }}></p>
                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <b>
                                                    <span lang="EN-GB">Government Authorities </span>
                                                </b>
                                                <span lang="EN-GB">
                                                    &nbsp;means, without limitation, all Government departments and agencies with responsibility for the import and export of goods,
                                                    the collection of revenue on the import and export of goods and the transport of those goods to include, without limitation,
                                                    Customs, AQIS, the ATO and the RSA;
                                                </span>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <b>
                                                    <span lang="EN-GB">Hazardous Goods</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    {" "}
                                                    means an item or substance that has or is capable of posing an unreasonable risk to health, safety, and property when
                                                    transported in commerce, and has designated as hazardous or prohibited by a relevant Government Authority. The term includes
                                                    prohibited goods listed by the Australian Border Force, any products considered a dangerous good or hazmat by GHS/CLP
                                                    Standards,&nbsp; explosives, radioactive materials, flammable or corrosive substances and toxic/infectious materials.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <b></b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <b>
                                                    <span lang="EN-GB">Insolvent</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    means any event or circumstance in which the Client is or becomes unable to pay any outstanding debt on the day it falls due for
                                                    payment and includes where a petition is presented or proceeding is commenced for the winding up, insolvency, administration,
                                                    dissolution or bankruptcy of the Customer or for the appointment of a liquidator, receiver, administrator, trustee of the
                                                    Customer or of all or any part of the Customer’s business.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <b></b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <b>
                                                    <span lang="EN-GB">Insurance Proceeds </span>
                                                </b>
                                                <span lang="EN-GB">
                                                    means &nbsp;amounts paid by an insurer under any Insurance Policy, other than amounts (i) to cover expenses incurred by or on
                                                    behalf of SKUdrop in connection with procuring such proceeds or (ii) required to be paid over to any other third-party pursuant
                                                    to law.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <b></b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <b>
                                                    <span lang="EN-GB">Inventory</span>
                                                </b>
                                                <span lang="EN-GB">means all goods delivered to SKU Drop for or relating to the Services.</span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <b></b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <b>
                                                    <span lang="EN-GB">NVOCC</span>
                                                </b>
                                                <span lang="EN-GB">means Non-Vessel-Operating Common Carrier. </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <b></b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <b>
                                                    <span lang="EN-GB">Perishable Goods</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    {" "}
                                                    means any Goods liable to waste, spoilage or deterioration, including Goods that require temperature controlled storage and are
                                                    susceptible to loss or damage if those controlled conditions change.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <b></b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <b>
                                                    <span lang="EN-GB">Related Body Corporate </span>
                                                </b>
                                                <span lang="EN-GB">means in relation to the client the relevant body corporate is: </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: ".5in" }}>
                                                <span lang="EN-GB">
                                                    (a)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
                                                </span>
                                                <span lang="EN-GB">a holding company of the Client; </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: ".5in" }}>
                                                <span lang="EN-GB">
                                                    (b)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
                                                </span>
                                                <span lang="EN-GB">a subsidiary of the Client; </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: ".5in" }}>
                                                <span lang="EN-GB">
                                                    (c)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                                </span>
                                                <span lang="EN-GB">a subsidiary of a holding company of the Client. </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <b></b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <b>
                                                    <span lang="EN-GB">Service</span>
                                                </b>
                                                <span lang="EN-GB">means the Services provided by SKUdrop. </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <b></b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <b>
                                                    <span lang="EN-GB">SKUdrop Website </span>
                                                </b>
                                                <span lang="EN-GB">means the website located at www.SKUdrop.com.</span>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <b>
                                                    <span lang="EN-GB">Subcontractor</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    means any third party (including that third party’s employees, agents and contractors) engaged to provide all or part of the
                                                    Services.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <b></b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <b>
                                                    <span lang="EN-GB">Storage</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    {" "}
                                                    means the care, storage, consolidation, loading and unloading process and contingent services rendered to you in relation to
                                                    Goods stored and handled in connection with the Service.
                                                </span>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <b>
                                                    <span lang="EN-GB">Storage Lien Legislation</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    {" "}
                                                    means the Storage Liens Act 1935 (NSW), Warehouseman’s Liens Act 1958 (Vic), Warehouse Liens and Storage Act 1990 (SA), Storage
                                                    Liens Act 1973 (QLD), Warehousemen’s Liens Act 1969 (NT), Disposal of Uncollected Goods Act 1968 (TAS), Warehousemen’s Liens Act
                                                    1952 (WA) and Mercantile Law Act 1962 (ACT) and similar legislation in any applicable jurisdiction.{" "}
                                                </span>
                                            </p>
                                            <h3
                                                style={{
                                                    marginTop: "24.0pt",
                                                    marginRight: "0in",
                                                    marginBottom: "0in",
                                                    marginLeft: "0in",
                                                    textIndent: "0in",
                                                }}
                                            >
                                                <span lang="EN-GB">WAREHOUSING &amp; LOGISTICS POLICY</span>
                                            </h3>

                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <span lang="EN-GB">
                                                    This Warehousing &amp; Logistics Policy (“Logistics Policy”) is incorporated into the SKUDROP General Terms as published on the
                                                    SKUDROP Website (“Terms”). Where there is any conflict or material inconsistency between these Logistics Policy and the Terms,
                                                    the Terms shall prevail to the extent of any inconsistency.{" "}
                                                </span>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <span lang="EN-GB">
                                                    BEFORE FORWARDING ANY GOODS TO SKUDROP PLEASE READ THIS LOGISTICS POLICY CAREFULLY AND ENSURE THAT YOU MEET ALL REQUIREMENTS OF
                                                    THIS POLICY.{" "}
                                                </span>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: ".5in" }}>
                                                <b>
                                                    <span lang="EN-GB">
                                                        (c)
                                                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                                    </span>
                                                </b>
                                                <b>
                                                    <span lang="EN-GB">Packaging and Labelling</span>
                                                </b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    (i)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    The Client agrees to regularly review and comply with all Amazon and SKUdrop requirements, as amended from time to time, for
                                                    packaging and labelling when shipping products to SKUdrop.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    (ii)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">Without limitation the Client will ensure that: </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt", textIndent: "-21.25pt" }}>
                                                <b>
                                                    <span style={{ fontFamily: '"Arial",sans-serif' }} lang="EN-GB">
                                                        (a)
                                                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span>
                                                    </span>
                                                </b>
                                                <span lang="EN-GB">each product is appropriately packaged and includes sufficient padding to protect fragile components; </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt", textIndent: "-21.25pt" }}>
                                                <b>
                                                    <span style={{ fontFamily: '"Arial",sans-serif' }} lang="EN-GB">
                                                        (b)<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
                                                    </span>
                                                </b>
                                                <span lang="EN-GB">
                                                    each parcel, pallet or item is legibly and durably labelled with an appropriately formatted shipping address and global shipping
                                                    marks as are required;
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt", textIndent: "-21.25pt" }}>
                                                <b>
                                                    <span style={{ fontFamily: '"Arial",sans-serif' }} lang="EN-GB">
                                                        (c)
                                                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span>
                                                    </span>
                                                </b>
                                                <span lang="EN-GB">the products match the quantities, dimensions and weights reported to SKUdrop;</span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt", textIndent: "-21.25pt" }}>
                                                <b>
                                                    <span style={{ fontFamily: '"Arial",sans-serif' }} lang="EN-GB">
                                                        (d)<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
                                                    </span>
                                                </b>
                                                <span lang="EN-GB">
                                                    any Hazardous or Potentially Hazardous goods meet the requirements of Amazon, UPS/FedEx and SKUdrop’s Hazardous Goods Policy;
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt", textIndent: "-21.25pt" }}>
                                                <b>
                                                    <span style={{ fontFamily: '"Arial",sans-serif' }} lang="EN-GB">
                                                        (e)
                                                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span>
                                                    </span>
                                                </b>
                                                <span lang="EN-GB">
                                                    meet all requirements of the Amazon Packaging and prep requirements located here:{" "}
                                                    <a href="https://sellercentral.amazon.com/gp/help/external/200141500?language=en_US">
                                                        <span style={{ color: "#1155CC" }}>Amazon Packaging and Prep Requirements</span>
                                                    </a>
                                                    .{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    (iii)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    Failure to meet these shipping and labelling requirements may result in additional fees (as outlined in our Fee Policy) or the
                                                    return or destruction of your product at your sole expense.{" "}
                                                </span>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: ".5in" }}>
                                                <b>
                                                    <span style={{ fontFamily: '"Arial",sans-serif' }} lang="EN-GB">
                                                        (f)<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
                                                    </span>
                                                </b>
                                                <b>
                                                    <span lang="EN-GB">Errors, Non-Conformities and Recalls</span>
                                                </b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    (i)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    You are solely responsible for any non-performance, misdelivery or other mistake or act in connection with the fulfilment of
                                                    Your Products, and agree to reimburse us for any costs we may incur in connection with such errors
                                                </span>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: ".5in" }}>
                                                <b>
                                                    <span style={{ fontFamily: '"Arial",sans-serif' }} lang="EN-GB">
                                                        (g)<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
                                                    </span>
                                                </b>
                                                <b>
                                                    <span lang="EN-GB">Lost or Damaged Product</span>
                                                </b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    (i)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    Where products are lost or damaged whilst in our control we may reimburse you where we are able to substantiate that the loss or
                                                    damage was caused by the negligence of our employees or subcontractors.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    (ii)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    Any claim for loss or damage must be made within [60 days] of the date of receipt of the goods by us or the third-party
                                                    facility.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    (iii)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    In order to submit a claim for reimbursement for lost or damaged product you must provide us with all requested information and
                                                    documents that we require to investigate your claim.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    (iv)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">Once we have investigated your claim we will confirm with you whether you are eligible for reimbursement. </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    (v)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    If you are not satisfied with the results of the investigation you may file a Notice of Claim in accordance with the Disputes
                                                    Policy.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    (vi)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    You agree that SKUdrop will have a 1.5% shrink allowance based on the value of your account’s Inventory on the stated cost value
                                                    measured on an annual basis and subject to the Limitations of Liability.
                                                </span>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: ".5in" }}>
                                                <b>
                                                    <span style={{ fontFamily: '"Arial",sans-serif' }} lang="EN-GB">
                                                        (h)<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
                                                    </span>
                                                </b>
                                                <b>
                                                    <span lang="EN-GB">Perishable &amp; Hazardous Goods</span>
                                                </b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    (i)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    The Client must obtain SKUdrop’s written approval at least five (5) Business Days prior to shipment if any of the Inventory
                                                    being shipped to SKUdrop is classified as either Perishable or Hazardous Goods.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    (ii)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">SKUdrop may, acting reasonably, classify certain Goods as Perishable Goods or Hazardous Goods.</span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    (iii)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    In the event SKUdrop agrees to provide the Services in relation to Perishable or Hazardous Goods, which may be limited to those
                                                    which qualify for regulatory exceptions such as a limited quantity or small lithium battery, the Client must provide SKUdrop
                                                    with any documentation required by SKUdrop at the time of shipment.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    (iv)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    If SKUdrop discovers or reasonably suspects that a Inventory containing Perishable or Hazardous Goods has been shipped to
                                                    SKUdrop without written approval, the shipment may be destroyed, rejected or held pending receipt of return shipment
                                                    instructions from Client at SKUdrop’s sole and absolute discretion and at the Client’s sole expense.
                                                </span>
                                            </p>
                                            <p
                                                className="MsoNormal"
                                                style={{
                                                    marginTop: "12.0pt",
                                                    marginRight: "0in",
                                                    marginBottom: ".0001pt",
                                                    marginLeft: "0in",
                                                    textIndent: "0in",
                                                    pageBreakAfter: "avoid",
                                                }}
                                            >
                                                <b>
                                                    <span lang="EN-GB">Disclaimer. </span>
                                                </b>
                                                <span lang="EN-GB">
                                                    SKUdrop is not the Importer of Record nor Merchant of Record for any of your Inventory stored pursuant to the Services and shall
                                                    not be held liable for complying with your instructions through the SKUdrop Services. You understand that SKUdrop does not
                                                    inspect your Inventory nor does SKUdrop take responsibility for the business decisions that you make and implement through the
                                                    SKUdrop Services. SKUdrop cannot control or ensure that a buyer or seller with whom you do business will remit payment for
                                                    Inventory in accordance with your agreement with them.
                                                </span>
                                            </p>
                                            <p
                                                className="MsoNormal"
                                                style={{
                                                    marginTop: "24.0pt",
                                                    marginRight: "0in",
                                                    marginBottom: ".0001pt",
                                                    marginLeft: "0in",
                                                    textIndent: "0in",
                                                    pageBreakAfter: "avoid",
                                                }}
                                            >
                                                <b>
                                                    <span style={{ fontSize: "22.0pt" }} lang="EN-GB">
                                                        PRIVACY POLICY
                                                    </span>
                                                </b>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <span lang="EN-GB">
                                                    We at SKUdrop respect your privacy and are committed to protecting it through our compliance with this privacy policy (“Policy”)
                                                    and the <i>Privacy Act 1988</i>
                                                    (“Privacy Act”).{" "}
                                                </span>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <span lang="EN-GB">
                                                    This Privacy Policy (“Privacy Policy”) is incorporated into the SKUDROP General Terms as published on the SKUDROP Website
                                                    (“Terms”). Where there is any conflict or material inconsistency between this Privacy Policy and the Terms, the Terms shall
                                                    prevail to the extent of any inconsistency.{" "}
                                                </span>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <span lang="EN-GB">
                                                    This Privacy Policy describes the types of information we may collect from you or that you may provide (“Personal Information”)
                                                    on the SKUdrop.com website (“Website”), “SKUdrop” mobile application (“Mobile Application”), and any of their related products
                                                    and services (collectively, “Services”), and our practices for collecting, using, maintaining, protecting, and disclosing that
                                                    Personal Information. It also describes the choices available to you regarding our use of your Personal Information and how you
                                                    can access and update it.
                                                </span>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <span lang="EN-GB">
                                                    By accessing and using the Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this
                                                    Policy. This Policy does not apply to the practices of companies that we do not own or control, or to individuals that we do not
                                                    employ or manage.{" "}
                                                </span>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: "28.3pt", textIndent: "-28.3pt", lineHeight: "115%" }}>
                                                <b>
                                                    <span lang="EN-GB">
                                                        1.
                                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        </span>
                                                    </span>
                                                </b>
                                                <b>
                                                    <span lang="EN-GB">What Personal Information do we Collect?</span>
                                                </b>
                                            </p>
                                            <p
                                                className="MsoNormal"
                                                style={{
                                                    marginLeft: "63.75pt",
                                                    textIndent: "-63.75pt",
                                                    lineHeight: "115%",
                                                }}
                                            >
                                                <span style={{ fontFamily: '"Arial",sans-serif' }} lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    1.1.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    In the course of providing services to you we may collect personal information in order to provide features of the service and
                                                    improve the range of products and services we can make available to you.{" "}
                                                </span>
                                            </p>
                                            <p
                                                className="MsoNormal"
                                                style={{
                                                    marginLeft: "63.75pt",
                                                    textIndent: "-63.75pt",
                                                    lineHeight: "115%",
                                                }}
                                            >
                                                <span style={{ fontFamily: '"Arial",sans-serif' }} lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    1.2.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">We may collect information you provide to us as set out in the examples below: </span>
                                            </p>

                                            <table
                                                className="MsoNormalTable"
                                                style={{
                                                    marginLeft: "29.75pt",
                                                    borderCollapse: "collapse",
                                                    border: "none",
                                                }}
                                                width={570}
                                                cellSpacing={0}
                                                cellPadding={0}
                                                border={1}
                                            >
                                                <tbody>
                                                    <tr style={{ height: "21.0pt" }}>
                                                        <td
                                                            style={{
                                                                width: "192.0pt",
                                                                border: "solid black 1.0pt",
                                                                background: "#F3F3F3",
                                                                padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                                                height: "21.0pt",
                                                            }}
                                                            width={256}
                                                            valign="top"
                                                        >
                                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                                <span style={{ fontSize: "10.0pt" }} lang="EN-GB">
                                                                    Source of Personal Information
                                                                </span>
                                                            </p>
                                                        </td>
                                                        <td
                                                            style={{
                                                                width: "235.5pt",
                                                                border: "solid black 1.0pt",
                                                                borderLeft: "none",
                                                                background: "#F3F3F3",
                                                                padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                                                height: "21.0pt",
                                                            }}
                                                            width={314}
                                                            valign="top"
                                                        >
                                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                                <span style={{ fontSize: "10.0pt", color: "black" }} lang="EN-GB">
                                                                    Examples of information:{" "}
                                                                </span>
                                                            </p>
                                                        </td>
                                                    </tr>
                                                    <tr style={{ height: "21.0pt" }}>
                                                        <td
                                                            style={{
                                                                width: "192.0pt",
                                                                border: "solid black 1.0pt",
                                                                borderTop: "none",
                                                                padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                                                height: "21.0pt",
                                                            }}
                                                            width={256}
                                                            valign="top"
                                                        >
                                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                                <span style={{ fontSize: "10.0pt" }} lang="EN-GB">
                                                                    Information you provide to us:
                                                                </span>
                                                            </p>
                                                        </td>
                                                        <td
                                                            style={{
                                                                width: "235.5pt",
                                                                borderTop: "none",
                                                                borderLeft: "none",
                                                                borderBottom: "solid black 1.0pt",
                                                                borderRight: "solid black 1.0pt",
                                                                padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                                                height: "21.0pt",
                                                            }}
                                                            width={314}
                                                            valign="top"
                                                        >
                                                            <p
                                                                className="MsoNormal"
                                                                style={{
                                                                    marginLeft: "21.25pt",
                                                                    textIndent: "-14.15pt",
                                                                    lineHeight: "115%",
                                                                }}
                                                            >
                                                                <span style={{ fontSize: "10.0pt", lineHeight: "115%" }} lang="EN-GB">
                                                                    ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                                                </span>
                                                                <span style={{ fontSize: "10.0pt", lineHeight: "115%" }} lang="EN-GB">
                                                                    information such as your name, address and phone numbers;{" "}
                                                                </span>
                                                            </p>
                                                            <p
                                                                className="MsoNormal"
                                                                style={{
                                                                    marginLeft: "21.25pt",
                                                                    textIndent: "-14.15pt",
                                                                    lineHeight: "115%",
                                                                }}
                                                            >
                                                                <span style={{ fontSize: "10.0pt", lineHeight: "115%" }} lang="EN-GB">
                                                                    ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                                                </span>
                                                                <span style={{ fontSize: "10.0pt", lineHeight: "115%" }} lang="EN-GB">
                                                                    payment details;{" "}
                                                                </span>
                                                            </p>
                                                            <p
                                                                className="MsoNormal"
                                                                style={{
                                                                    marginLeft: "21.25pt",
                                                                    textIndent: "-14.15pt",
                                                                    lineHeight: "115%",
                                                                }}
                                                            >
                                                                <span style={{ fontSize: "10.0pt", lineHeight: "115%" }} lang="EN-GB">
                                                                    ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                                                </span>
                                                                <span style={{ fontSize: "10.0pt", lineHeight: "115%" }} lang="EN-GB">
                                                                    Amazon Selling Partner ID;
                                                                </span>
                                                            </p>
                                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                                <span style={{ fontSize: "10.0pt" }} lang="EN-GB">
                                                                    &nbsp;
                                                                </span>
                                                            </p>
                                                        </td>
                                                    </tr>
                                                    <tr style={{ height: "21.0pt" }}>
                                                        <td
                                                            style={{
                                                                width: "192.0pt",
                                                                border: "solid black 1.0pt",
                                                                borderTop: "none",
                                                                padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                                                height: "21.0pt",
                                                            }}
                                                            width={256}
                                                            valign="top"
                                                        >
                                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                                <span style={{ fontSize: "10.0pt" }} lang="EN-GB">
                                                                    Information automatically collected:{" "}
                                                                </span>
                                                            </p>
                                                        </td>
                                                        <td
                                                            style={{
                                                                width: "235.5pt",
                                                                borderTop: "none",
                                                                borderLeft: "none",
                                                                borderBottom: "solid black 1.0pt",
                                                                borderRight: "solid black 1.0pt",
                                                                padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                                                height: "21.0pt",
                                                            }}
                                                            width={314}
                                                            valign="top"
                                                        >
                                                            <p
                                                                className="MsoNormal"
                                                                style={{
                                                                    marginLeft: "21.25pt",
                                                                    textIndent: "-14.15pt",
                                                                    lineHeight: "115%",
                                                                }}
                                                            >
                                                                <span style={{ fontSize: "10.0pt", lineHeight: "115%" }} lang="EN-GB">
                                                                    ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                                                </span>
                                                                <span style={{ fontSize: "10.0pt", lineHeight: "115%" }} lang="EN-GB">
                                                                    your login details, account password and email address;{" "}
                                                                </span>
                                                            </p>
                                                            <p
                                                                className="MsoNormal"
                                                                style={{
                                                                    marginLeft: "21.25pt",
                                                                    textIndent: "-14.15pt",
                                                                    lineHeight: "115%",
                                                                }}
                                                            >
                                                                <span style={{ fontSize: "10.0pt", lineHeight: "115%" }} lang="EN-GB">
                                                                    ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                                                </span>
                                                                <span style={{ fontSize: "10.0pt", lineHeight: "115%" }} lang="EN-GB">
                                                                    your Amazon Selling Partner ID;
                                                                </span>
                                                            </p>
                                                            <p
                                                                className="MsoNormal"
                                                                style={{
                                                                    marginLeft: "21.25pt",
                                                                    textIndent: "-14.15pt",
                                                                    lineHeight: "115%",
                                                                }}
                                                            >
                                                                <span style={{ fontSize: "10.0pt", lineHeight: "115%" }} lang="EN-GB">
                                                                    ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                                                </span>
                                                                <span style={{ fontSize: "10.0pt", lineHeight: "115%" }} lang="EN-GB">
                                                                    phone numbers used to call any support service.{" "}
                                                                </span>
                                                            </p>
                                                            <p className="MsoNormal" style={{ marginLeft: ".5in", textIndent: "0in" }}>
                                                                <span style={{ fontSize: "10.0pt" }} lang="EN-GB">
                                                                    &nbsp;
                                                                </span>
                                                            </p>
                                                        </td>
                                                    </tr>
                                                    <tr style={{ height: "21.0pt" }}>
                                                        <td
                                                            style={{
                                                                width: "192.0pt",
                                                                border: "solid black 1.0pt",
                                                                borderTop: "none",
                                                                padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                                                height: "21.0pt",
                                                            }}
                                                            width={256}
                                                            valign="top"
                                                        >
                                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                                <span style={{ fontSize: "10.0pt" }} lang="EN-GB">
                                                                    Information from other sources:{" "}
                                                                </span>
                                                            </p>
                                                        </td>
                                                        <td
                                                            style={{
                                                                width: "235.5pt",
                                                                borderTop: "none",
                                                                borderLeft: "none",
                                                                borderBottom: "solid black 1.0pt",
                                                                borderRight: "solid black 1.0pt",
                                                                padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                                                height: "21.0pt",
                                                            }}
                                                            width={314}
                                                            valign="top"
                                                        >
                                                            <p
                                                                className="MsoNormal"
                                                                style={{
                                                                    marginLeft: "21.25pt",
                                                                    textIndent: "-14.15pt",
                                                                    lineHeight: "115%",
                                                                }}
                                                            >
                                                                <span style={{ fontSize: "10.0pt", lineHeight: "115%" }} lang="EN-GB">
                                                                    ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                                                </span>
                                                                <span style={{ fontSize: "10.0pt", lineHeight: "115%" }} lang="EN-GB">
                                                                    delivery and address information from your manufacturer;{" "}
                                                                </span>
                                                            </p>
                                                            <p
                                                                className="MsoNormal"
                                                                style={{
                                                                    marginLeft: "21.25pt",
                                                                    textIndent: "-14.15pt",
                                                                    lineHeight: "115%",
                                                                }}
                                                            >
                                                                <span style={{ fontSize: "10.0pt", lineHeight: "115%" }} lang="EN-GB">
                                                                    ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                                                </span>
                                                                <span style={{ fontSize: "10.0pt", lineHeight: "115%" }} lang="EN-GB">
                                                                    account information.
                                                                </span>
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <p className="MsoNormal" style={{ marginLeft: "28.3pt", textIndent: "-28.3pt", lineHeight: "115%" }}>
                                                <b>
                                                    <span lang="EN-GB">
                                                        2.
                                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        </span>
                                                    </span>
                                                </b>
                                                <b>
                                                    <span lang="EN-GB">Why do we Collect Personal Information?</span>
                                                </b>
                                            </p>
                                            <p
                                                className="MsoNormal"
                                                style={{
                                                    marginLeft: "63.75pt",
                                                    textIndent: "-63.75pt",
                                                    lineHeight: "115%",
                                                }}
                                            >
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    2.1.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                </span>
                                                <span lang="EN-GB">
                                                    We may use your personal information in order to operate the Service and make improvements to our Service.{" "}
                                                </span>
                                            </p>
                                            <p
                                                className="MsoNormal"
                                                style={{
                                                    marginLeft: "63.75pt",
                                                    textIndent: "-63.75pt",
                                                    lineHeight: "115%",
                                                }}
                                            >
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    2.2.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                </span>
                                                <span lang="EN-GB">
                                                    Processing your Personal Information depends on how you interact with the Services, where you are located in the world and if
                                                    one of the following applies:{" "}
                                                </span>
                                            </p>
                                            <p
                                                className="MsoNormal"
                                                style={{
                                                    marginLeft: "70.85pt",
                                                    textIndent: "-21.25pt",
                                                    lineHeight: "115%",
                                                }}
                                            >
                                                <span lang="EN-GB">
                                                    (a)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">you have given your consent for one or more specific purposes; </span>
                                            </p>
                                            <p
                                                className="MsoNormal"
                                                style={{
                                                    marginLeft: "70.85pt",
                                                    textIndent: "-21.25pt",
                                                    lineHeight: "115%",
                                                }}
                                            >
                                                <span lang="EN-GB">
                                                    (b)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    provision of information is necessary for the performance of an agreement with you and/or for any pre-contractual obligations
                                                    thereof;{" "}
                                                </span>
                                            </p>
                                            <p
                                                className="MsoNormal"
                                                style={{
                                                    marginLeft: "70.85pt",
                                                    textIndent: "-21.25pt",
                                                    lineHeight: "115%",
                                                }}
                                            >
                                                <span lang="EN-GB">
                                                    (c)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">where processing is necessary for compliance with a legal obligation to which you are subject; </span>
                                            </p>
                                            <p
                                                className="MsoNormal"
                                                style={{
                                                    marginLeft: "70.85pt",
                                                    textIndent: "-21.25pt",
                                                    lineHeight: "115%",
                                                }}
                                            >
                                                <span lang="EN-GB">
                                                    (d)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in
                                                    us;{" "}
                                                </span>
                                            </p>
                                            <p
                                                className="MsoNormal"
                                                style={{
                                                    marginLeft: "70.85pt",
                                                    textIndent: "-21.25pt",
                                                    lineHeight: "115%",
                                                }}
                                            >
                                                <span lang="EN-GB">
                                                    (e)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    where processing is necessary for the purposes of the legitimate interests pursued by us or by a third party.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    2.3.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">The purposes for which we use your Personal Information include:</span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    (a)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
                                                </span>
                                                <b>
                                                    <span lang="EN-GB">(Provision of Service)</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    {" "}
                                                    to provide you with the Service, handle orders, deliver products, payment processing and to communicate with you about the
                                                    Service.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    (b)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
                                                </span>
                                                <b>
                                                    <span lang="EN-GB">(Support)</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    {" "}
                                                    We may use your Personal Information to improve the usability, functionality, reliability and to fix errors in the Services. We
                                                    may also use your Personal Information to communicate with you or answer support requests that you submit.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    (c)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <b>
                                                    <span lang="EN-GB">(Suggestions)</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    {" "}
                                                    In certain circumstances we may use your Personal Information to recommend additional features, products or services that we
                                                    believe will be of interest to you.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    (d)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
                                                </span>
                                                <b>
                                                    <span lang="EN-GB">(Compliance with Law)</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    {" "}
                                                    We may collect and use your Personal Information where we are required to do so by any applicable Law or Regulation, for example
                                                    where we retain your information to comply with any Know-Your-Customer (“KYC”) requirements of any Law or Regulation.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "70.85pt", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    (e)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
                                                </span>
                                                <b>
                                                    <span lang="EN-GB">(Breach of Law and Fraud Prevention)</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    {" "}
                                                    We may also use your Personal Information to detect, prevent and report fraud, crime or breaches of any Law or Regulation.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <b></b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "28.3pt", textIndent: "-28.3pt", lineHeight: "115%" }}>
                                                <b>
                                                    <span lang="EN-GB">
                                                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>3.
                                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        </span>
                                                    </span>
                                                </b>
                                                <b>
                                                    <span lang="EN-GB">Who do we share your information with?</span>
                                                </b>
                                            </p>
                                            <p
                                                className="MsoNormal"
                                                style={{
                                                    marginLeft: "63.75pt",
                                                    textIndent: "-63.75pt",
                                                    lineHeight: "115%",
                                                }}
                                            >
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    3.1.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                </span>
                                                <b>
                                                    <span lang="EN-GB">Third Party Transactions.</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    {" "}
                                                    We may share your information with our affiliates, third-party contractors and service providers (collectively, “Service
                                                    Providers”) where necessary to complete any transaction or provide any Service you have requested. We will not share any
                                                    personally identifiable information with third parties and will not share any information with unaffiliated third parties. These
                                                    service providers may not be located in Australia.
                                                </span>
                                            </p>
                                            <p
                                                className="MsoNormal"
                                                style={{
                                                    marginLeft: "63.75pt",
                                                    textIndent: "-63.75pt",
                                                    lineHeight: "115%",
                                                }}
                                            >
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    3.2.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                </span>
                                                <b>
                                                    <span lang="EN-GB">Business Sale.</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    {" "}
                                                    In circumstances where SKUdrop enters into negotiations regarding a Business Sale or enters into a contract for Business Sale,
                                                    we may transfer your Personal Information to the purchaser of the business as a component of the assets transferred to the
                                                    Purchaser.{" "}
                                                </span>
                                            </p>
                                            <p
                                                className="MsoNormal"
                                                style={{
                                                    marginLeft: "63.75pt",
                                                    textIndent: "-63.75pt",
                                                    lineHeight: "115%",
                                                }}
                                            >
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    3.3.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                </span>
                                                <b>
                                                    <span lang="EN-GB">Legal Compliance.</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    We reserve the right to release your account details and other Personal Information when we believe such release is necessary or
                                                    appropriate to comply with any applicable Law or Regulation or enforce these terms and other agreements.
                                                </span>
                                            </p>
                                            <p
                                                className="MsoNormal"
                                                style={{
                                                    marginLeft: "63.75pt",
                                                    textIndent: "-63.75pt",
                                                    lineHeight: "115%",
                                                }}
                                            >
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    3.4.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                </span>
                                                <b>
                                                    <span lang="EN-GB">Third Party Service Providers.</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    {" "}
                                                    Service Providers are given only the information they require in order to perform their functions and are not authorised to use
                                                    or disclose your Personal Information except to the extent necessary to perform the Services or comply with legal
                                                    requirements.{" "}
                                                </span>
                                            </p>
                                            <p
                                                className="MsoNormal"
                                                style={{
                                                    marginLeft: "63.75pt",
                                                    textIndent: "-63.75pt",
                                                    lineHeight: "115%",
                                                }}
                                            >
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    3.5.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                </span>
                                                <span lang="EN-GB">
                                                    Some of the following categories of Service Providers may receive your Personal Information depending on what Services you
                                                    engage us to provide:{" "}
                                                </span>
                                            </p>

                                            <table
                                                className="MsoNormalTable"
                                                style={{
                                                    marginLeft: "29.75pt",
                                                    borderCollapse: "collapse",
                                                    border: "none",
                                                }}
                                                width={570}
                                                cellSpacing={0}
                                                cellPadding={0}
                                                border={1}
                                            >
                                                <tbody>
                                                    <tr style={{ height: "21.0pt" }}>
                                                        <td
                                                            style={{
                                                                width: "225.0pt",
                                                                border: "solid black 1.0pt",
                                                                background: "#F3F3F3",
                                                                padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                                                height: "21.0pt",
                                                            }}
                                                            width={300}
                                                            valign="top"
                                                        >
                                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                                <span style={{ fontSize: "10.0pt" }} lang="EN-GB">
                                                                    Type of Service Provider
                                                                </span>
                                                            </p>
                                                        </td>
                                                        <td
                                                            style={{
                                                                width: "202.5pt",
                                                                border: "solid black 1.0pt",
                                                                borderLeft: "none",
                                                                background: "#F3F3F3",
                                                                padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                                                height: "21.0pt",
                                                            }}
                                                            width={270}
                                                            valign="top"
                                                        >
                                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                                <span style={{ fontSize: "10.0pt", color: "black" }} lang="EN-GB">
                                                                    Examples{" "}
                                                                </span>
                                                            </p>
                                                        </td>
                                                    </tr>
                                                    <tr style={{ height: "21.0pt" }}>
                                                        <td
                                                            style={{
                                                                width: "225.0pt",
                                                                border: "solid black 1.0pt",
                                                                borderTop: "none",
                                                                padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                                                height: "21.0pt",
                                                            }}
                                                            width={300}
                                                            valign="top"
                                                        >
                                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                                <span lang="EN-GB">Data analytics services</span>
                                                            </p>
                                                        </td>
                                                        <td
                                                            style={{
                                                                width: "202.5pt",
                                                                borderTop: "none",
                                                                borderLeft: "none",
                                                                borderBottom: "solid black 1.0pt",
                                                                borderRight: "solid black 1.0pt",
                                                                padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                                                height: "21.0pt",
                                                            }}
                                                            width={270}
                                                            valign="top"
                                                        >
                                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                                <span style={{ fontSize: "10.0pt" }} lang="EN-GB">
                                                                    N/A
                                                                </span>
                                                            </p>
                                                        </td>
                                                    </tr>
                                                    <tr style={{ height: "21.0pt" }}>
                                                        <td
                                                            style={{
                                                                width: "225.0pt",
                                                                border: "solid black 1.0pt",
                                                                borderTop: "none",
                                                                padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                                                height: "21.0pt",
                                                            }}
                                                            width={300}
                                                            valign="top"
                                                        >
                                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                                <span lang="EN-GB">Data storage services</span>
                                                            </p>
                                                        </td>
                                                        <td
                                                            style={{
                                                                width: "202.5pt",
                                                                borderTop: "none",
                                                                borderLeft: "none",
                                                                borderBottom: "solid black 1.0pt",
                                                                borderRight: "solid black 1.0pt",
                                                                padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                                                height: "21.0pt",
                                                            }}
                                                            width={270}
                                                            valign="top"
                                                        >
                                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                                <span style={{ fontSize: "10.0pt" }} lang="EN-GB">
                                                                    AWS
                                                                </span>
                                                            </p>
                                                        </td>
                                                    </tr>
                                                    <tr style={{ height: "21.0pt" }}>
                                                        <td
                                                            style={{
                                                                width: "225.0pt",
                                                                border: "solid black 1.0pt",
                                                                borderTop: "none",
                                                                padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                                                height: "21.0pt",
                                                            }}
                                                            width={300}
                                                            valign="top"
                                                        >
                                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                                <span lang="EN-GB">Social networks</span>
                                                            </p>
                                                        </td>
                                                        <td
                                                            style={{
                                                                width: "202.5pt",
                                                                borderTop: "none",
                                                                borderLeft: "none",
                                                                borderBottom: "solid black 1.0pt",
                                                                borderRight: "solid black 1.0pt",
                                                                padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                                                height: "21.0pt",
                                                            }}
                                                            width={270}
                                                            valign="top"
                                                        >
                                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                                <span style={{ fontSize: "10.0pt" }} lang="EN-GB">
                                                                    N/A
                                                                </span>
                                                            </p>
                                                        </td>
                                                    </tr>
                                                    <tr style={{ height: "21.0pt" }}>
                                                        <td
                                                            style={{
                                                                width: "225.0pt",
                                                                border: "solid black 1.0pt",
                                                                borderTop: "none",
                                                                padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                                                height: "21.0pt",
                                                            }}
                                                            width={300}
                                                            valign="top"
                                                        >
                                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                                <span lang="EN-GB">User authentication services</span>
                                                            </p>
                                                        </td>
                                                        <td
                                                            style={{
                                                                width: "202.5pt",
                                                                borderTop: "none",
                                                                borderLeft: "none",
                                                                borderBottom: "solid black 1.0pt",
                                                                borderRight: "solid black 1.0pt",
                                                                padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                                                height: "21.0pt",
                                                            }}
                                                            width={270}
                                                            valign="top"
                                                        >
                                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                                <span style={{ fontSize: "10.0pt" }} lang="EN-GB">
                                                                    Google Captcha
                                                                </span>
                                                            </p>
                                                        </td>
                                                    </tr>
                                                    <tr style={{ height: "21.0pt" }}>
                                                        <td
                                                            style={{
                                                                width: "225.0pt",
                                                                border: "solid black 1.0pt",
                                                                borderTop: "none",
                                                                padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                                                height: "21.0pt",
                                                            }}
                                                            width={300}
                                                            valign="top"
                                                        >
                                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                                <span lang="EN-GB">Website hosting service providers</span>
                                                            </p>
                                                        </td>
                                                        <td
                                                            style={{
                                                                width: "202.5pt",
                                                                borderTop: "none",
                                                                borderLeft: "none",
                                                                borderBottom: "solid black 1.0pt",
                                                                borderRight: "solid black 1.0pt",
                                                                padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                                                height: "21.0pt",
                                                            }}
                                                            width={270}
                                                            valign="top"
                                                        >
                                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                                <span style={{ fontSize: "10.0pt" }} lang="EN-GB">
                                                                    AWS
                                                                </span>
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <p className="MsoNormal" style={{ marginLeft: "28.3pt", textIndent: "-28.3pt", lineHeight: "115%" }}>
                                                <b>
                                                    <span lang="EN-GB">
                                                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>4.
                                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        </span>
                                                    </span>
                                                </b>
                                                <b>
                                                    <span lang="EN-GB">Privacy of Children</span>
                                                </b>
                                            </p>
                                            <p
                                                className="MsoNormal"
                                                style={{
                                                    marginLeft: "63.75pt",
                                                    textIndent: "-63.75pt",
                                                    lineHeight: "115%",
                                                }}
                                            >
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    4.1.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                </span>
                                                <span lang="EN-GB">
                                                    Persons under the age of 18 may not use any of the Services and must not submit any Personal Information .{" "}
                                                </span>
                                            </p>
                                            <p
                                                className="MsoNormal"
                                                style={{
                                                    marginLeft: "63.75pt",
                                                    textIndent: "-63.75pt",
                                                    lineHeight: "115%",
                                                }}
                                            >
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    4.2.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                </span>
                                                <span lang="EN-GB">
                                                    If you believe that a person under the age of 18 has provided Personal Information to us, please contact us immediately to
                                                    request that we delete that person’s Personal Information from our Services.{" "}
                                                </span>
                                            </p>
                                            <p
                                                className="MsoNormal"
                                                style={{
                                                    marginLeft: "63.75pt",
                                                    textIndent: "-63.75pt",
                                                    lineHeight: "115%",
                                                }}
                                            >
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    4.3.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                </span>
                                                <span lang="EN-GB">
                                                    We ask that all parents and guardians overseeing the care of children take all necessary precautions to ensure that their
                                                    children never give out Personal Information when online.
                                                </span>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: "28.3pt", textIndent: "-28.3pt", lineHeight: "115%" }}>
                                                <b>
                                                    <span lang="EN-GB">
                                                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>5.
                                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        </span>
                                                    </span>
                                                </b>
                                                <b>
                                                    <span lang="EN-GB">Managing your Information</span>
                                                </b>
                                            </p>
                                            <p
                                                className="MsoNormal"
                                                style={{
                                                    marginLeft: "63.75pt",
                                                    textIndent: "-63.75pt",
                                                    lineHeight: "115%",
                                                }}
                                            >
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    5.1.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                </span>
                                                <span lang="EN-GB">
                                                    You are able to delete certain Personal Information we have about you upon request. We may maintain a copy of the unrevised
                                                    Personal Information in our records for the duration necessary to comply with our obligations to our affiliates and partners,
                                                    and for the purposes described below.{" "}
                                                </span>
                                            </p>
                                            <p
                                                className="MsoNormal"
                                                style={{
                                                    marginLeft: "63.75pt",
                                                    textIndent: "-63.75pt",
                                                    lineHeight: "115%",
                                                }}
                                            >
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    5.2.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                </span>
                                                <span lang="EN-GB">
                                                    If you would like to delete your Personal Information or permanently delete your account, you can do so on the settings page of
                                                    your account on the Services.
                                                </span>
                                            </p>
                                            <p
                                                className="MsoNormal"
                                                style={{
                                                    marginLeft: "63.75pt",
                                                    textIndent: "-63.75pt",
                                                    lineHeight: "115%",
                                                }}
                                            >
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    5.3.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                </span>
                                                <span lang="EN-GB">
                                                    We will retain and use your Personal Information for the period necessary to comply with our legal obligations, as long as your
                                                    user account remains active, to enforce our agreements, resolve disputes, and unless a longer retention period is required or
                                                    permitted by law.
                                                </span>
                                            </p>
                                            <p
                                                className="MsoNormal"
                                                style={{
                                                    marginLeft: "63.75pt",
                                                    textIndent: "-63.75pt",
                                                    lineHeight: "115%",
                                                }}
                                            >
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    5.4.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                </span>
                                                <span lang="EN-GB">
                                                    We may also use any aggregated data derived from or incorporating your Personal Information after you update or delete it, so
                                                    long as it is stored in an anonymised form in a manner that would identify you personally.{" "}
                                                </span>
                                            </p>
                                            <p
                                                className="MsoNormal"
                                                style={{
                                                    marginLeft: "63.75pt",
                                                    textIndent: "-63.75pt",
                                                    lineHeight: "115%",
                                                }}
                                            >
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    5.5.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                </span>
                                                <span lang="EN-GB">
                                                    Our retention period is set by internal policies that may vary from time to time and once the retention period expires, Personal
                                                    Information shall be deleted automatically. The right to access, the right to erasure, the right to rectification, and the right
                                                    to data portability cannot be enforced after the expiration of the retention period.
                                                </span>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: "28.3pt", textIndent: "-28.3pt", lineHeight: "115%" }}>
                                                <b>
                                                    <span lang="EN-GB">
                                                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>6.
                                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        </span>
                                                    </span>
                                                </b>
                                                <b>
                                                    <span lang="EN-GB">Cookies and Other Tracking</span>
                                                </b>
                                            </p>
                                            <p
                                                className="MsoNormal"
                                                style={{
                                                    marginLeft: "63.75pt",
                                                    textIndent: "-63.75pt",
                                                    lineHeight: "115%",
                                                }}
                                            >
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    6.1.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                </span>
                                                <span lang="EN-GB">
                                                    Our Service may use cookies, tracking pixels and other tracking technology (“Cookies”) to recognise your device, personalise
                                                    your experience and provide you with some components of the Service.
                                                </span>
                                            </p>
                                            <p
                                                className="MsoNormal"
                                                style={{
                                                    marginLeft: "63.75pt",
                                                    textIndent: "-63.75pt",
                                                    lineHeight: "115%",
                                                }}
                                            >
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    6.2.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                </span>
                                                <span lang="EN-GB">
                                                    If you choose to decline Cookies or use a browser that restricts Cookies, you may not be able to fully experience the features
                                                    of the Services.{" "}
                                                </span>
                                            </p>
                                            <p
                                                className="MsoNormal"
                                                style={{
                                                    marginLeft: "63.75pt",
                                                    textIndent: "-63.75pt",
                                                    lineHeight: "115%",
                                                }}
                                            >
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    6.3.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                </span>
                                                <span lang="EN-GB">
                                                    In addition, our Services may use third-party analytics tools that use Cookies, web beacons, or other similar technologies to
                                                    collect internet activity and usage information. This information is used to produce statistical reports on User activity such
                                                    as how often Users visit our Services, what pages they visit and for how long. This information is used to monitor and improve
                                                    the Services.
                                                </span>
                                            </p>
                                            <p
                                                className="MsoNormal"
                                                style={{
                                                    marginLeft: "63.75pt",
                                                    textIndent: "-63.75pt",
                                                    lineHeight: "115%",
                                                }}
                                            >
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    6.4.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                </span>
                                                <span lang="EN-GB">
                                                    Certain components of our Service may incorporate social media features, such as Facebook/Twitter share buttons, etc
                                                    (collectively, “Social Media Features”). These Social Media Features may collect data about you and may use cookies to function
                                                    properly. Social Media Features may be hosted directly on our Services or by the respective provider. Your use of these Social
                                                    Media Features is governed by the policies of their respective providers.
                                                </span>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: "28.3pt", textIndent: "-28.3pt", lineHeight: "115%" }}>
                                                <b>
                                                    <span lang="EN-GB">
                                                        7.
                                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        </span>
                                                    </span>
                                                </b>
                                                <b>
                                                    <span lang="EN-GB">Email Communications</span>
                                                </b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    7.1.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    Where you consent to receive email communications from us we may send you electronic newsletters and promotions that may be of
                                                    interest to you (“Newsletters”).{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    7.2.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    We are committed to keeping your e-mail address confidential and will not disclose your email address to any third parties
                                                    except where such disclosure is allowed in the information use and processing section or as necessary to provide to a
                                                    third-party provider to send and manage such emails.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    7.3.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    To ensure compliance with the <i>Spam Act 2003</i>
                                                    (Cth) (the “Spam Act”)&nbsp; we will ask for your permission to send you Newsletters and you may unsubscribe at any time.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    7.4.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    If you choose to unsubscribe from the Newsletters we will still send you necessary informational and administrative emails
                                                    related to provision of the Service..
                                                </span>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: "28.3pt", textIndent: "-28.3pt", lineHeight: "115%" }}>
                                                <b>
                                                    <span lang="EN-GB">
                                                        8.
                                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        </span>
                                                    </span>
                                                </b>
                                                <b>
                                                    <span lang="EN-GB">Security of your Information</span>
                                                </b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    8.1.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <b>
                                                    <span lang="EN-GB">(External Links)</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    The Service may contain links to external resources that are not controlled by us. We are not responsible for the privacy
                                                    practices of such other external resources or third parties and we encourage you to read the Privacy Policies of any external
                                                    resource or third-party website before providing any Personal Information to them.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    8.2.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <b>
                                                    <span lang="EN-GB">(Security Practices)</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    {" "}
                                                    Personal Information you provide to us is stored in a secure environment and only accessible by authorised parties. We maintain
                                                    reasonable internal technical, procedural and physical safeguards to protect against unauthorised use, access, modification and
                                                    disclosure of your information.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    8.3.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <b>
                                                    <span lang="EN-GB">(Limitations)</span>
                                                </b>
                                                <span lang="EN-GB">While we do our best to protect your Personal Information, we advise and you acknowledge that: </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "92.1pt", textIndent: "-21.25pt", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    (a)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">certain factors that may affect your security and privacy online are beyond our control; </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "92.1pt", textIndent: "-21.25pt", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    (b)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    we cannot guarantee the security, integrity, and privacy of information/data exchanged between you and the Services;{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "92.1pt", textIndent: "-21.25pt", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    (c)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    a third party may tamper with your information/data whilst in transit despite any efforts on our part; and{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "92.1pt", textIndent: "-21.25pt", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    (d)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    as the security of Personal Information depends in part on the security of the device you use to communicate with us and the
                                                    security you use to protect your credentials, you represent and warrant that you take all reasonable measures to protect this
                                                    information.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    8.4.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <b>
                                                    <span lang="EN-GB">(Data Breach)</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    {" "}
                                                    If we become aware that Users’ Personal Information has been compromised or disclosed to unrelated third parties as a result of
                                                    hacking or fraud, you will be notified of the breach and we reserve the right to take all appropriate measures, including
                                                    investigation and notification to and cooperation with law enforcement authorities.{" "}
                                                </span>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: "28.3pt", textIndent: "-28.3pt", lineHeight: "115%" }}>
                                                <b>
                                                    <span lang="EN-GB">
                                                        9.
                                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        </span>
                                                    </span>
                                                </b>
                                                <b>
                                                    <span lang="EN-GB">GDPR Addendum</span>
                                                </b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    9.1.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    Where the European Union General Data Protection Regulation (Regulation (EU) 2016/679) (“GDPR”) applies to our provision of the
                                                    Service or your use or access of the Service, the provisions of this clause shall apply and prevail to the extent of any
                                                    inconsistency.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    9.2.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    Where the GDPR applies, the use, handling, disclosure, transfer, sharing or processing in any way and for any purpose, any
                                                    information that relates to an identified or identifiable individual shall constitute “Personal Data”.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    9.3.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    The Parties acknowledge and agree that all Personal Data disclosed by one Party and processed by the other Party as part of this
                                                    Agreement is Confidential Information of the disclosing Party and is subject to the confidentiality obligations set out in this
                                                    Agreement.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    9.4.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    Notwithstanding its obligations of confidentiality SKUdrop may copy, modify, distribute, and otherwise use Personal Data
                                                    received from or on behalf of Customer for purposes of providing the Services.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    9.5.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    You represent and warrant that you have or will obtain all permissions, consents, and authorisations that are required by
                                                    applicable law for Customer to provide, or to arrange for the provision of, Personal Data to SKUdrop.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    9.6.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    We act as a data controller and a data processor when handling Personal Data, unless we have entered into a data processing
                                                    agreement with you in which case you would be the data controller and we would be the data processor.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    9.7.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">Our role will differ depending on the specific situation involving Personal Information. For example: </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "92.1pt", textIndent: "-21.25pt", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    (a)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    we act in the capacity of a data controller when you submit Personal Information that is necessary to ensure your access and use
                                                    of the Services;&nbsp;{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "92.1pt", textIndent: "-21.25pt", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    (b)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    we act in the capacity of a data processor when you submit Personal Information through the Services. We do not own, control, or
                                                    make decisions about the submitted Personal Information, and such Personal Information is processed only in accordance with your
                                                    instructions. In such instances, the User providing Personal Information acts as a data controller.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    9.8.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    To the extent that SKUdrop “processes” (as that term is defined in the GDPR) Personal Data subject to the GDPR on behalf of
                                                    Customer, SKUdrop shall:{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "92.1pt", textIndent: "-21.25pt", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    (a)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    only process the Personal Data provided by Customer in accordance with its instructions, for no other purposes than those
                                                    determined by Customer and in compliance with the provisions of the Data Protection Laws and Regulations;
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "92.1pt", textIndent: "-21.25pt", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    (b)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    take reasonable steps to ensure that employees having access to the personal data processed as part of providing the Services
                                                    under this Agreement are properly trained and subject to confidentiality obligations;{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "92.1pt", textIndent: "-21.25pt", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    (c)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    take reasonable technical and organisational measures against the unauthorised or unlawful processing of that Personal Data and
                                                    against accidental loss or destruction of, or damage of Personal Data;
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "92.1pt", textIndent: "-21.25pt", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    (d)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    inform the Customer of any data subject request, third party notices, breach of security or loss of Customer’s Personal Data and
                                                    cooperate with Customer to ensure compliance with the applicable Data Protection Laws and Regulations; /provide reasonable
                                                    assistance to the Customer to allow it to comply with its obligations under the Data Protection Laws and Regulations, including
                                                    data subject requests; and
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "92.1pt", textIndent: "-21.25pt", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    (e)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    upon termination of the Agreement, cease all processing of Customer’s Personal Data and shall delete or, upon Customer’s
                                                    request, return, all files containing the Personal Data.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    9.9.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    SKUdrop agrees, upon reasonable notice by Customer, to submit to audits or have an independent third-party auditor, inspector,
                                                    regulator, and other representative, agreed in writing by the Customer and SKUdrop, to perform an audit on its behalf in order
                                                    to validate SKUdrop’s compliance with its obligations under this Agreement and the applicable Data Protection Laws and
                                                    Regulations. All such audits shall be at Customer’s sole cost and expense.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    9.10.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    In the event either of the Parties breaches and fails to comply with the terms and conditions on data protection provided in
                                                    this Agreement, the breaching Party shall be held liable for all damages and costs incurred by the other Party.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    9.11.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    The Customer shall indemnify and hold SKUdrop harmless from any liability, losses, claims, penalties, damages, costs and
                                                    expenses of whatever nature, imposed by the any regulatory body on SKUdrop and arising out of any claims, actions, proceedings
                                                    or settlements, resulting from the breach or non-compliance of Customer with the terms and conditions on data protection set
                                                    forth in this Agreement and/or with the applicable Data Protection Laws and Regulations.
                                                </span>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: "28.3pt", textIndent: "-28.3pt", lineHeight: "115%" }}>
                                                <b>
                                                    <span lang="EN-GB">
                                                        10.
                                                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    </span>
                                                </b>
                                                <b>
                                                    <span lang="EN-GB">General Provisions</span>
                                                </b>
                                            </p>
                                            <p
                                                className="MsoNormal"
                                                style={{
                                                    marginLeft: "63.75pt",
                                                    textIndent: "-63.75pt",
                                                    lineHeight: "115%",
                                                }}
                                            >
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    10.1.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                </span>
                                                <b>
                                                    <span lang="EN-GB">(Amendments to Policy)</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    {" "}
                                                    We reserve the right to modify this Policy or its terms related to the Services at any time at our sole discretion. When such
                                                    amendments are made we will change the version number and date at the bottom of this page and send you an email to notify
                                                    you.{" "}
                                                </span>
                                            </p>
                                            <p
                                                className="MsoNormal"
                                                style={{
                                                    marginLeft: "63.75pt",
                                                    textIndent: "-63.75pt",
                                                    lineHeight: "115%",
                                                }}
                                            >
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    10.2.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                </span>
                                                <b>
                                                    <span lang="EN-GB">(Effective Date of Amendments)</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    {" "}
                                                    Any updated versions of this Policy will be effective from the date that the revised policy is posted to this website. Your
                                                    continued use of the Services after the effective date of the revised Policy (or such other act specified at that time) will
                                                    constitute your consent to those amendments.
                                                </span>
                                            </p>
                                            <p
                                                className="MsoNormal"
                                                style={{
                                                    marginLeft: "63.75pt",
                                                    textIndent: "-63.75pt",
                                                    lineHeight: "115%",
                                                }}
                                            >
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    10.3.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                </span>
                                                <b>
                                                    <span lang="EN-GB">(Acceptance)</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    {" "}
                                                    You acknowledge that you have read this Policy and agree to all its terms and conditions. By accessing and using the Service and
                                                    submitting your information you agree to be bound by this Policy. If you do not agree to abide by the terms of this Policy, you
                                                    should immediately cease use of the Service.
                                                </span>
                                            </p>
                                            <p
                                                className="MsoNormal"
                                                style={{
                                                    marginLeft: "63.75pt",
                                                    textIndent: "-63.75pt",
                                                    lineHeight: "115%",
                                                }}
                                            >
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    10.4.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                </span>
                                                <b>
                                                    <span lang="EN-GB">(Contacting Us)</span>
                                                </b>
                                                <span lang="EN-GB">
                                                    If you have any questions, concerns, or complaints regarding this Policy, the information we hold about you, or if you wish to
                                                    exercise your rights, we encourage you to contact us using the details below:{" "}
                                                    <a href="mailto:privacy@skudrop.com.au">
                                                        <span style={{ color: "#1155CC" }}>privacy@SKUdrop.com.au</span>
                                                    </a>
                                                </span>
                                            </p>
                                            <p
                                                className="MsoNormal"
                                                style={{
                                                    marginLeft: "63.75pt",
                                                    textIndent: "-63.75pt",
                                                    lineHeight: "115%",
                                                }}
                                            >
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    10.5.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                </span>
                                                <span lang="EN-GB">
                                                    We will attempt to resolve complaints and disputes and make every reasonable effort to honour your wish to exercise your rights
                                                    as quickly as possible and in any event, within the timescales provided by applicable Data Protection Laws.
                                                </span>
                                            </p>
                                            <p
                                                className="MsoNormal"
                                                style={{
                                                    marginTop: "24.0pt",
                                                    marginRight: "0in",
                                                    marginBottom: "6.0pt",
                                                    marginLeft: "0in",
                                                    textIndent: "0in",
                                                    pageBreakAfter: "avoid",
                                                }}
                                            >
                                                <b>
                                                    <span style={{ fontSize: "22.0pt" }} lang="EN-GB">
                                                        DISPUTES POLICY
                                                    </span>
                                                </b>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <span lang="EN-GB">
                                                    This Disputes (“Disputes Policy”) is incorporated into the SKUdrop General Terms as published on the SKUdrop Website (“Terms”).
                                                    Where there is any conflict or material inconsistency between this Dispute Policy and the Terms, the Terms shall prevail to the
                                                    extent of any inconsistency.{" "}
                                                </span>
                                            </p>

                                            <p
                                                className="MsoNormal"
                                                style={{
                                                    marginLeft: "21.25pt",
                                                    textIndent: "-21.25pt",
                                                    lineHeight: "115%",
                                                }}
                                            >
                                                <b>
                                                    <span style={{ fontFamily: '"Arial",sans-serif' }} lang="EN-GB">
                                                        1.
                                                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    </span>
                                                </b>
                                                <b>
                                                    <span lang="EN-GB">Solving Problems with your Account</span>
                                                </b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    1.1.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    SKUdrop is focused on customer service and addressing any complaints in an efficient and proactive manner. To that end, if you
                                                    have a problem with your account or the SKUdrop services, please log a support case for resolution.&nbsp; You can may initiate a
                                                    support case by:{" "}
                                                </span>
                                            </p>
                                            <p
                                                className="MsoNormal"
                                                style={{
                                                    marginLeft: "77.95pt",
                                                    textIndent: "-21.25pt",
                                                    lineHeight: "115%",
                                                }}
                                            >
                                                <span lang="EN-GB">
                                                    (a)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    sending an email to customer care at:&nbsp; <a href="mailto:support@SKUdrop.com">support@SKUdrop.com</a>; or{" "}
                                                </span>
                                            </p>
                                            <p
                                                className="MsoNormal"
                                                style={{
                                                    marginLeft: "77.95pt",
                                                    textIndent: "-21.25pt",
                                                    lineHeight: "115%",
                                                }}
                                            >
                                                <span lang="EN-GB">
                                                    (b)
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">logging into your account and using the “Contact Support” option.</span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "56.65pt", textIndent: "0in" }}>
                                                <span lang="EN-GB">For purposes of clarity, this does not waive the notice requirement set forth below.</span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    1.2.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    SKUdrop will respond to all customer complaints submitted to it within a reasonable time and in no case more than 60 days after
                                                    the initial complaint.{" "}
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    1.3.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    If we are unable to address your complaint within a reasonable timeframe or the complaint is not addressed to your satisfaction
                                                    you should issue a Notice of Dispute in accordance with this Disputes Policy.
                                                </span>
                                            </p>

                                            <p
                                                className="MsoNormal"
                                                style={{
                                                    marginLeft: "21.25pt",
                                                    textIndent: "-21.25pt",
                                                    lineHeight: "115%",
                                                }}
                                            >
                                                <b>
                                                    <span style={{ fontFamily: '"Arial",sans-serif' }} lang="EN-GB">
                                                        2.
                                                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    </span>
                                                </b>
                                                <b>
                                                    <span lang="EN-GB">Notice of Dispute</span>
                                                </b>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    2.1.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    Where we have been unable to solve your complaint or have not responded to your complaint within 60 days you may initiate a
                                                    formal dispute resolution procedure in accordance with this Policy by sending an email to customer care at:&nbsp;{" "}
                                                    <a href="mailto:disputes@SKUDrop.com">
                                                        <span style={{ color: "#1155CC" }}>disputes@SKUdrop.com</span>
                                                    </a>
                                                    <u>
                                                        <span style={{ color: "#1155CC" }}>.</span>
                                                    </u>
                                                </span>
                                            </p>
                                            <p
                                                className="MsoNormal"
                                                style={{
                                                    marginLeft: "21.25pt",
                                                    textIndent: "-21.25pt",
                                                    lineHeight: "115%",
                                                }}
                                            >
                                                <b>
                                                    <span style={{ fontFamily: '"Arial",sans-serif' }} lang="EN-GB">
                                                        3.
                                                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    </span>
                                                </b>
                                                <b>
                                                    <span lang="EN-GB">Mediation and Arbitration of Disputes.</span>
                                                </b>
                                                <span lang="EN-GB"> </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    3.1.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    Except where a dispute relates to payments due to SKUdrop for the Services, any dispute, controversy or claim arising out of,
                                                    relating to or in connection with this contract, including any question regarding its existence, validity or termination, shall
                                                    be resolved by mediation in accordance with the ACICA Mediation Rules.&nbsp; The mediation shall take place in Sydney, Australia
                                                    and be administered by the Australian Centre for International Commercial Arbitration (ACICA).
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    3.2.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    If the dispute has not been settled pursuant to the said Rules within 60 days following the written invitation to mediate or
                                                    within such other period as the parties may agree in writing, the dispute shall be resolved by arbitration in accordance with
                                                    the ACICA Arbitration Rules. The seat of arbitration shall be Sydney, Australia. The language of the arbitration shall be
                                                    English.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{ marginLeft: "49.6pt", textIndent: "-49.6pt", lineHeight: "115%" }}>
                                                <span lang="EN-GB">
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    3.3.
                                                    <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </span>
                                                <span lang="EN-GB">
                                                    This clause shall not preclude parties from seeking provisional remedies in aid of arbitration from a court of appropriate
                                                    jurisdiction. The arbitrator may, in the Award, allocate all or part of the costs of the arbitration, including the fees of the
                                                    arbitrator and the reasonable attorneys’ fees of the prevailing party.
                                                </span>
                                            </p>

                                            <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                                <i>
                                                    <span style={{ fontSize: "14.0pt" }} lang="EN-GB">
                                                        This document was last updated on 05 Sep 2022
                                                    </span>
                                                </i>
                                            </p>
                                        </div>
                                    </div>
                                </Container>
                            </Modal.Body>
                        </Modal>
                    </div>
                </Form.Group>
                {process.env.REACT_APP_CAPTCHA_ENABLED === "true" ? (
                    <Form.Group className="d-flex justify-content-center mb-4" controlId="formBasicEmail">
                        <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""} onChange={onChange} />
                    </Form.Group>
                ) : null}
                <Button variant="primary" disabled={selectTerms && captcha ? false : true} type="submit" data-testid="btn_CREATE ACCOUNT">
                    CREATE ACCOUNT
                </Button>
                <Row className="mb-0 mt-4">
                    <Col>
                        <span className="d-flex justify-content-center">
                            Already have an account?{" "}
                            <span className="have-account" onClick={onHaveAccount}>
                                Log In
                            </span>
                        </span>
                    </Col>
                </Row>
            </Form>
        </Container>
    );
};

export default SignUpFirstStep;
