import { displayError } from "../hooks/useErrorHandler";
import { outboundShipmentsAPI } from "../api/tenantDashboardAPI";
import { TOutboundWarehouse } from "./types/WarehouseTypes/warehouse.types";
import {
    TAvailableOutboundOptions,
    TOutboundShipmentsLoadingStates,
    TOutboundShipmentsReducesActions,
    TOutboundShipmentsThunk,
    TSetAvailableOutboundOptions,
    TSetOutboundShipmentsLoadingStates,
    TSetOutboundWarehouses,
} from "./types/outboundShipments.types";

export const SET_OUTBOUND_WAREHOUSES = "SET_OUTBOUND_WAREHOUSES";
export const SET_LOADING_STATES = "SET_LOADING_STATES";
export const SET_AVAILABLE_OUTBOUND_OPTIONS = "SET_AVAILABLE_OUTBOUND_OPTIONS";

export type TOutboundShipmentsInitialState = {
    outboundWarehouses: TOutboundWarehouse[] | null;
    availableOutboundOptions: TAvailableOutboundOptions | null;
    loadingStates: TOutboundShipmentsLoadingStates;
};

const initialState: TOutboundShipmentsInitialState = {
    outboundWarehouses: null,
    availableOutboundOptions: null,
    loadingStates: {
        isLoadingOutboundWarehouses: false,
        isLoadingAvailableOutboundOptions: false,
    },
};

const outboundShipmentsReducer = (state = initialState, action: TOutboundShipmentsReducesActions): TOutboundShipmentsInitialState => {
    switch (action.type) {
        case SET_OUTBOUND_WAREHOUSES:
            return {
                ...state,
                outboundWarehouses: action.data,
            };
        case SET_AVAILABLE_OUTBOUND_OPTIONS:
            return {
                ...state,
                availableOutboundOptions: action.data,
            };
        case SET_LOADING_STATES:
            return {
                ...state,
                loadingStates: {
                    ...state.loadingStates,
                    ...action.data,
                },
            };
        default:
            return state;
    }
};

export const SetOutboundWarehouses = (data: TOutboundWarehouse[]): TSetOutboundWarehouses => ({
    type: SET_OUTBOUND_WAREHOUSES,
    data,
});

export const SetAvailableOutboundOptions = (data: TAvailableOutboundOptions): TSetAvailableOutboundOptions => ({
    type: SET_AVAILABLE_OUTBOUND_OPTIONS,
    data,
});

export const SetLoadingStates = (data: { [key in keyof TOutboundShipmentsLoadingStates]?: boolean }): TSetOutboundShipmentsLoadingStates => ({
    type: SET_LOADING_STATES,
    data,
});

export const getOutboundWarehouses = (country_code: string): TOutboundShipmentsThunk => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            dispatch(SetLoadingStates({ isLoadingOutboundWarehouses: true }));

            let response = await outboundShipmentsAPI.getOutboundWarehouses(cookies, country_code);

            dispatch(SetOutboundWarehouses(response.data));

            dispatch(SetLoadingStates({ isLoadingOutboundWarehouses: false }));
        } catch (e) {
            displayError(e);
        }
    };
};

export const getAvailableOutboundOptions = (queryParams?: Partial<{ warehouse_id: string }>): TOutboundShipmentsThunk => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            dispatch(SetLoadingStates({ isLoadingAvailableOutboundOptions: true }));

            let response = await outboundShipmentsAPI.getOutboundShipmentsAvailableOptions(cookies, queryParams);

            dispatch(SetAvailableOutboundOptions(response.data));

            dispatch(SetLoadingStates({ isLoadingAvailableOutboundOptions: false }));
        } catch (e) {
            displayError(e);
        }
    };
};

export default outboundShipmentsReducer;
