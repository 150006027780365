import Cookies from "universal-cookie";

import { instance } from "./authAPI";
import { processRequestQueryParams } from "./utils/apiUtils";
import { TGenericResponseFormat, TRequestQueryParams, TSKUdropVendorMarkets } from "../redux/types/common/common.types";

import {
    TPostPrepOrders,
    TOwnFFOrderToPrep,
    TGetOrdersToPrepGeneral,
    PrepOrdersResult,
    TGetOrdersToShipGeneral,
    GetOrderToShipResult,
    TOwnFFOrderToShip,
    TPatchBulkPickedUpOrdersToShipReqData,
    TPatchBulkLoadedOrdersToShipReqData,
    TWarehouseMarkets,
    TPostOrdersToShipVerificationFile,
    TPostBulkCompletedOrdersToPrep,
    TQuoteOrderToPrep,
    TPostCompletedQuoteOrderToPrep,
    TPatchQuoteOrders,
    TSKUdropOrderToTrack,
    SetQuoteTrackingIdListType,
    TPatchUpdateQuoteTrackingIdsReqData,
    TQuoteOrderToShip,
    TGetQuoteOrdersToShipGeneral,
    TPatchQuoteLoadedOrdersToShipReqData,
    TTariffManagementResponse,
    TQuoteTariffOrder,
    TQuoteOrder,
    TGeQuoteOrdersGeneral,
    TQuoteOrderToPrepDetails,
    TQuoteOrderToShipDetails,
    TQuoteOrderToTrackDetails,
    TQuoteOrderDetails,
    TQuoteTariffOrderDetails,
    GetItemsForUkContainerRootObject,
    PatchUpdateDeliveryWindowReqData,
    TPatchSetOrderTrackingIDsReqData,
    TManagePermissionReqData,
    TManagePermissionData,
    TOwnFFOrdersDetails,
    TSKUdropOrderDetails,
    TIncomingOrdersDetails,
    TAllOwnFFOutgoingOrdersData,
    TAllQuoteOutgoingOrderDetails,
    PatchUpdateVendorProductCartonReqData,
    TOwnFFOrderToPrepDetails,
    TOwnFFOrderToShipDetails,
    TSKUdropOrderToPrepDetails,
    TAssignRoleRequestData,
    TEditRoleRequestData,
} from "./../redux/types/WarehouseTypes/warehouse.types";
import {
    AdminTotalsResponse,
    CartonsStored,
    ConfirmAuthorizedUserResponse,
    CreateCustomerCommunicationType,
    GetAllContainersRootObject,
    GetAllIncomingRootObject,
    GetAllOutgoingRootObject,
    GetIncomingShipmentsRootObject,
    GetItemsForContainerRootObject,
    GetTariffListRootObject,
    GetTenantsListResponseType,
    GetTrackingIdListRootObject,
    GetUserListRootObject,
    GetUserRoleListRootObject,
    GetWarehouseListRootObject,
    PatchProductListRequestType,
    SetShippedDateType,
    TPostCompletedOrderToPrep,
    TPostCompletedOwnFFOrderToShip,
} from "../redux/types/WarehouseTypes/warehouse.types";

export const incomingShipments = {
    getIncomingShipments(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<GetIncomingShipmentsRootObject>(`/shipments/admin/incoming-shipments/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getAllIncomingOrders(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<GetAllIncomingRootObject>(`/shipments/admin/all_incoming/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    deleteIncomingShipments(cookies: Cookies, id: string, queryParams: TRequestQueryParams) {
        return instance.delete(`/shipments/incoming-shipments/${id}/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    checkIncomingShipment(token: string, id: string) {
        return instance.get(`/shipments/check/incoming-shipment/${id}/`, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    postDownloadIncomingTotals(
        cookies: Cookies,
        queryParams: Partial<{
            search: string;
            country_code: TSKUdropVendorMarkets;
            date_from: string;
            date_to: string;
        }>
    ) {
        return instance.post<unknown, { data: { url: string } }>(`/shipments/admin/incoming_total_file/${processRequestQueryParams(queryParams)}`, undefined, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getIncomingRequestDetails(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TIncomingOrdersDetails>(`/shipments/admin/all_incoming-details/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};

export const orderToShip = {
    getOrdersToShip(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TGetOrdersToShipGeneral<GetOrderToShipResult>>(`/shipments/admin/order-to-ship/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getOrdersToShipAll(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TGetOrdersToShipGeneral<GetOrderToShipResult>>(`/shipments/admin/order-to-ship/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getAllOutgoingOrders(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<GetAllOutgoingRootObject>(`/shipments/admin/all_outgoing/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    patchOrderToShip(id: string, shipment_id: string, token: string, queryParams: TRequestQueryParams) {
        return instance.patch(
            `/amazon_mws/inbound-shipment-carrier-status/${id}/${processRequestQueryParams(queryParams)}`,
            { shipment_id: shipment_id },
            {
                headers: {
                    Authorization: "Bearer " + token,
                },
            }
        );
    },
    downloadSkudropOutgoingTotalFile(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.post<{ url: string }>(`/shipments/admin/outgoing_total_file/${processRequestQueryParams(queryParams)}`, null, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    downloadOwnFFOutgoingTotalFile(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.post<{ url: string }>(`shipments/admin/off_outgoing_total_file/${processRequestQueryParams(queryParams)}`, null, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    downloadQuoteOutgoingTotalFile(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.post<{ url: string }>(`manual_quoting/admin/all_quote_orders_total_file/${processRequestQueryParams(queryParams)}`, null, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getOwnFFOrdersToShip(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TGetOrdersToShipGeneral<TOwnFFOrderToShip>>(`/shipments/admin/off-order-to-ship/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getOwnFFOrdersToShipAll(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TGetOrdersToShipGeneral<TOwnFFOrderToShip>>(`/shipments/admin/off-order-to-ship/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    patchProofsOwnFFOrderToShip(cookies: Cookies, orderId: string, data: FormData, queryParams: TRequestQueryParams) {
        return instance.patch(`/shipments/admin/off-order-to-ship/${orderId}/${processRequestQueryParams(queryParams)}`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postCompletedOwnFFOrderToShip(cookies: Cookies, data: TPostCompletedOwnFFOrderToShip, queryParams: TRequestQueryParams) {
        return instance.post(`/shipments/admin/off-order-to-ship/${processRequestQueryParams(queryParams)}`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getAllOwnFFOutgoingOrders(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TAllOwnFFOutgoingOrdersData>(`/shipments/admin/all_off_outgoing/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getAllQuoteOutgoingOrders(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<GetAllOutgoingRootObject>(`/manual_quoting/admin/all_quote_orders/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    // Bulk Management
    patchBulkPickedUpOrdersToShip(cookies: Cookies, data: TPatchBulkPickedUpOrdersToShipReqData, queryParams: TRequestQueryParams) {
        return instance.patch(`/amazon_mws/inbound-shipment-carrier-status/bulk-update/${processRequestQueryParams(queryParams)}`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    patchBulkLoadedOrdersToShip(cookies: Cookies, data: TPatchBulkLoadedOrdersToShipReqData, queryParams: TRequestQueryParams) {
        return instance.patch(`/amazon_mws/inbound-shipment/bulk-update/${processRequestQueryParams(queryParams)}`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getOrdersToShipBulkManagement(cookies: Cookies, mode: "picked_up" | "loaded" | "verification_file", queryParams: TRequestQueryParams) {
        return instance.get<TGenericResponseFormat<GetOrderToShipResult>>(
            `/shipments/admin/${mode === "picked_up" || mode === "verification_file" ? "order-ready-to-pick-up" : "order-ready-to-load"}/${processRequestQueryParams(queryParams)}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    // Verification file
    postOrdersToShipVerificationFile(cookies: Cookies, data: TPostOrdersToShipVerificationFile) {
        return instance.post<{ url: string }>("/shipments/admin/generate_verification_document/", data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    // Quote Orders
    getOrdersToShipQuoting(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TGetQuoteOrdersToShipGeneral<TQuoteOrderToShip>>(`/manual_quoting/quote-order-to-ship/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    patchQuotePickedUpOrdersToShip(cookies: Cookies, id: string, queryParams: TRequestQueryParams) {
        return instance.patch(
            `/manual_quoting/quote-order-to-ship/${id}/${processRequestQueryParams(queryParams)}`,
            { id },
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    patchQuoteLoadedOrdersToShip(cookies: Cookies, data: TPatchQuoteLoadedOrdersToShipReqData, queryParams: TRequestQueryParams) {
        return instance.patch(`/manual_quoting/load-order-to-ship/${data.id}/${processRequestQueryParams(queryParams)}`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    // Manual Quoting: Order Details
    getQuoteShipOrderDetails(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TQuoteOrderToShipDetails>(`/manual_quoting/quote-order-to-ship-details/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getAllQuoteOutgoingOrderDetails(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TAllQuoteOutgoingOrderDetails>(`/manual_quoting/admin/all_quote_orders-details/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getOwnFFOrdersToShipDetails(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TGetOrdersToShipGeneral<TOwnFFOrderToShipDetails>>(
            `/shipments/admin/off-order-to-ship/${processRequestQueryParams({ ...queryParams, details: true })}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
};

export const productList = {
    getProductList(cookies: Cookies, storedMarket: string, queryParams: TRequestQueryParams) {
        return instance.get<CartonsStored>(
            `/shipments/admin/product-stored/${processRequestQueryParams(queryParams)}${storedMarket && storedMarket !== "ALL" ? `&integration__country_code=${storedMarket}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    getProductListAll(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<CartonsStored>(`/shipments/admin/product-stored/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    patchProductList(cookies: Cookies, data: PatchProductListRequestType | PatchUpdateVendorProductCartonReqData, carton_id: string, queryParams: TRequestQueryParams) {
        return instance.patch(`/shipments/admin/product-stored/${carton_id}/${processRequestQueryParams(queryParams)}`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};

export const AdminTotals = {
    getAdminTotals(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<AdminTotalsResponse>(`/shipments/admin/totals/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    downloadProductListReport(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<unknown, { data: { url: string } }>(`/shipments/admin/storage_tracking_file/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};

export const trackingId = {
    getTrackingIdList(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<GetTrackingIdListRootObject<TSKUdropOrderToTrack>>(`/shipments/admin/order-tracking/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getTrackingIdListAll(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<GetTrackingIdListRootObject<TSKUdropOrderToTrack>>(`/shipments/admin/order-tracking/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    patchSetOrderTrackingIDs(cookies: Cookies, data: TPatchSetOrderTrackingIDsReqData) {
        return instance.patch(`/amazon_mws/inbound-shipment/transport/${data.id}/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getDownloadBulkTrackingIDs(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<string>(`/amazon_mws/bulk-transport-update/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postUploadBulkTrackingIDsFile(cookies: Cookies, data: FormData, queryParams: TRequestQueryParams) {
        return instance.post(`/amazon_mws/bulk-transport-update/${processRequestQueryParams(queryParams)}`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    setShippedDate(cookies: Cookies, id: string, data: SetShippedDateType, queryParams: TRequestQueryParams) {
        return instance.patch(`/shipments/outgoing_shipment/update_departure_date/${id}/${processRequestQueryParams(queryParams)}`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    patchUpdateDeliveryWindow(cookies: Cookies, activeWindowID: string, reqData: PatchUpdateDeliveryWindowReqData) {
        return instance.patch(`/amazon_mws/delivery-window/${activeWindowID}/`, reqData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    // Quote Orders
    getTrackingIdQuoting(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get(`/manual_quoting/quote-order-tracking/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getTrackingIdQuotingAll(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get(`/manual_quoting/quote-order-tracking/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    setQuoteShippedDate(cookies: Cookies, id: string, data: SetShippedDateType, queryParams: TRequestQueryParams) {
        return instance.patch(`/manual_quoting/update_quote_departure_date/${id}/${processRequestQueryParams(queryParams)}`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    setQuoteTrackingIdList(cookies: Cookies, data: SetQuoteTrackingIdListType, queryParams: TRequestQueryParams) {
        return instance.patch(`/manual_quoting/set_tracking_id/${data.id}/${processRequestQueryParams(queryParams)}`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    updateQuoteTrackingIdLis(cookies: Cookies, data: TPatchUpdateQuoteTrackingIdsReqData, queryParams: TRequestQueryParams) {
        return instance.patch(`/manual_quoting/update_tracking_id/${data.id}/${processRequestQueryParams(queryParams)}`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    // Manual Quoting: Order Details
    getQuoteTrackingIdDetails(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TQuoteOrderToTrackDetails>(`/manual_quoting/quote-order-details-tracking/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    // Tracking File Upload
    patchTrackingFilesUpload(cookies: Cookies, formData: FormData, id: string) {
        return instance.patch(`/tracking/upload_order_logistics_documents/${id}/`, formData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};

export const orderToPrep = {
    getOrdersToPrep(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TGetOrdersToPrepGeneral<PrepOrdersResult>>(`/shipments/admin/order-to-prep/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getOrdersToPrepAll(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TGetOrdersToPrepGeneral<PrepOrdersResult>>(`/shipments/admin/order-to-prep/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postOrderToPrep(cookies: Cookies, reqData: TPostPrepOrders, queryParams: TRequestQueryParams) {
        return instance.post(`/shipments/admin/order-to-prep/${processRequestQueryParams(queryParams)}`, reqData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    checkOrderLabels(cookies: Cookies, id: string) {
        return instance.patch(
            `/shipments/restart-job-get-labels/${id}/`,
            {},
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    getOwnFFOrdersToPrep(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TGetOrdersToPrepGeneral<TOwnFFOrderToPrep>>(`/shipments/admin/off-order-to-prep/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getOwnFFOrdersToPrepAll(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TGetOrdersToPrepGeneral<TOwnFFOrderToPrep>>(`/shipments/admin/off-order-to-prep/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    patchPickUpOwnFFOrderToPrep(cookies: Cookies, id: string, data: { pick_up_time: string }, queryParams: TRequestQueryParams) {
        return instance.patch<TOwnFFOrderToPrep>(`/shipments/admin/off-order-to-prep/${id}/${processRequestQueryParams(queryParams)}`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postCompletedOwnFFOrderToPrep(cookies: Cookies, data: TPostCompletedOrderToPrep, queryParams: TRequestQueryParams) {
        return instance.post(`/shipments/admin/off-order-to-prep/${processRequestQueryParams(queryParams)}`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    // Bulk management
    postBulkCompletedOrdersToPrep(cookies: Cookies, data: TPostBulkCompletedOrdersToPrep, queryParams: TRequestQueryParams) {
        return instance.post(`/shipments/admin/order-to-prep/bulk-create/${processRequestQueryParams(queryParams)}`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    // Quote Orders
    getOrdersToPrepQuoting(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TGetOrdersToPrepGeneral<TQuoteOrderToPrep>>(`/manual_quoting/quote-order-to-prep/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getOrdersToPrepQuotingAll(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TGetOrdersToPrepGeneral<TQuoteOrderToPrep>>(`/manual_quoting/quote-order-to-prep/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postCompletedQuoteOrderToPrep(cookies: Cookies, data: TPostCompletedQuoteOrderToPrep, queryParams: TRequestQueryParams) {
        return instance.post(`/manual_quoting/quote-order-to-prep/${processRequestQueryParams(queryParams)}`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    // Manual Quoting: Order Details
    getQuotePrepOrderDetails(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TQuoteOrderToPrepDetails>(`/manual_quoting/quote-order-to-prep-details/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getOrdersToPrepDetails(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TGetOrdersToPrepGeneral<TSKUdropOrderToPrepDetails>>(`/shipments/admin/order-to-prep/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getOwnFFOrdersToPrepDetails(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TGetOrdersToPrepGeneral<TOwnFFOrderToPrepDetails>>(
            `/shipments/admin/off-order-to-prep/${processRequestQueryParams({ ...queryParams, details: true })}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    getSKUdropRequestDetails(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TSKUdropOrderDetails>(`/shipments/admin/all_outgoing-details/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};

export const tariffList = {
    getTariffList(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<GetTariffListRootObject>(`/shipments/admin/tariff-management/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getTariffListAll(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<GetTariffListRootObject>(`/shipments/admin/tariff-management/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getAllContainers(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<GetAllContainersRootObject>(`/shipments/admin/tariff-container/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getItemsForContainer(cookies: Cookies, containerId: string, queryParams: TRequestQueryParams) {
        return instance.get<GetItemsForContainerRootObject>(`/shipments/admin/tariff-container/${containerId}/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getItemsForUkContainer(cookies: Cookies, containerId: string, queryParams: TRequestQueryParams) {
        return instance.get<GetItemsForUkContainerRootObject>(`/shipments/admin/tariff-container-uk/${containerId}/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getAllItemsForContainer(cookies: Cookies, containerId: string, queryParams: TRequestQueryParams) {
        return instance.get<GetItemsForContainerRootObject>(`/shipments/admin/tariff-container/${containerId}/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getTariffManagementQuoteOrders(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TTariffManagementResponse<TQuoteTariffOrder>>(`/manual_quoting/tariff-management/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postSetQuoteOrderTotalTariff(cookies: Cookies, reqData: { order_id: string; total_price: number }, queryParams: TRequestQueryParams) {
        return instance.post(`/manual_quoting/tariff-total/${processRequestQueryParams(queryParams)}`, reqData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postChargeQuoteOrderTariff(cookies: Cookies, reqData: { outgoing_shipment_ids: string[] }, queryParams: TRequestQueryParams) {
        return instance.post(`/manual_quoting/tariff-management/${processRequestQueryParams(queryParams)}`, reqData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    // Manual Quoting: Order Details
    getQuoteTariffOrderDetails(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TQuoteTariffOrderDetails>(`/manual_quoting/tariff-details-management/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};

export const tenantList = {
    getUserList(cookies: Cookies) {
        return instance.get<GetUserListRootObject>(`/tenants/user-tenants-list/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getUserListAll(cookies: Cookies, items: number, offset: number, search: string, sortBy?: string) {
        return instance.get<GetUserListRootObject>(
            `/tenants/user-tenants-list/?limit=${items}&offset=${offset}${search !== "" ? `&search=${window.encodeURIComponent(search)}` : ""}${sortBy ? `&ordering=${sortBy}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    getWarehouseList(cookies: Cookies, queryParams?: TRequestQueryParams) {
        return instance.get<GetWarehouseListRootObject>(`/warehouses/warehouse-storage/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getWarehouseListAll(cookies: Cookies, items: number, offset: number, search: string) {
        return instance.get<GetWarehouseListRootObject>(
            `/warehouses/warehouse-storage/?limit=${items}&offset=${offset}${search !== "" ? `&search=${window.encodeURIComponent(search)}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    getPermissions(cookies: Cookies, userId: string) {
        return instance.get<TManagePermissionData>(`/tenants/admin-manage-permission/${userId}/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    patchUpdatePermissions(cookies: Cookies, data: TManagePermissionReqData, id: string) {
        return instance.patch<TManagePermissionReqData>(`/tenants/admin-manage-permission/${id}/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    deleteTenantRole(cookies: Cookies, email: string) {
        return instance.delete(`/tenants/users-manipulator/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
            data: {
                email: email,
            },
        });
    },
    deleteUserFromTennant(cookies: Cookies, id: string) {
        return instance.delete(`/tenants/tenants-manipulator/${id}/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};

export const roleList = {
    getUserRoleList(cookies: Cookies) {
        return instance.get<GetUserRoleListRootObject>(`/users/set-role/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getUserRoleListAll(cookies: Cookies, items: number, offset: number, search: string) {
        return instance.get<GetUserRoleListRootObject>(`/users/set-role/?limit=${items}&offset=${offset}${search ? `&search=${window.encodeURIComponent(search)}` : ""}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    updateUserRole(cookies: Cookies, id: string, data: TEditRoleRequestData) {
        return instance.patch(`users/set-role/${id}/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    addUserRole(cookies: Cookies, data: TAssignRoleRequestData) {
        return instance.post(`/users/invite/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};

export const userCommunicationAPI = {
    getTenantsList(token: string) {
        return instance.get<unknown, GetTenantsListResponseType>(`/notifications/tenant_list/`, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    createCustomerCommunication(data: CreateCustomerCommunicationType, token: string) {
        return instance.post(`/notifications/comunications/`, data, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
};

export const confirmAuthorizedUser = {
    ConfirmAuthorizedUserToTenant(id: string, token: string) {
        return instance.get<ConfirmAuthorizedUserResponse>(`/users/activate/${id}/${token}/`);
    },
};

export const quoteOrders = {
    getQuoteOrders(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TGeQuoteOrdersGeneral<TQuoteOrder>>(`/manual_quoting/quote-orders-requests/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    patchConfirmQuoteOrderPrice(cookies: Cookies, data: TPatchQuoteOrders, id: string, queryParams: TRequestQueryParams) {
        return instance.patch(`/manual_quoting/quote-orders-requests/${id}/${processRequestQueryParams(queryParams)}`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getQuoteRequestDetails(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TQuoteOrderDetails>(`/manual_quoting/quote-orders-requests-details/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getOwnFFRequestDetails(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TOwnFFOrdersDetails>(`/shipments/admin/all_off_outgoing-details/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};
