import axios from "axios";
import Cookies from "universal-cookie";

import {
    AmazonProduct,
    CreateShipmentPlanRequestType,
    SendToAmazonActionTypes,
    SendToAmazonThunkType,
    SetErrorAmazonProductType,
    SetLoadingMessageType,
    SetReadyToAmazonProductType,
    SetShipmentPlanType,
    TSetLoadingShipmentPlans,
    TSetOwnFFAmazonProducts,
    TStateSendToAmazonProducts,
    TSetFFDetails,
    TFFDetailsResults,
    TStateOwnFFSendToAmazonProducts,
    TPostCreateFlatFileData,
    TSetOwnFFPlans,
    TStateOwnFFPlans,
    TOwnFFPlanItem,
    TStateOwnFFAwdPlans,
    TSetOwnFFAwdPlans,
    TOwnFFAwdPlanItem,
    TSendToAmazonLoadingStates,
    TSetLoadingStates,
    SetMarketPlacesType,
    TOwnFFToAmazonMarketplaces,
    TAmazonPlanSplits,
    TConfirmShippingPlanReqData,
    TOutboundShipmentsIntegrationsState,
    TSetOutboundIntegrations,
    TSendToAmazonIntegration,
    TSKUdropAutomatedBulkProduct,
    SetSkudropAutomatedBulkUploadType,
    TSetOwnFFOffAmazonBulkProducts,
    OwnFFOffAmazonBulkProductResponse,
} from "./types/SendToAmazon/sendToAmazon.types";
import { getUser } from "./authReducer";
import { sendToAmazon } from "../api/sendToAmazonAPI";
import { vendorProductsAPI } from "../api/addProductAPI";
import { SetLoadingType } from "./types/AuthTypes/auth.types";
import { manualQuotingTenantApi } from "../api/manualQuotingApi";
import { sortIntegrationList } from "../components/common/utils";
import { displayError, displayFormattedToastMessage, displaySuccess } from "../hooks/useErrorHandler";
import { TRequestLazySearchParams } from "./types/common/common.types";
import { TPostVendorProductHSDetailsReqData } from "./types/AddProductTypes/AddProduct.types";

export const SET_READY_TO_AMAZON_PRODUCT = "SET_READY_TO_AMAZON_PRODUCT";
export const SET_LOADING = "SET_LOADING";
export const SET_ERROR_AMAZON_PRODUCT = "SET_ERROR_AMAZON_PRODUCT";
export const SET_SHIPMENT_PLAN = "SET_SHIPMENT_PLAN";
export const SET_LOADING_MESSAGE = "SET_LOADING_MESSAGE";
export const SET_LOADING_SHIPPING_PLANS = "SET_LOADING_SHIPPING_PLANS";
export const SET_LOADING_SHIPMENT_PLANS = "SET_LOADING_SHIPMENT_PLANS";
export const SET_OWN_FF_AMAZON_PRODUCTS = "SET_OWN_FF_AMAZON_PRODUCTS";
export const SET_FF_DETAILS = "SET_FF_DETAILS";
export const SET_OWN_FF_PLANS = "SET_OWN_FF_PLANS";
export const SET_OWN_FF_AWD_PLANS = "SET_OWN_FF_AWD_PLANS";
export const SET_LOADING_STATES = "SET_LOADING_STATES";
export const SET_ALL_MARKETPLACES = "SET_ALL_MARKETPLACES";
export const SET_OUTBOUND_INTEGRATIONS = "SET_OUTBOUND_INTEGRATIONS";
export const SET_SKUDROP_AUTOMATED_BULK_UPLOAD = "SET_SKUDROP_AUTOMATED_BULK_UPLOAD";
export const SET_OWN_FF_OFF_AMAZON_BULK_PRODUCTS = "SET_OWN_FF_OFF_AMAZON_BULK_PRODUCTS";

type InitalStateType = {
    sendToAmazonProduct: TStateSendToAmazonProducts | null;
    isLoading: boolean;
    isLoadingMessage: null | string;
    isLoadingShipmentPlans: boolean;
    isErrorAmazonProduct: null | boolean | string;
    shipmentPlan: { [key: string]: TAmazonPlanSplits | null } | null;
    marketplaces: TOwnFFToAmazonMarketplaces | null;
    sendToAmazonFFProducts: TStateOwnFFSendToAmazonProducts;
    ffDetails: TFFDetailsResults[] | null;
    own_ff_plans: TStateOwnFFPlans | null;
    own_ff_awd_plans: TStateOwnFFAwdPlans | null;
    skudrop_automated_bulk_upload: TSKUdropAutomatedBulkProduct[] | null;
    loadingStates: TSendToAmazonLoadingStates;
    integrations: TOutboundShipmentsIntegrationsState;
    own_ff_off_amazon_bulk_products: OwnFFOffAmazonBulkProductResponse | null;
};

let initialState: InitalStateType = {
    sendToAmazonProduct: null,
    isLoading: false,
    isLoadingMessage: null,
    isLoadingShipmentPlans: false,
    isErrorAmazonProduct: null,
    shipmentPlan: null,
    marketplaces: null,
    sendToAmazonFFProducts: {
        container_builder: null,
        off_amazon: null,
    },
    ffDetails: null,
    own_ff_plans: null,
    own_ff_awd_plans: null,
    skudrop_automated_bulk_upload: null,
    loadingStates: {
        isLoadingSKUdropAmazonProducts: false,
        isLoadingOwnFFOffAmazonProducts: false,
        isLoadingOwnFFDetails: false,
        isLoadingOwnFFPlans: false,
        isLoadingMarketplaces: false,
        isLoadingOwnFFIntegrations: false,
        isLoadingPostShipmentPlan: false,
        isLoadingOutboundIntegrations: false,
        isLoadingSkudropAutomatedBulkUpload: false,
        // Bulk Own FF
        isLoadingBulkOwnFFDownload: false,
    },
    integrations: {
        own_ff_off_amazon: null,
        own_ff_container_builder: null,
        send_to_amazon: null,
    },
    own_ff_off_amazon_bulk_products: null,
};

const sendToAmazonReducer = (state = initialState, action: SendToAmazonActionTypes): InitalStateType => {
    switch (action.type) {
        case SET_ALL_MARKETPLACES: {
            return {
                ...state,
                marketplaces: action.data,
            };
        }
        case SET_READY_TO_AMAZON_PRODUCT: {
            return {
                ...state,
                sendToAmazonProduct: { ...state?.sendToAmazonProduct, [action.integrationId]: action.data },
            };
        }
        case SET_SHIPMENT_PLAN: {
            return {
                ...state,
                shipmentPlan: { ...state.shipmentPlan, [action.integration]: action.data },
            };
        }
        case SET_LOADING: {
            return {
                ...state,
                isLoading: action.data,
            };
        }
        case SET_LOADING_MESSAGE: {
            return {
                ...state,
                isLoadingMessage: action.data,
            };
        }
        case SET_ERROR_AMAZON_PRODUCT: {
            return {
                ...state,
                isErrorAmazonProduct: action.data,
            };
        }
        case SET_LOADING_SHIPMENT_PLANS: {
            return {
                ...state,
                isLoadingShipmentPlans: action.data,
            };
        }
        case SET_OWN_FF_AMAZON_PRODUCTS: {
            return {
                ...state,
                sendToAmazonFFProducts: {
                    ...state.sendToAmazonFFProducts,
                    ...action.data,
                },
            };
        }
        case SET_FF_DETAILS: {
            return {
                ...state,
                ffDetails: action.data,
            };
        }
        case SET_OWN_FF_PLANS: {
            return {
                ...state,
                own_ff_plans: { ...state?.own_ff_plans, [action.marketplace_id]: action.data },
            };
        }
        case SET_OWN_FF_AWD_PLANS: {
            return {
                ...state,
                own_ff_awd_plans: { ...state?.own_ff_awd_plans, [action.integration_id]: action.data },
            };
        }
        case SET_SKUDROP_AUTOMATED_BULK_UPLOAD: {
            return {
                ...state,
                skudrop_automated_bulk_upload: action.data,
            };
        }
        case SET_LOADING_STATES: {
            return {
                ...state,
                loadingStates: {
                    ...state.loadingStates,
                    ...action.data,
                },
            };
        }
        case SET_OUTBOUND_INTEGRATIONS: {
            return {
                ...state,
                integrations: { ...state.integrations, ...action.data },
            };
        }
        case SET_OWN_FF_OFF_AMAZON_BULK_PRODUCTS: {
            return {
                ...state,
                own_ff_off_amazon_bulk_products: action.data,
            };
        }
        default:
            return state;
    }
};

export const SetOutboundIntegrations = (data: Partial<TOutboundShipmentsIntegrationsState>): TSetOutboundIntegrations => ({
    type: SET_OUTBOUND_INTEGRATIONS,
    data,
});

export const SetAllMarketplaces = (data: TOwnFFToAmazonMarketplaces): SetMarketPlacesType => ({
    type: SET_ALL_MARKETPLACES,
    data,
});
export const SetReadyToAmazonProduct = (integrationId: string, data: AmazonProduct[]): SetReadyToAmazonProductType => ({
    type: SET_READY_TO_AMAZON_PRODUCT,
    integrationId,
    data,
});

export const SetOwnFFAmazonProducts = (data: Partial<TStateOwnFFSendToAmazonProducts>): TSetOwnFFAmazonProducts => ({
    type: SET_OWN_FF_AMAZON_PRODUCTS,
    data,
});
export const SetShipmentPlan = (integration: string, data: TAmazonPlanSplits | null): SetShipmentPlanType => ({
    type: SET_SHIPMENT_PLAN,
    data,
    integration,
});
export const SetLoading = (loading: boolean): SetLoadingType => ({
    type: SET_LOADING,
    data: loading,
});

export const SetLoadingMessage = (message: null | string): SetLoadingMessageType => ({
    type: SET_LOADING_MESSAGE,
    data: message,
});
export const SetErrorAmazonProduct = (error: null | boolean | string): SetErrorAmazonProductType => ({
    type: SET_ERROR_AMAZON_PRODUCT,
    data: error,
});
export const SetLoadingShipmentPlans = (loading: boolean): TSetLoadingShipmentPlans => ({
    type: SET_LOADING_SHIPMENT_PLANS,
    data: loading,
});
export const SetFFDetails = (data: TFFDetailsResults[]): TSetFFDetails => ({
    type: SET_FF_DETAILS,
    data,
});

export const SetOwnFFPlans = (marketplace_id: string, data: TOwnFFPlanItem[]): TSetOwnFFPlans => ({
    type: SET_OWN_FF_PLANS,
    marketplace_id,
    data,
});

export const SetSkudropAutomatedBulkUpload = (data: TSKUdropAutomatedBulkProduct[] | null): SetSkudropAutomatedBulkUploadType => ({
    type: SET_SKUDROP_AUTOMATED_BULK_UPLOAD,
    data,
});

export const SetOwnFFAwdPlans = (integration_id: string, data: TOwnFFAwdPlanItem[]): TSetOwnFFAwdPlans => ({
    type: SET_OWN_FF_AWD_PLANS,
    integration_id,
    data,
});

export const SetLoadingStates = (data: Partial<TSendToAmazonLoadingStates>): TSetLoadingStates => ({
    type: SET_LOADING_STATES,
    data,
});

export const SetOwnFFBulkProducts = (data: OwnFFOffAmazonBulkProductResponse | null): TSetOwnFFOffAmazonBulkProducts => ({
    type: SET_OWN_FF_OFF_AMAZON_BULK_PRODUCTS,
    data,
});

export const getOutboundIntegrations = (type: keyof TOutboundShipmentsIntegrationsState): SendToAmazonThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            dispatch(SetLoadingStates({ isLoadingOutboundIntegrations: true }));

            if (type === "send_to_amazon") {
                const response = await sendToAmazon.getAmazonFbaIntegration(cookies, { limit: 20 });

                const filteredResults = response.data.results.filter((item) => item.country_code !== "DE" && item.country_code !== "FR");

                dispatch(SetOutboundIntegrations({ send_to_amazon: sortIntegrationList(filteredResults) }));
            } else if (type === "own_ff_off_amazon") {
                const response = await manualQuotingTenantApi.getQuoteAvailableIntegrations(cookies, { limit: 20 });

                dispatch(SetOutboundIntegrations({ own_ff_off_amazon: sortIntegrationList(response.data.results || []) }));
            } else {
                const response = await manualQuotingTenantApi.getQuoteAvailableIntegrations(cookies, { limit: 20, source_type: "Amazon" });

                const filtered = (response.data.results.filter((item) => item.country_code !== "VS") || []) as TSendToAmazonIntegration[];

                dispatch(SetOutboundIntegrations({ own_ff_container_builder: sortIntegrationList(filtered) }));
            }

            dispatch(SetLoadingStates({ isLoadingOutboundIntegrations: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getAllMarketplaces = (): SendToAmazonThunkType => {
    return async (dispatch, getState) => {
        try {
            dispatch(SetLoadingStates({ isLoadingMarketplaces: true }));

            const cookies = getState().auth.cookies;

            const response = await sendToAmazon.getAllMarketplaces(cookies);

            dispatch(SetAllMarketplaces(response.data.marketplaces));

            dispatch(SetLoadingStates({ isLoadingMarketplaces: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getReadyToAmazonProduct = (
    cookies: Cookies,
    integration_id: string,
    searchTerm: string,
    items: number,
    offset: number,
    warehouse_id: string,
    setProductAllNext: (value: boolean) => void
): SendToAmazonThunkType => {
    return async (dispatch) => {
        try {
            if (!offset) {
                dispatch(SetLoadingStates({ isLoadingSKUdropAmazonProducts: true }));
            }

            const response = await sendToAmazon.getAmazonProduct(cookies, integration_id, searchTerm, items, offset, warehouse_id);

            setProductAllNext(response.data.next !== null ? true : false);

            console.log(response.data.results);

            dispatch(SetErrorAmazonProduct(null));
            dispatch(SetReadyToAmazonProduct(integration_id, response.data.results));

            if (!offset) {
                dispatch(SetLoadingStates({ isLoadingSKUdropAmazonProducts: false }));
            }
        } catch (e) {
            console.log(e);
            dispatch(SetLoadingStates({ isLoadingSKUdropAmazonProducts: false }));
        }
    };
};

export const getShipmentPlan = (cookies: Cookies, integration_id: string): SendToAmazonThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingShipmentPlans(true));

            const response = await sendToAmazon.getShipmentPlan(cookies, integration_id);

            dispatch(SetShipmentPlan(integration_id, response.data));

            dispatch(SetLoadingShipmentPlans(false));
        } catch (e) {
            console.log(e);
            dispatch(SetLoadingShipmentPlans(false));
        }
    };
};

export const deleteUnconfirmedShipmentPlan = (
    planId: string,
    integrationId: string,
    cookies: Cookies,
    searchTerm: string,
    items: number,
    offset: number,
    warehouse_id: string,
    setProductAllNext: (value: boolean) => void
): SendToAmazonThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingShipmentPlans(true));

            await sendToAmazon.deleteUnconfirmedShipmentPlan(planId, cookies);

            dispatch(getShipmentPlan(cookies, integrationId));
            dispatch(getReadyToAmazonProduct(cookies, integrationId, searchTerm, items, offset, warehouse_id, setProductAllNext));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingShipmentPlans(false));
                displayError(e.response.data, "An error occurred while deleting the shipment plan", { isAWS: true });
            }
        }
    };
};

export const createShipmentPlan = (
    cookies: Cookies,
    reqData: CreateShipmentPlanRequestType[],
    hsReqData: TPostVendorProductHSDetailsReqData | null,
    integrationId: string,
    searchTerm: string,
    items: number,
    offset: number,
    warehouse_id: string,
    setProductAllNext: (value: boolean) => void
): SendToAmazonThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingMessage("We are creating your shipping plans. If you have lots of SKU’s this could take up to a minute."));
            dispatch(SetLoadingStates({ isLoadingPostShipmentPlan: true }));

            if (hsReqData) {
                await vendorProductsAPI.postVendorProductHSDetails(cookies, hsReqData);
            }

            await sendToAmazon.createUnconfirmedShipmentPlan(cookies, reqData, integrationId);

            await dispatch(getShipmentPlan(cookies, integrationId));
            await dispatch(getReadyToAmazonProduct(cookies, integrationId, searchTerm, items, offset, warehouse_id, setProductAllNext));

            dispatch(SetLoadingMessage(null));
            dispatch(SetLoadingStates({ isLoadingPostShipmentPlan: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                await dispatch(getShipmentPlan(cookies, integrationId));
                await dispatch(getReadyToAmazonProduct(cookies, integrationId, searchTerm, items, offset, warehouse_id, setProductAllNext));

                dispatch(SetLoadingMessage(null));
                dispatch(SetLoadingStates({ isLoadingPostShipmentPlan: false }));

                displayError(e.response.data, "An error occurred while creating the shipment plan", { isAWS: true });
            }
        }
    };
};

export const submitShipment = (
    cookies: Cookies,
    reqData: TConfirmShippingPlanReqData,
    use_credits: boolean,
    integrationId: string,
    onSuccess: () => void
): SendToAmazonThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            dispatch(SetLoadingMessage("We are submitting your shipping plans. If you have lots of SKU’s this could take up to a minute."));

            await sendToAmazon.submitShipment(cookies, reqData);

            if (use_credits) {
                dispatch(getUser(cookies));
            }

            dispatch(SetShipmentPlan(integrationId, null));

            onSuccess();

            dispatch(SetErrorAmazonProduct(false));
            dispatch(SetLoading(false));
            dispatch(SetLoadingMessage(null));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                displayError(e.response.data, "An error occurred while submitting the shipping plan", { isAWS: true });

                dispatch(getShipmentPlan(cookies, integrationId));
                dispatch(SetLoading(false));
                dispatch(SetLoadingMessage(null));
            }
        }
    };
};

export const getSkudropAutomatedBulkUpload = (queryParams: Partial<{ integration_id: string }>): SendToAmazonThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            const response = await sendToAmazon.getSkudropAutomatedBulkUpload(cookies, queryParams);

            window.open(response.data);
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                displayError(e.response.data, "An error occurred while downloading the file");
            }
        }
    };
};

export const postSkudropAutomatedBulkUpload = (formData: FormData, integration_id: string, onSuccess: () => void): SendToAmazonThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;
            dispatch(SetLoadingStates({ isLoadingSkudropAutomatedBulkUpload: true }));

            const response = await sendToAmazon.postSkudropAutomatedBulkUpload(cookies, formData, integration_id);

            dispatch(SetSkudropAutomatedBulkUpload(response.data.data));

            onSuccess();

            dispatch(SetLoadingStates({ isLoadingSkudropAutomatedBulkUpload: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingSkudropAutomatedBulkUpload: false }));
                displayError(e.response.data, "An error occurred while uploading the file");
            }
        }
    };
};

export const getOwnFFOffAmazonProducts = (
    queryParams: TRequestLazySearchParams & Partial<{ integration_id: string }>,
    setHasNextItems: (value: boolean) => void
): SendToAmazonThunkType => {
    return async (dispatch, getState) => {
        try {
            // if (!queryParams.offset && component === "off_amazon") {
            //     dispatch(SetLoadingStates({ isLoadingOwnFFOffAmazonProducts: true }));
            // }

            const cookies = getState().auth.cookies;

            const response = await sendToAmazon.getOwnFFOffAmazonProducts(cookies, queryParams);

            setHasNextItems(!!response.data.next);

            dispatch(SetOwnFFAmazonProducts({ off_amazon: response.data.results }));

            // if (!queryParams.offset && component === "off_amazon") {
            //     dispatch(SetLoadingStates({ isLoadingOwnFFOffAmazonProducts: false }));
            // }
        } catch (e) {
            console.log(e);
            // if (!queryParams.offset && component === "off_amazon") {
            //     dispatch(SetLoadingStates({ isLoadingOwnFFOffAmazonProducts: false }));
            // }
        }
    };
};

export const getOwnFFContainerBuilderAmazonProducts = (
    queryParams: TRequestLazySearchParams & Partial<{ integration_id: string }>,
    setHasNextItems: (value: boolean) => void
): SendToAmazonThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            const response = await sendToAmazon.getOwnFFContainerBuilderProducts(cookies, queryParams);

            setHasNextItems(!!response.data.next);

            dispatch(SetOwnFFAmazonProducts({ container_builder: response.data.results }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const postCreateOwnFFOffAmazonPlan = (formData: FormData, onSuccess: () => void): SendToAmazonThunkType => {
    return async (dispatch, getState) => {
        try {
            dispatch(SetLoadingMessage("We are sending your shipment information to the warehouse, if you have lots of SKUs this could take up to a minute."));
            dispatch(SetLoading(true));

            const cookies = getState().auth.cookies;

            await sendToAmazon.postCreateOwnFFOffAmazonShipmentPlan(cookies, formData);

            onSuccess(); // clear changed products & clear all lazy items & reset lazy loading offset & reset all page changes

            dispatch(SetLoading(false));
            dispatch(SetLoadingMessage(null));

            displaySuccess("Shipment created successfully");
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                displayError(e.response.data, "An error occurred while sending the shipment plan to the warehouse");

                dispatch(SetLoading(false));
                dispatch(SetLoadingMessage(null));
            }
        }
    };
};

export const getFFDetails = (): SendToAmazonThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            dispatch(SetLoadingStates({ isLoadingOwnFFDetails: true }));

            const response = await sendToAmazon.getFFDetails(cookies);

            dispatch(SetFFDetails(response.data.results));

            dispatch(SetLoadingStates({ isLoadingOwnFFDetails: false }));
        } catch (e) {
            console.log(e);

            dispatch(SetLoadingStates({ isLoadingOwnFFDetails: false }));
        }
    };
};

export const postCreateFlatFile = (data: TPostCreateFlatFileData, onSuccess: () => void): SendToAmazonThunkType => {
    return async (_, getState) => {
        try {
            const cookies = getState().auth.cookies;

            await sendToAmazon.createFlatFile(cookies, data).then((res) => {
                window.open(res.data.url, "_blank");
                onSuccess();
            });
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                displayError(e.response.data, "An error occurred while creating the flat file");
            }
        }
    };
};

export const getOwnFFPlans = (marketplace_id: string, withLoading?: boolean): SendToAmazonThunkType => {
    return async (dispatch, getState) => {
        try {
            if (withLoading) {
                dispatch(SetLoadingStates({ isLoadingOwnFFPlans: true }));
            }

            const cookies = getState().auth.cookies;

            const response = await sendToAmazon.getOwnFFPlans(cookies, marketplace_id);

            dispatch(SetOwnFFPlans(marketplace_id, response.data.results));

            if (withLoading) {
                dispatch(SetLoadingStates({ isLoadingOwnFFPlans: false }));
            }
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                if (withLoading) {
                    dispatch(SetLoadingStates({ isLoadingOwnFFPlans: false }));
                }

                displayError(e.response.data);
            }
        }
    };
};

export const deleteOwnFFPlans = (marketplace_id: string, ids: string[]): SendToAmazonThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            await sendToAmazon.deleteOwnFFPlans(cookies, ids);

            await dispatch(getOwnFFPlans(marketplace_id)); // await is required for the correct order of actions in the function that calls this thunk
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                displayError(e.response.data);
            }
        }
    };
};

export const postCreateOwnFFToAmazonShipment = (marketplace_id: string, formData: FormData, onSuccess: () => void, handleNavigate: () => void): SendToAmazonThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            dispatch(SetLoadingMessage("We are sending your shipment information to the warehouse, if you have lots of SKUs this could take up to a minute."));
            dispatch(SetLoading(true));

            await sendToAmazon.postCreateOwnFFToAmazonShipment(cookies, formData);

            onSuccess(); // reset own ff changes

            await dispatch(getFFDetails());
            await dispatch(getOwnFFPlans(marketplace_id));

            dispatch(SetLoading(false));
            dispatch(SetLoadingMessage(null));

            handleNavigate();

            displaySuccess("Own FF shipment was created successfully");
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoading(false));
                dispatch(SetLoadingMessage(null));

                displayError(e.response.data, "An error occurred while sending the shipment plan to the warehouse");
            }
        }
    };
};

export const postProcessOwnFFToAmazonLabels = (data: FormData, marketplace_id: string, processStatus: (status: number) => void): SendToAmazonThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            const response = await sendToAmazon.postProcessOwnFFToAmazonLabels(cookies, data);

            await dispatch(getOwnFFPlans(marketplace_id));

            processStatus(response.status); // clear uploaded labels from state
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                processStatus(e.response.status); // change processing status
                displayError(e.response.data);
            }
        }
    };
};

export const postProcessOwnFFToAmazonCsv = (formData: FormData, marketplace_id: string, onSuccess: (plan_id?: string) => void, isAwd?: boolean): SendToAmazonThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            const response = await sendToAmazon.postProcessOwnFFToAmazonCsv(cookies, formData);

            if (isAwd) {
                await dispatch(getOwnFFAwdPlans(marketplace_id));
            } else {
                await dispatch(getOwnFFPlans(marketplace_id));
            }

            onSuccess(response.data.plan);
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                onSuccess();
                displayError(e.response.data);
            }
        }
    };
};

export const getOwnFFBulkDownload = (): SendToAmazonThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;
            dispatch(SetLoadingStates({ isLoadingBulkOwnFFDownload: true }));

            const response = await sendToAmazon.getOwnFFBulkDownload(cookies);

            window.open(response.data.url || "", "_blank");

            dispatch(SetLoadingStates({ isLoadingBulkOwnFFDownload: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const postOwnFFBulkUpload = (formData: FormData, onSuccess: () => void): SendToAmazonThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            dispatch(SetLoadingStates({ isLoadingBulkOwnFFDownload: true }));

            const response = await sendToAmazon.postOwnFFBulkUpload(cookies, formData);

            dispatch(SetOwnFFBulkProducts(response.data));

            onSuccess();

            if (response.data.errors) {
                displayFormattedToastMessage({
                    data: response.data.errors,
                    tostOptions: { type: "warning" },
                });
            }

            dispatch(SetLoadingStates({ isLoadingBulkOwnFFDownload: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingBulkOwnFFDownload: false }));
                displayError(e.response.data);
            }
        }
    };
};

// Own FF AWD shipments

export const getOwnFFAwdPlans = (marketplace_id: string, withLoading?: boolean): SendToAmazonThunkType => {
    return async (dispatch, getState) => {
        try {
            if (withLoading) {
                dispatch(SetLoadingStates({ isLoadingOwnFFPlans: true }));
            }

            const cookies = getState().auth.cookies;

            const response = await sendToAmazon.getOwnFFAwdPlans(cookies, { marketplace_id, limit: 1000 });

            dispatch(SetOwnFFAwdPlans(marketplace_id, response.data.results));

            if (withLoading) {
                dispatch(SetLoadingStates({ isLoadingOwnFFPlans: false }));
            }
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                if (withLoading) {
                    dispatch(SetLoadingStates({ isLoadingOwnFFPlans: false }));
                }

                displayError(e.response.data);
            }
        }
    };
};

export const deleteOwnFFAwdPlans = (marketplace_id: string, idsToDelete: string[]): SendToAmazonThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            await sendToAmazon.deleteOwnFFAwdPlans(cookies, idsToDelete);

            await dispatch(getOwnFFAwdPlans(marketplace_id));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                displayError(e.response.data);
            }
        }
    };
};

export const postProcessOwnFFToAmazonAwdLabels = (formData: FormData, marketplace_id: string, processStatus: (status: number) => void): SendToAmazonThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            const response = await sendToAmazon.postProcessOwnFFToAmazonAwdLabels(cookies, formData);

            await dispatch(getOwnFFAwdPlans(marketplace_id));

            processStatus(response.status);
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                processStatus(e.response.status);

                displayError(e.response.data, "An error occurred while processing AWD labels");
            }
        }
    };
};

export const postCreateOwnFFToAmazonAwdShipment = (marketplace_id: string, formData: FormData, onSuccess: () => void, navigate: () => void): SendToAmazonThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            dispatch(SetLoadingMessage("We are sending your shipment information to the warehouse, if you have lots of SKUs this could take up to a minute."));
            dispatch(SetLoading(true));

            await sendToAmazon.postCreateOwnFFToAmazonShipment(cookies, formData);

            onSuccess(); // reset own ff changes

            await dispatch(getFFDetails());
            await dispatch(getOwnFFAwdPlans(marketplace_id));

            dispatch(SetLoading(false));
            dispatch(SetLoadingMessage(null));

            navigate();

            displaySuccess("Shipment sent to the warehouse successfully");
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoading(false));
                dispatch(SetLoadingMessage(null));

                displayError(e.response.data, "An error occurred while sending the shipment plan to the warehouse");
            }
        }
    };
};

export default sendToAmazonReducer;
