import axios from "axios";
import Cookies from "universal-cookie";

import { ActionAdminWarehouseType, SetErrorWarehouseListType, SetIsLoadingType, TPostCreateWarehouse, ThunkType } from "./types/AdminWarehouseTypes/AdminWarehouse.types";
import { dashboardWarehouseAPI } from "../api/adminWarehouse";
import { displayError, displaySuccess } from "../hooks/useErrorHandler";

export const SET_ERROR_WAREHOUSE_LIST = "SET_ERROR_WAREHOUSE_LIST";
export const SET_LOADING = "SET_LOADING";

type AdminWarehouseInitialType = {
    isErrorWarehouseList: null | boolean;
    isLoading: boolean;
};

let initialState: AdminWarehouseInitialType = {
    isErrorWarehouseList: null,
    isLoading: false,
};

const warehouseSetUpReducer = (state = initialState, action: ActionAdminWarehouseType): AdminWarehouseInitialType => {
    switch (action.type) {
        case SET_ERROR_WAREHOUSE_LIST: {
            return {
                ...state,
                isErrorWarehouseList: action.data,
            };
        }
        case SET_LOADING: {
            return {
                ...state,
                isLoading: action.data,
            };
        }
        default:
            return state;
    }
};

export const SetErrorWarehouseList = (error: null | boolean): SetErrorWarehouseListType => ({
    type: SET_ERROR_WAREHOUSE_LIST,
    data: error,
});

export const SetLoading = (loading: boolean): SetIsLoadingType => ({
    type: SET_LOADING,
    data: loading,
});

export const WarehouseSetUp = (data: TPostCreateWarehouse, onSuccess: () => void): ThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            dispatch(SetLoading(true));

            await dashboardWarehouseAPI.warehouseSetUp(cookies, data);

            onSuccess();

            displaySuccess("Warehouse creation procedure started.");

            dispatch(SetLoading(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoading(false));
                displayError(e.response.data, "Something went wrong when creating a warehouse");
            }
        }
    };
};

export default warehouseSetUpReducer;
