export const ORDER_DETAILS_FIELDS = [
    "custom_id",
    "shipment_id",
    "created_at",
    "sku",
    "cubic_value",
    "number_of_cartons",
    "boat_calculation_type",
    "amazon_warehouse_id",
    "arrived_to_amazon_at",
];
export const TRACKING_DETAILS_FIELDS = [
    "container_number",
    "estimated_departure_time",
    "estimated_arrival_time",
    "carrier_tracking_id",
    "mawb_number",
    "vessel_name",
    "map",
    "status",
];
export const LAST_MILE_DETAILS_FIELDS = ["container_discharged", "delivered_to_warehouse", "picked_up", "delivered"];
export const DOCUMENTATIONS_FIELDS = ["bill_of_lading", "proof_of_delivery", "import_service_agreement"];

export const TRACKING_FIELD_NAME_TO_LABEL = {
    custom_id: "orderNumber",
    shipment_id: "fbaID",
    created_at: "dateLowercase",
    sku: "skuList",
    cubic_value: "cbmUpper",
    number_of_cartons: "cartons",
    boat_calculation_type: "method",
    amazon_warehouse_id: "destination",
    arrived_to_amazon_at: "deliveryDate",
    container_number: "containerNumberFull",
    estimated_departure_time: "ETD",
    estimated_arrival_time: "ETA",
    carrier_tracking_id: "trackingID",
    mawb_number: "blNumber",
    vessel_name: "vesselName",
    map: "map",
    status: "status",
    container_discharged: "containerDischarged",
    delivered_to_warehouse: "deliveredToWarehouse",
    picked_up: "pickedUpCamelCase",
    delivered: "delivered",
    bill_of_lading: "BOL",
    proof_of_delivery: "POD",
    import_service_agreement: "ISA",
};

export const TRACKING_STATUS_TO_COLOR = {
    Arrived: {
        background: "#ccfedb",
        text: "#126c2f",
    },
    New: {
        background: "#fbe6e8",
        text: "#124c2f",
    },
    "In progress": {
        background: "#b5d3ff",
        text: "#0076ba",
    },
    InProgress: {
        background: "#b5d3ff",
        text: "#0076ba",
    },
    Booked: {
        background: "#b5d3ff",
        text: "#0076ba",
    },
    Loaded: {
        background: "#b5d3ff",
        text: "#0076ba",
    },
    Sailing: {
        background: "#b3d5ff",
        text: "#0076ba",
    },
    Discharged: {
        background: "#ccfedb",
        text: "#126c2f",
    },
    Untracked: {
        background: "#fbe6e6",
        text: "#f13938",
    },
    NEW: {
        background: "#fbe6e8",
        text: "#124c2f",
    },
    INPROGRESS: {
        background: "#b5d3ff",
        text: "#0076ba",
    },
    BOOKED: {
        background: "#b5d3ff",
        text: "#0076ba",
    },
    EN_ROUTE: {
        background: "#b3d5ff",
        text: "#0076ba",
    },
    LANDED: {
        background: "#ccfedb",
        text: "#126c2f",
    },
    DELIVERED: {
        background: "#ccfedb",
        text: "#126c2f",
    },
    UNTRACKED: {
        background: "#fbe6e6",
        text: "#f13938",
    },
    Inprogress: {
        background: "#b5d3ff",
        text: "#0076ba",
    },
};

export const TRACKING_STATUS_TRANSLATIONS = {
    Arrived: "arrived",
    New: "new",
    "In progress": "inProgress",
    InProgress: "inProgress",
    Booked: "booked",
    Loaded: "loaded",
    Sailing: "sailing",
    Discharged: "discharged",
    Untracked: "untracked",
    NEW: "new",
    INPROGRESS: "inProgress",
    BOOKED: "booked",
    EN_ROUTE: "enRoute",
    LANDED: "landed",
    DELIVERED: "delivered",
    UNTRACKED: "untracked",
    Inprogress: "inProgress",
};

export const TRACKING_FILTER_TRANSLATIONS = {
    "": "filter",
    "NEXT TO ARRIVE": "filterByNextToArrive",
    "LATE SHIPMENTS": "filterByLateShipments",
    "AMAZON SHIPMENTS": "filterByAmazonShipments",
    "OTHER SHIPMENTS": "filterByOtherShipments",
    "LAST MILE SHIPMENTS": "filterByLastMileShipments",
};

export const TRACKING_TABLE_TITLE_TRANSLATIONS = {
    "Order Details": "orderDetailsCamelCase",
    "Tracking Details": "trackingDetailsCamelCase",
    "Last Mile Details": "lastMileDetailsCamelCase",
    Documentations: "documentation",
};

export const TRACKING_FILTER_LABEL_TO_VALUE = {
    "NEXT TO ARRIVE": "next_to_arrive",
    "LATE SHIPMENTS": "late_shipments",
    "AMAZON SHIPMENTS": "amazon_shipments",
    "OTHER SHIPMENTS": "other_shipments",
    "LAST MILE SHIPMENTS": "last_mile_shipments",
};
