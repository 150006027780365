import axios from "axios";

import Cookies from "universal-cookie";

import { dashboardAPI, vendorProductsAPI } from "../api/addProductAPI";
import { displayError, displaySuccess } from "../hooks/useErrorHandler";
import {
    TSetBrands,
    TSetTenantDashboardLoadingStates,
    TTenantDashboardActions,
    TTenantDashboardLoadingStates,
    TTenantDashboardThunk,
    TBrandsStateData,
    TBrandsData,
    TSetDashboardTotals,
    TDashboardTotalsState,
    TSetBrandsData,
    TTotalsState,
    TSupplierDetails,
    TSetSuppliers,
    TTotalStorage,
    TSetTotalStorage,
    TVendorProductMappingIntegrationsState,
    TSetVendorProductMappingIntegrations,
} from "./types/tenantDashboard.types";
import { tenantDashboardAPI } from "../api/tenantDashboardAPI";
import { TSKUdropVendorMarkets } from "./types/common/common.types";
import { EditProductDataType, TIntegrationSourceTypes, TPatchVendorProductReqData } from "./types/AddProductTypes/AddProduct.types";

export const SET_TOTAL_STORAGE = "SET_TOTAL_STORAGE";
export const SET_BRANDS = "SET_BRANDS";
export const SET_LOADING_STATES = "SET_LOADING_STATES";
export const SET_BRANDS_DATA = "SET_BRANDS_DATA";
export const SET_TOTALS = "SET_TOTALS";
export const SET_SUPPLIERS = "SET_SUPPLIERS";
export const SET_OUTGOING_ORDERS_DETAILS = "SET_OUTGOING_ORDERS_DETAILS";
export const SET_VENDOR_PRODUCT_MAPPING_INTEGRATIONS = "SET_VENDOR_PRODUCT_MAPPING_INTEGRATIONS";

export type TTenantDashboardInitialState = {
    totalStorage: TTotalStorage;
    brands: string[];
    brandsData: TBrandsStateData;
    totals: TDashboardTotalsState;
    loadingStates: TTenantDashboardLoadingStates;
    suppliers: TSupplierDetails[];
    vendorProductMappingIntegrations: TVendorProductMappingIntegrationsState;
};

const initialState: TTenantDashboardInitialState = {
    totalStorage: {
        required_action_products: [],
        stored_products: [],
        uploaded_products: [],
    },
    brands: [],
    brandsData: {},
    totals: {
        total_storage: {
            outgoing_totals: {
                total_cartons_in_tranisit: 0,
                total_cartons_prepared_outgoing: 0,
            },
            incoming_totals: {
                total_cartons_incoming: 0,
                total_cubic_incoming: 0,
            },
            stored_dict: {
                total_cartons_stored: 0,
                total_cubic_stored: 0,
                total_units_stored: 0,
            },
        },
    },
    loadingStates: {
        isLoadingIncomingShipments: false,
        isLoadingOutgoingSKUdropShipments: false,
        isLoadingRequireActionProducts: false,
        isLoadingStoredProducts: false,
        isLoadingUploadedProducts: false,
        isLoadingUploadedProductDetailsModal: false,
        isLoadingBrands: false,
        isLoadingSuppliers: false,
        isLoadingVendorProductsRemapping: false,
        isLoadingBrandIncomingShipments: false,
        isLoadingBrandRequireActionProducts: false,
        isLoadingBrandStoredProducts: false,
        isLoadingBrandUploadedProducts: false,
        isLoadingMappingIntegrations: false,
        isLoadingTenantDashboardTotals: false,
    },
    suppliers: [],
    vendorProductMappingIntegrations: {},
};

const tenantDashboardReducer = (state = initialState, action: TTenantDashboardActions): TTenantDashboardInitialState => {
    switch (action.type) {
        case SET_LOADING_STATES: {
            return {
                ...state,
                loadingStates: { ...state.loadingStates, ...action.data },
            };
        }
        case SET_TOTAL_STORAGE: {
            return {
                ...state,
                totalStorage: { ...state.totalStorage, ...action.data },
            };
        }
        case SET_BRANDS: {
            return {
                ...state,
                brands: action.data,
            };
        }
        case SET_BRANDS_DATA: {
            return {
                ...state,
                brandsData: { ...state.brandsData, [action.brand]: { ...state.brandsData?.[action.brand], ...action.data } },
            };
        }
        case SET_TOTALS: {
            return {
                ...state,
                totals: { ...state.totals, [action.brand]: { ...state.totals[action.brand], ...action.data } },
            };
        }
        case SET_SUPPLIERS: {
            return {
                ...state,
                suppliers: action.data,
            };
        }
        case SET_VENDOR_PRODUCT_MAPPING_INTEGRATIONS: {
            return {
                ...state,
                vendorProductMappingIntegrations: { ...state?.vendorProductMappingIntegrations, ...action.data },
            };
        }
        default: {
            return state;
        }
    }
};

export const SetLoadingStates = (data: Partial<TTenantDashboardLoadingStates>): TSetTenantDashboardLoadingStates => ({
    type: SET_LOADING_STATES,
    data,
});

export const SetBrands = (data: string[]): TSetBrands => ({
    type: SET_BRANDS,
    data,
});

export const SetTotalStorage = (data: Partial<TTotalStorage>): TSetTotalStorage => ({
    type: SET_TOTAL_STORAGE,
    data,
});

export const SetBrandsData = (brand: string, data: Partial<TBrandsData>): TSetBrandsData => ({
    type: SET_BRANDS_DATA,
    data,
    brand: brand,
});

export const SetTotals = (brand: "total_storage" | string, data: Partial<TTotalsState>): TSetDashboardTotals => ({
    type: SET_TOTALS,
    data,
    brand,
});

export const SetSuppliers = (data: TSupplierDetails[]): TSetSuppliers => ({
    type: SET_SUPPLIERS,
    data,
});

export const SetVendorProductMappingIntegrations = (data: TVendorProductMappingIntegrationsState): TSetVendorProductMappingIntegrations => ({
    type: SET_VENDOR_PRODUCT_MAPPING_INTEGRATIONS,
    data,
});

export const patchEditUploadedProduct = (
    carton_id: string,
    data: EditProductDataType,
    queryParams: Partial<{
        integration__country_code: TSKUdropVendorMarkets;
        brand: string;
    }>,
    onSuccess: () => void
): TTenantDashboardThunk => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            dispatch(SetLoadingStates({ isLoadingUploadedProductDetailsModal: true }));

            await tenantDashboardAPI.patchEditUploadedProduct(cookies.get("token"), carton_id, data);

            onSuccess();

            await dispatch(getUploadedProducts(queryParams));

            dispatch(SetLoadingStates({ isLoadingUploadedProductDetailsModal: false }));

            if (data.category?.shipping_declaration_document.length) {
                displaySuccess("New documents will be updated soon");
            }
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingUploadedProductDetailsModal: false }));
                displayError(e.response.data, "Something went wrong during a product update");
            }
        }
    };
};

export const patchEditVendorUploadedProduct = (
    carton_id: string,
    data: TPatchVendorProductReqData,
    queryParams: Partial<{
        integration__country_code: TSKUdropVendorMarkets;
        brand: string;
    }>,
    onSuccess: () => void
): TTenantDashboardThunk => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            dispatch(SetLoadingStates({ isLoadingUploadedProductDetailsModal: true }));

            await tenantDashboardAPI.patchEditUploadedProduct(cookies.get("token"), carton_id, data);

            onSuccess();

            const isBrandDataLoaded = Object.keys(getState().tenantDashboard.brandsData).includes(queryParams.brand || "");

            if (isBrandDataLoaded) {
                const { brand, ...rest } = queryParams;

                const promises = [dispatch(getUploadedProducts(rest)), dispatch(getUploadedProducts(queryParams))];

                await Promise.all(promises);
            }

            dispatch(getUploadedProducts({ integration__country_code: queryParams?.integration__country_code }));

            dispatch(SetLoadingStates({ isLoadingUploadedProductDetailsModal: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingUploadedProductDetailsModal: false }));
                displayError(e.response.data, "Something went wrong during a product update");
            }
        }
    };
};

export const deleteUploadedProduct = (
    carton_id: string,
    queryParams: Partial<{
        integration__country_code: TSKUdropVendorMarkets;
        brand: string;
    }>,
    onSuccess: () => void
): TTenantDashboardThunk => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            dispatch(SetLoadingStates({ isLoadingUploadedProductDetailsModal: true }));

            await tenantDashboardAPI.deleteUploadedProduct(cookies.get("token"), carton_id);

            onSuccess();

            const isBrandDataLoaded = Object.keys(getState().tenantDashboard.brandsData).includes(queryParams.brand || "");

            if (isBrandDataLoaded) {
                const { brand, ...rest } = queryParams;

                const promises = [dispatch(getUploadedProducts(rest)), dispatch(getUploadedProducts(queryParams))];

                await Promise.all(promises);
            }

            dispatch(getUploadedProducts({ integration__country_code: queryParams?.integration__country_code }));

            dispatch(SetLoadingStates({ isLoadingUploadedProductDetailsModal: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingUploadedProductDetailsModal: false }));
                displayError(e.response.data, "Something went wrong while removing a product");
            }
        }
    };
};

export const deleteProductHSCode = (
    hsId: string,
    queryParams: Partial<{
        integration__country_code: TSKUdropVendorMarkets;
        brand: string;
    }>,
    onSuccess: () => void
): TTenantDashboardThunk => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            dispatch(SetLoadingStates({ isLoadingUploadedProductDetailsModal: true }));

            await tenantDashboardAPI.deleteProductHSCode(cookies, hsId);

            onSuccess();

            const isBrandDataLoaded = Object.keys(getState().tenantDashboard.brandsData).includes(queryParams.brand || "");

            if (isBrandDataLoaded) {
                const { brand, ...rest } = queryParams;

                const promises = [dispatch(getUploadedProducts(rest)), dispatch(getUploadedProducts(queryParams))];

                await Promise.all(promises);
            }

            dispatch(getUploadedProducts({ integration__country_code: queryParams?.integration__country_code }));

            dispatch(SetLoadingStates({ isLoadingUploadedProductDetailsModal: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingUploadedProductDetailsModal: false }));
                displayError(e.response.data, "Something went wrong when deleting the HS code");
            }
        }
    };
};

export const getBrandsNames = (cookies: Cookies): TTenantDashboardThunk => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingBrands: true }));

            const response = await dashboardAPI.getBrands(cookies.get("token"));

            dispatch(SetBrands(response.data));

            dispatch(SetLoadingStates({ isLoadingBrands: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getRequireActionProducts = (
    queryParams?: Partial<{
        integration__country_code: TSKUdropVendorMarkets;
        brand: string;
    }>
): TTenantDashboardThunk => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            const loadingField = queryParams?.brand ? "isLoadingBrandRequireActionProducts" : "isLoadingRequireActionProducts";

            dispatch(SetLoadingStates({ [loadingField]: true }));

            let response = await tenantDashboardAPI.getRequireActionProducts(cookies, queryParams);

            if (queryParams?.brand) {
                dispatch(SetBrandsData(queryParams.brand, { require_action_products: response.data.results }));
            } else {
                dispatch(SetTotalStorage({ required_action_products: response.data.results }));
            }

            dispatch(SetLoadingStates({ [loadingField]: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const deleteRequiredActionProduct = (
    id: string,
    queryParams?: Partial<{
        integration__country_code: TSKUdropVendorMarkets;
        brand: string;
    }>
): TTenantDashboardThunk => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            dispatch(SetLoadingStates({ isLoadingRequireActionProducts: true }));

            await tenantDashboardAPI.deleteUploadedProduct(cookies.get("token"), id);

            const isBrandDataLoaded = Object.keys(getState().tenantDashboard.brandsData).includes(queryParams?.brand || "");

            if (isBrandDataLoaded && queryParams?.brand) {
                const { brand, ...rest } = queryParams;

                const promises = [dispatch(getRequireActionProducts(rest)), dispatch(getRequireActionProducts(queryParams))];

                await Promise.all(promises);
            }

            dispatch(getRequireActionProducts({ integration__country_code: queryParams?.integration__country_code }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingRequireActionProducts: false }));
                displayError(e.response.data, "Something went wrong while removing a product");
            }
        }
    };
};

export const getUploadedProducts = (
    queryParams?: Partial<{
        integration__country_code: TSKUdropVendorMarkets;
        brand: string;
    }>
): TTenantDashboardThunk => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            const loadingField = queryParams?.brand ? "isLoadingBrandUploadedProducts" : "isLoadingUploadedProducts";

            dispatch(SetLoadingStates({ [loadingField]: true }));

            let response = await tenantDashboardAPI.getUploadedProducts(cookies, queryParams);

            if (queryParams?.brand) {
                dispatch(SetBrandsData(queryParams.brand, { uploaded_products: response.data.results }));
            } else {
                dispatch(SetTotalStorage({ uploaded_products: response.data.results }));
            }

            dispatch(SetLoadingStates({ [loadingField]: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const postRemapVendorProduct = (
    formData: FormData,
    onSuccess: () => void,
    queryParams?: Partial<{
        integration__country_code: TSKUdropVendorMarkets;
        brand: string;
    }>
): TTenantDashboardThunk => {
    return async (dispatch, getState) => {
        try {
            dispatch(SetLoadingStates({ isLoadingVendorProductsRemapping: true }));

            const cookies = getState().auth.cookies;

            await tenantDashboardAPI.postRemapVendorProduct(cookies, formData);

            onSuccess();

            const isBrandDataLoaded = Object.keys(getState().tenantDashboard.brandsData).includes(queryParams?.brand || "");

            if (isBrandDataLoaded && queryParams?.brand) {
                const { brand, ...rest } = queryParams;

                const promises = [dispatch(getUploadedProducts({ brand })), dispatch(getStoredProducts(rest))];

                await Promise.all(promises);
            }

            dispatch(getStoredProducts({ integration__country_code: queryParams?.integration__country_code }));

            dispatch(getUploadedProducts({ integration__country_code: queryParams?.integration__country_code }));

            dispatch(SetLoadingStates({ isLoadingVendorProductsRemapping: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingVendorProductsRemapping: false }));
                displayError(e.response.data);
            }
        }
    };
};

export const getStoredProducts = (
    queryParams?: Partial<{
        integration__country_code: TSKUdropVendorMarkets;
        brand: string;
    }>
): TTenantDashboardThunk => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            const loadingField = queryParams?.brand ? "isLoadingBrandStoredProducts" : "isLoadingStoredProducts";

            dispatch(SetLoadingStates({ [loadingField]: true }));

            const response = await tenantDashboardAPI.getStoredProducts(cookies, queryParams);

            if (queryParams?.brand) {
                dispatch(SetBrandsData(queryParams.brand, { stored_products: response.data.results }));
            } else {
                dispatch(SetTotalStorage({ stored_products: response.data.results }));
            }

            dispatch(SetLoadingStates({ [loadingField]: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getStoredProductsSuppliers = (queryParams?: Partial<{ brand: string }>): TTenantDashboardThunk => {
    return async (dispatch, getState) => {
        try {
            dispatch(SetLoadingStates({ isLoadingSuppliers: true }));

            const { cookies } = getState().auth;

            const response = await tenantDashboardAPI.getSuppliers(cookies, queryParams);

            dispatch(SetSuppliers(response.data));

            dispatch(SetLoadingStates({ isLoadingSuppliers: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getVendorProductMappingIntegrations = (
    queryParams: Partial<{ source_type: TIntegrationSourceTypes; limit: number }> & { vendor_product_id: string }
): TTenantDashboardThunk => {
    return async (dispatch, getState) => {
        try {
            dispatch(SetLoadingStates({ isLoadingMappingIntegrations: true }));

            const cookies = getState().auth.cookies;

            const response = await vendorProductsAPI.getVendorMappingIntegrations(cookies, queryParams);

            dispatch(SetVendorProductMappingIntegrations({ [queryParams.vendor_product_id]: response.data.results }));

            dispatch(SetLoadingStates({ isLoadingMappingIntegrations: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getTenantDashboardTotals = (queryParams?: Partial<{ brand: string }>): TTenantDashboardThunk => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            dispatch(SetLoadingStates({ isLoadingTenantDashboardTotals: true }));

            const response = await tenantDashboardAPI.getTenantDashboardTotals(cookies, queryParams);

            dispatch(SetTotals(queryParams?.brand || "total_storage", response.data));

            dispatch(SetLoadingStates({ isLoadingTenantDashboardTotals: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export default tenantDashboardReducer;
