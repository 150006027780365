import Cookies from "universal-cookie";

import { instance } from "./authAPI";
import { processRequestQueryParams } from "./utils/apiUtils";
import { TPlatformMember } from "../redux/types/WarehouseTypes/warehouse.types";
import { TGenericResponseFormat, TRequestQueryParams } from "../redux/types/common/common.types";
import { TAdminChatGroup, TSupportRequest, TSupportRequestMessage } from "../redux/types/supportRequests.types";

export const supportRequestsAPI = {
    getSupportRequests(cookies: Cookies, queryParams?: TRequestQueryParams) {
        return instance.get<TGenericResponseFormat<TSupportRequest>>(`/tenants/admin-chat/${processRequestQueryParams(queryParams || {})}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getSupportRequestDetails(cookies: Cookies, chat_id: string) {
        return instance.get<TAdminChatGroup>(`/tenants/admin-chat/${chat_id}/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postSendChatMessage(cookies: Cookies, formData: FormData) {
        return instance.post<TSupportRequestMessage>(`/tenants/admin-chat/`, formData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    patchJoinToChat(cookies: Cookies, chat_id: string) {
        return instance.patch(`/tenants/admin-chat/${chat_id}/`, undefined, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    patchCloseChat(cookies: Cookies, chat_id: string) {
        return instance.patch(`/tenants/close-chat/${chat_id}/`, undefined, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postStartInternalChat(cookies: Cookies, formData: FormData) {
        return instance.post(`/tenants/start-internal-chat/`, formData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getInternalChatDetails(cookies: Cookies, chat_id: string) {
        return instance.get<TAdminChatGroup>(`/tenants/admin-internal-chat/${chat_id}/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postSendInternalChatMessage(cookies: Cookies, formData: FormData) {
        return instance.post(`/tenants/admin-internal-chat/`, formData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getAdministrators(cookies: Cookies, queryParams?: TRequestQueryParams) {
        return instance.get<TGenericResponseFormat<TPlatformMember>>(`https://dev.dev.skudrop.com/api/users/set-role/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};
