import { MdLanguage } from "react-icons/md";

import { TSKUdropVendorMarkets } from "../../redux/types/common/common.types";
import { TCartonsTemplate } from "../../redux/types/SendToAmazon/sendToAmazon.types";

import gb_flag from "../../assets/img/flags/flag_uk.png";
import usa_flag from "../../assets/img/flags/flag_usa.png";
import france_flag from "../../assets/img/flags/flag_france.png";
import germany_flag from "../../assets/img/flags/flag_germany.png";

export function formatNumber(value: number, accuracy: number = 2) {
    const processedValue = parseFloat(value.toFixed(5));

    if (Number.isInteger(processedValue)) {
        return processedValue.toLocaleString("en-US", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });
    } else {
        return processedValue.toLocaleString("en-US", {
            minimumFractionDigits: accuracy,
            maximumFractionDigits: accuracy,
        });
    }
}

export function formatISODate(date: string) {
    const formattedDate = date.split("T")[0];
    const time = date.split("T")[1].split(".")[0].split(":").slice(0, 2).join(":");

    return `${formattedDate} ${time}`;
}

// Sort integrations by country code, with US first
export function sortIntegrationList<T>(integrations: ({ country_code: string } & T)[]) {
    return integrations.sort((a, b) => (a.country_code === "US" ? -1 : b.country_code === "US" ? 1 : a.country_code.localeCompare(b.country_code))) || integrations;
}

export function processCountryFlag(country_code: string): string | undefined {
    const flagMap: Record<string, string> = {
        FR: france_flag,
        DE: germany_flag,
        US: usa_flag,
        UK: gb_flag,
    };

    return flagMap[country_code];
}

export function processCountryImage({
    country_code,
    styles,
    classNames,
    disableVSContainer,
}: {
    country_code: TSKUdropVendorMarkets;
    styles?: Partial<{ img: React.CSSProperties; svg: React.CSSProperties; vsContainer: React.CSSProperties }>;
    classNames?: Partial<{ img: string; svg: string; vsContainer: string }>;
    disableVSContainer?: boolean;
}) {
    const flagMap: Record<string, string> = {
        FR: france_flag,
        DE: germany_flag,
        US: usa_flag,
        UK: gb_flag,
    };

    return country_code === "VS" ? (
        disableVSContainer ? (
            <MdLanguage color="#6D6D6D" size={19} className={classNames?.svg} style={styles?.svg} />
        ) : (
            <div className={`d-flex align-items-center justify-content-center ${classNames?.vsContainer || ""}`} style={{ width: 20, ...styles?.vsContainer }}>
                <MdLanguage color="#6D6D6D" size={19} className={classNames?.svg} style={styles?.svg} />
            </div>
        )
    ) : (
        <img width={20} className={classNames?.img} style={styles?.img} src={flagMap[country_code]} alt="" />
    );
}

export function extractTemplateCartons<T extends TCartonsTemplate>(
    carton_templates: T[],
    totalUnits: number
): {
    carton: T;
    used_number_of_cartons: number;
    used_number_of_units: number;
}[] {
    const result = [];
    let remaining_units = totalUnits;

    for (const carton of carton_templates) {
        const extractedQuantities = [];

        for (const quantity of carton.quantity) {
            const units_per_carton = quantity.units_per_carton;
            const number_of_cartons = quantity.number_of_cartons;

            // Calculate total available units in this quantity
            const total_available_units = units_per_carton * number_of_cartons;

            // If we still need units, take them from this quantity
            if (remaining_units > 0) {
                const units_to_take = Math.min(remaining_units, total_available_units);

                // Calculate how many cartons to take
                const cartons_to_take = Math.ceil(units_to_take / units_per_carton);

                // Add the used portion of this quantity to extracted quantities
                extractedQuantities.push({
                    used_number_of_units: units_to_take,
                    used_number_of_cartons: cartons_to_take,
                });

                // Reduce remaining units
                remaining_units -= units_to_take;
            }
        }

        // If any quantities were used, add this carton to the result
        if (extractedQuantities.length > 0) {
            result.push({
                carton,
                used_number_of_cartons: extractedQuantities.reduce((acc, curr) => acc + curr.used_number_of_cartons, 0),
                used_number_of_units: extractedQuantities.reduce((acc, curr) => acc + curr.used_number_of_units, 0),
            });
        }
    }

    return result;
}

export function roundValueDynamicallyToInt(value: number | string, precision: number = 2) {
    const valueAsNumber = Number(value);

    if (valueAsNumber % 1 !== 0) {
        return valueAsNumber.toFixed(precision);
    }

    return valueAsNumber.toString();
}

export function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function detectDisplayableImageByName(fileName: string) {
    const displayableImageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".webp", ".apng", ".avif", ".svg"];
    const lowerCaseFileName = fileName.toLowerCase();

    return displayableImageExtensions.some((ext) => lowerCaseFileName.endsWith(ext));
}

export function fileToSRC(fileData: Partial<{ file: File; url: string }>) {
    if (fileData.file) {
        return URL.createObjectURL(fileData.file);
    }

    return fileData.url || "";
}
