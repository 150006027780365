import axios from "axios";
import Cookies from "universal-cookie";

import {
    ActionAffiliateTypes,
    SetLoadingType,
    PostAffiliateReqType,
    SetAffiliateAllType,
    SetAffiliateType,
    TAffiliateDetails,
    TSetAffiliateDetails,
    TSetLoadingAffiliateDetails,
    TUpdateAffiliateReqData,
    ThunkType,
    GetAffiliateRootObject,
    TPostAffiliateDetailsFileReqData,
} from "./types/AffiliateTypes/Affiliate.types";
import { affiliateAPI } from "../api/affiliateAPI";
import { displayError, displaySuccess } from "../hooks/useErrorHandler";

export const SET_LOADING = "SET_LOADING";
export const SET_AFFILIATE = "SET_AFFILIATE";
export const SET_AFFILIATE_ALL = "SET_AFFILIATE_ALL";
export const SET_AFFILIATE_DETAILS = "SET_AFFILIATE_DETAILS";
export const SET_LOADING_AFFILIATE_DETAILS = "SET_LOADING_AFFILIATE_DETAILS";

export type InitialStateType = {
    isLoading: boolean;
    affiliateList: null | GetAffiliateRootObject[];
    affiliateListAll: null | GetAffiliateRootObject[];
    affiliateDetails: null | { [key: string]: TAffiliateDetails };
    isLoadingAffiliateDetails: boolean;
};

let initialState: InitialStateType = {
    isLoading: false,
    affiliateList: null,
    affiliateListAll: null,
    affiliateDetails: null,
    isLoadingAffiliateDetails: false,
};

const affiliateReducer = (state = initialState, action: ActionAffiliateTypes): InitialStateType => {
    switch (action.type) {
        case SET_LOADING: {
            return {
                ...state,
                isLoading: action.data,
            };
        }
        case SET_AFFILIATE: {
            return {
                ...state,
                affiliateList: action.data,
            };
        }
        case SET_AFFILIATE_ALL: {
            return {
                ...state,
                affiliateListAll: action.data,
            };
        }
        case SET_AFFILIATE_DETAILS: {
            return {
                ...state,
                affiliateDetails: { ...state.affiliateDetails, [action.id]: action.data },
            };
        }
        case SET_LOADING_AFFILIATE_DETAILS: {
            return {
                ...state,
                isLoadingAffiliateDetails: action.data,
            };
        }
        default:
            return state;
    }
};

export const SetLoading = (data: boolean): SetLoadingType => ({
    type: SET_LOADING,
    data,
});
export const SetAffiliate = (data: GetAffiliateRootObject[]): SetAffiliateType => ({
    type: SET_AFFILIATE,
    data: data,
});
export const SetAffiliateAll = (data: GetAffiliateRootObject[]): SetAffiliateAllType => ({
    type: SET_AFFILIATE_ALL,
    data: data,
});
export const SetAffiliateDetails = (data: TAffiliateDetails, id: string): TSetAffiliateDetails => ({
    type: SET_AFFILIATE_DETAILS,
    data,
    id,
});
export const SetLoadingAffiliateDetails = (data: boolean): TSetLoadingAffiliateDetails => ({
    type: SET_LOADING_AFFILIATE_DETAILS,
    data: data,
});

export const getAffiliate = (token: string, items: number): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));

            const res = await affiliateAPI.getAffiliate(token, items);

            dispatch(SetAffiliate(res.data.results));

            dispatch(SetLoading(false));
        } catch (e) {
            console.log(e);
            dispatch(SetLoading(false));
        }
    };
};

export const getAffiliateAll = (cookies: Cookies, items: number, offset: number, setUserListAllNext: (value: boolean) => void, search: string, sortBy?: string): ThunkType => {
    return async (dispatch) => {
        try {
            const res = await affiliateAPI.getAffiliateAll(cookies.get("token"), items, offset, search, sortBy);
            dispatch(SetAffiliateAll(res.data.results));
            setUserListAllNext(res.data.next !== null ? true : false);
            console.log(res);
        } catch (e) {
            console.log(e);
        }
    };
};

export const postAffiliate = (token: string, data: PostAffiliateReqType): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));

            await affiliateAPI.postAffiliate(token, data);

            dispatch(getAffiliate(token, 5));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoading(false));
                displayError(e.response.data, "Something went wrong when creating an affiliate");
            }
        }
    };
};

export const updateAffiliate = (token: string, id: string, data: TUpdateAffiliateReqData): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            await affiliateAPI.updateAffiliate(token, id, data);
            dispatch(getAffiliate(token, 5));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoading(false));
                displayError(e.response.data, "Something went wrong while updating an affiliate");
            }
        }
    };
};

export const deleteAffiliate = (token: string, id: string): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const res = await affiliateAPI.deleteAffiliate(token, id);
            dispatch(getAffiliate(token, 5));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoading(false));
                displayError(e.response.data, "Something went wrong while deleting an affiliate");
            }
        }
    };
};

export const onPayAffiliate = (token: string, id: string): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));

            await affiliateAPI.onPayAffiliate(token, id);

            await dispatch(getAffiliate(token, 5));

            displaySuccess("Payment complete successfully");
        } catch (e) {
            if (axios.isAxiosError(e) && e.response && e.response) {
                dispatch(SetLoading(false));
                displayError(e.response.data, "Something went wrong during the payment");
            }
        }
    };
};

export const getAffiliateDetailsData = (cookies: Cookies, id: string): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingAffiliateDetails(true));

            const response = await affiliateAPI.getAffiliateDetailsData(cookies, id);

            dispatch(SetAffiliateDetails(response.data, id));

            dispatch(SetLoadingAffiliateDetails(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                displayError(e.response.data, "Something went wrong during getting affiliate details data");
            }
        }
    };
};

export const updateAffiliateInvestment = (cookies: Cookies, id: string, total_investment: number): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingAffiliateDetails(true));

            await affiliateAPI.updateAffiliateInvestment(cookies, id, total_investment);

            await dispatch(getAffiliateDetailsData(cookies, id));

            displaySuccess("Total investment updated successfully");
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                displayError(e.response.data, "Something went wrong during updating affiliate investment");
            }
        }
    };
};

export const postAffiliateDetailsFile = (cookies: Cookies, data: TPostAffiliateDetailsFileReqData): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingAffiliateDetails(true));

            const response = await affiliateAPI.postAffiliateDetailsFile(cookies, data);

            window.open(response.data, "_blank");

            dispatch(SetLoadingAffiliateDetails(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                displayError(e.response.data);
            }
        }
    };
};

export default affiliateReducer;
