import Cookies from "universal-cookie";

import { instance } from "./authAPI";
import { processRequestQueryParams } from "./utils/apiUtils";
import { TGenericResponseFormat, TQueryOnlyId, TRequestQueryParams } from "../redux/types/common/common.types";
import {
    TAllInboundShipment,
    TAllInboundShipmentDetails,
    TAllOwnFFShipment,
    TAllOwnFFShipmentDetails,
    TAllQuoteShipment,
    TAllQuoteShipmentDetails,
    TAllSKUdropShipment,
    TAllSKUdropShipmentDetails,
} from "../redux/types/AllShipmentsTypes/allShipments.types";
import { TSupportRequestMessage, TTenantChatGroup } from "../redux/types/supportRequests.types";

export const userAllShipmentsApi = {
    getInboundShipments(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TGenericResponseFormat<TAllInboundShipment>>(`/shipments/user/dashboard/incoming-shipment-history/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getSKUdropShipments(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TGenericResponseFormat<TAllSKUdropShipment>>(`/shipments/user/dashboard/outgoing-shipment-history/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getOwnFFShipments(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TGenericResponseFormat<TAllOwnFFShipment>>(`/shipments/user/dashboard/off-shipment-history/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getQuoteShipments(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TGenericResponseFormat<TAllQuoteShipment>>(`/manual_quoting/dashboard/manual-quote-history/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    // Shipments Details
    getQuoteShipmentDetails(cookies: Cookies, queryParams: TQueryOnlyId) {
        return instance.get<TAllQuoteShipmentDetails>(`/manual_quoting/dashboard/manual-quote-details-history/${queryParams.id}/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getOwnFFShipmentDetails(cookies: Cookies, queryParams: TQueryOnlyId) {
        return instance.get<TAllOwnFFShipmentDetails>(`/shipments/user/dashboard/off-shipment-history/${queryParams.id}/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getSKUdropShipmentDetails(cookies: Cookies, queryParams: TQueryOnlyId) {
        return instance.get<TAllSKUdropShipmentDetails>(`/shipments/user/dashboard/outgoing-shipment-history/${queryParams.id}/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getReceivedInboundShipmentDetails(cookies: Cookies, queryParams: TQueryOnlyId) {
        return instance.get<TAllInboundShipmentDetails>(`/shipments/user/dashboard/incoming-shipment-history/${queryParams.id}/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postCreateOrderSupportRequest(cookies: Cookies, formData: FormData) {
        return instance.post<TTenantChatGroup>("/tenants/start-chat/", formData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postSendOrderSupportMessage(cookies: Cookies, formData: FormData) {
        return instance.post<TSupportRequestMessage>("/tenants/user-chat/", formData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getOrderSupportDetails(cookies: Cookies, chat_id: string) {
        return instance.get<TTenantChatGroup>(`/tenants/user-chat/${chat_id}/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    deleteIncomingShipment(cookies: Cookies, queryParams: TQueryOnlyId) {
        return instance.delete(`/shipments/incoming-shipments/${queryParams.id}/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};
