import axios from "axios";
import Cookies from "universal-cookie";

import {
    roleList,
    incomingShipments,
    orderToShip,
    productList,
    trackingId,
    tariffList,
    tenantList,
    userCommunicationAPI,
    confirmAuthorizedUser,
    orderToPrep,
    AdminTotals,
    quoteOrders,
} from "../api/warehouseAPI";
import {
    AvailableId,
    CartonsStoredResult,
    ContainerTariffItem,
    CreateCustomerCommunicationType,
    DataNotesType,
    FilterType,
    GetAllContainersResult,
    GetAllIncomingWithTotals,
    GetIncomingShipmentsResult,
    GetTenantsListType,
    GetUserListResult,
    TPlatformMember,
    TPatchSubmitFinalWeightOrCbmReqData,
    IncomingDict,
    OutgoingDict,
    PatchProductListRequestType,
    PrepOrdersResult,
    SetAllContainersType,
    SetAvaibleIdListType,
    SetContainerTariffsListType,
    SetIncomingPrepOrderType,
    SetIncomingShipmentsAllType,
    SetIncomingShipmentsType,
    SetLoadingType,
    SetOutgoingTotalsType,
    SetProductListAllType,
    SetProductListType,
    SetShippedDateType,
    SetStoredPrepOrderType,
    SetTenantsListType,
    SetTenantTotalIncomingType,
    SetTenantTotalOutgoingType,
    SetTenantTotalStoredType,
    TSetTrackingIdAll,
    TSetTrackingId,
    SetUserListAllType,
    SetUserListType,
    SetUserRoleListAllType,
    SetUserRoleListType,
    SetWarehouseListAllType,
    SetWarehouseListType,
    StoredDict,
    TGetOrdersToPrepResultsItem,
    TOrdersToPrepState,
    TOrdersToPrepStateItem,
    TFreightForwarderFilter,
    TPostPrepOrders,
    TSetOrdersToPrep,
    TSetOrdersToPrepAll,
    UpdateDownloadInfoType,
    WarehouseActionTypes,
    WarehouseThunkType,
    TOrdersToShipStateItem,
    TGetOrdersToShipResultsItem,
    TSetOrdersToShip,
    TSetOrdersToShipAll,
    TOrdersToShipState,
    TPostCompletedOrderToPrep,
    TPostCompletedOwnFFOrderToShip,
    TWarehouseFilters,
    TSetWarehouseFilters,
    TSetWarehouseFiltersData,
    TWarehouseMarkets,
    TDashboardWarehouseLoadingStates,
    TSetLoadingStates,
    TPatchBulkPickedUpOrdersToShipReqData,
    TPatchBulkLoadedOrdersToShipReqData,
    TOrdersToShipStateAll,
    GetOrderToShipResult,
    TSetOrdersToShipBulkManagement,
    TPostOrdersToShipVerificationFile,
    TOrdersToPrepStateAll,
    TSetOrdersToPrepBulkManagement,
    TPostBulkCompletedOrdersToPrep,
    TQuoteOrder,
    TSetQuoteOrders,
    TPostCompletedQuoteOrderToPrep,
    TPatchQuoteOrders,
    TOrdersToTrackState,
    TOrdersToTrackStateAll,
    TQuoteOrdersFilter,
    TOrdersToTrackStateItem,
    TGetTrackingIdResultsItem,
    SetQuoteTrackingIdListType,
    TPatchUpdateQuoteTrackingIdsReqData,
    TPatchQuoteLoadedOrdersToShipReqData,
    TTariffManagementState,
    TTariffManagementResultItems,
    TSetTariffManagement,
    TSetTariffManagementAll,
    TTariffManagementStateItem,
    TWarehouseDashboardOrdering,
    TOrdersFilter,
    TQuoteOrdersStateItem,
    TQuoteOrdersState,
    TSetQuoteOrdersAll,
    TDashboardWarehouseFilters,
    TQuoteOrdersDetailsState,
    TQuoteOrdersDetailsItems,
    TQuoteOrdersDetailsOptions,
    TSetQuoteOrdersDetails,
    TUkContainerItemsToManage,
    TSetTariffFilterManagement,
    TSetTariffFilterManagementAll,
    TPostUkTariffAmountReqData,
    TSetSelectedDashboardWarehouse,
    TWarehouse,
    TSetDashboardWarehouses,
    PatchUpdateDeliveryWindowReqData,
    TDashboardWarehousesState,
    TSelectedDashboardWarehouseState,
    TPatchSetOrderTrackingIDsReqData,
    TSetManagePermissions,
    TManagePermissionData,
    TManagePermissionReqData,
    TAdminAllShipmentsDetailsState,
    TAdminAllShipmentsDetails,
    TAdminAllShipmentsDetailsOption,
    TAdminAllShipmentsDetailsItems,
    TAllOutgoingOrdersStateType,
    TSetAllIncomingOrders,
    TOutgoingOrders,
    TSetAllOutgoingOrders,
    TAllAdminShipmentsFilter,
    PatchUpdateVendorProductCartonReqData,
    TWarehousePermission,
    TSetPermissions,
    TWarehousePermissions,
    TOwnFFOrderDetailsOptions,
    TOwnFFOrdersDetailsState,
    TSetOwnFFOrdersDetails,
    TSKUdropOrderToPrepDetails,
    TAssignRoleRequestData,
    TEditRoleRequestData,
} from "./types/WarehouseTypes/warehouse.types";
import { Auth } from "./authReducer";
import { dashboardAPI } from "../api/addProductAPI";
import { dashboardWarehouseAPI } from "../api/adminWarehouse";
import { TPatchWarehouse } from "./types/AdminWarehouseTypes/AdminWarehouse.types";
import { displayError, displaySuccess, displayToastMessage, extractRequestErrors } from "../hooks/useErrorHandler";
import { ADMIN_TABS_DEFAULT_ORDERING, adminPermissions, defaultManagerPermissions } from "../components/DashboardWarehouse/common/Constants";
import { TRequestLazySearchParams, TSKUdropAllVendorMarkets, TSKUdropVendorMarkets, TSKUdropWarehouseNodes } from "./types/common/common.types";
import { UserType } from "./types/AuthTypes/auth.types";
import { tenantDashboardAPI } from "../api/tenantDashboardAPI";

export const SET_INCOMING_SHIPMENTS = "SET_INCOMING_SHIPMENTS";
export const SET_LOADING = "SET_LOADING";
export const SET_ERROR = "SET_ERROR";
export const SET_INCOMING_SHIPMENTS_ALL = "SET_INCOMING_SHIPMENTS_ALL";
export const SET_PRODUCT_LIST = "SET_PRODUCT_LIST";
export const SET_PRODUCT_LIST_ALL = "SET_PRODUCT_LIST_ALL";
export const SET_INCOMING_PREP_ORDERS_LIST = "SET_INCOMING_PRODUCT_LIST";
export const SET_TOTAL_STORED_PREP_ORDERS_LIST = "SET_TOTAL_STORED_PREP_ORDERS_LIST";
export const SET_TRACKING_ID_LIST = "SET_TRACKING_ID_LIST";
export const SET_TRACKING_ID_LIST_ALL = "SET_TRACKING_ID_LIST_ALL";
export const SET_OUTGOING_TOTAL = "SET_OUTGOING_TOTAL";
export const SET_WAREHOUSE_LIST = "SET_WAREHOUSE_LIST";
export const SET_WAREHOUSE_LIST_ALL = "SET_WAREHOUSE_LIST_ALL";
export const SET_USER_LIST = "SET_USER_LIST";
export const SET_USER_LIST_ALL = "SET_USER_LIST_ALL";
export const SET_USER_ROLE_LIST = "SET_USER_ROLE_LIST";
export const SET_USER_ROLE_LIST_ALL = "SET_USER_ROLE_LIST_ALL";
export const SET_TENANT_TOTAL_STORED = "SET_TENANT_TOTAL_STORED";
export const SET_TENANT_TOTAL_INCOMING = "SET_TENANT_TOTAL_INCOMING";
export const SET_TENANT_TOTAL_OUTGOING = "SET_TENANT_TOTAL_OUTGOING";
export const SET_TARIF_CALCULATED = "SET_TARIF_CALCULATED";
export const SET_AVAIBLE_ID_LIST = "SET_AVAIBLE_ID_LIST";
export const SET_TENANTS_LIST = "SET_TENANTS_LIST";
export const SET_ALL_OUTGOING_ORDERS = "SET_ALL_OUTGOING_ORDERS";
export const SET_ALL_SHIPMENTS = "SET_ALL_ALL_SHIPMENTS";
export const SET_ALL_INCOMING_ORDERS = "SET_ALL_INCOMING_ORDERS";
export const SET_ALL_CONTAINERS = "SET_ALL_CONTAINERS";
export const SET_CONTAINER_TARIFFS_LIST = "SET_CONTAINER_TARIFFS_LIST";
export const SET_ORDERS_TO_PREP = "SET_ORDERS_TO_PREP";
export const SET_ORDERS_TO_PREP_ALL = "SET_ORDERS_TO_PREP_ALL";
export const SET_ORDERS_TO_SHIP = "SET_ORDERS_TO_SHIP";
export const SET_ORDERS_TO_SHIP_ALL = "SET_ORDERS_TO_SHIP_ALL";
export const SET_WAREHOUSE_FILTERS = "SET_WAREHOUSE_FILTERS";
export const SET_LOADING_STATES = "SET_LOADING_STATES";
export const SET_ORDERS_TO_SHIP_BULK_MANAGEMENT = "SET_ORDERS_TO_SHIP_BULK_MANAGEMENT";
export const SET_ORDERS_TO_PREP_BULK_MANAGEMENT = "SET_ORDERS_TO_PREP_BULK_MANAGEMENT";
export const SET_QUOTE_ORDERS = "SET_QUOTE_ORDERS";
export const SET_QUOTE_ORDERS_ALL = "SET_QUOTE_ORDERS_ALL";
export const SET_TARIFF_MANAGEMENT = "SET_TARIFF_MANAGEMENT";
export const SET_TARIFF_MANAGEMENT_ALL = "SET_TARIFF_MANAGEMENT_ALL";
export const SET_QUOTE_ORDERS_DETAILS = "SET_QUOTE_ORDERS_DETAILS";
export const SET_ALL_SHIPMENT_ORDERS_DETAILS = "SET_ALL_SHIPMENT_ORDERS_DETAILS";
export const SET_TARIFF_FILTER_MANAGEMENT = "SET_TARIFF_FILTER_MANAGEMENT";
export const SET_TARIFF_FILTER_MANAGEMENT_ALL = "SET_TARIFF_FILTER_MANAGEMENT_ALL";
export const SET_SELECTED_DASHBOARD_WAREHOUSE = "SET_SELECTED_DASHBOARD_WAREHOUSE";
export const SET_DASHBOARD_WAREHOUSES = "SET_DASHBOARD_WAREHOUSES";
export const SET_MANAGE_PERMISSIONS = "SET_MANAGE_PERMISSIONS";
export const SET_PERMISSIONS = "SET_PERMISSIONS";
export const SET_OWN_FF_ORDERS_DETAILS = "SET_OWN_FF_ORDERS_DETAILS";

const defaultDashboardWarehouseFilters: TDashboardWarehouseFilters = {
    ordersToPrep: "skudrop",
    ordersToShip: "skudrop",
    trackingIdEntry: "skudrop",
    tariffManagement: "skudrop",
    quoteOrders: "quote_requests",
    allShipments: "incoming",
};

type InitialStateType = {
    incomingShipments: null | GetIncomingShipmentsResult[];
    incomingShipmentsAll: null | GetIncomingShipmentsResult[];
    incomingShipmentsSuspended: null | GetIncomingShipmentsResult[];
    incomingShipmentsSuspendedAll: null | GetIncomingShipmentsResult[];
    productList: null | CartonsStoredResult[];
    productListAll: null | CartonsStoredResult[];
    isLoading: boolean;
    incomingPrepOrders: null | IncomingDict;
    totalStoredPrepOrders: null | StoredDict;
    trackingList: TOrdersToTrackState;
    trackingListAll: TOrdersToTrackStateAll;
    outgoingTotals: null | OutgoingDict;
    tenantTotalIncoming: null | IncomingDict;
    tenantTotalStored: null | StoredDict;
    tenantTotalOutgoing: null | OutgoingDict;
    warehouseList: null | TWarehouse[];
    warehouseListAll: null | TWarehouse[];
    avaibleIdList: null | AvailableId[];
    userList: null | GetUserListResult[];
    userListAll: null | GetUserListResult[];
    usersRole: null | TPlatformMember[];
    usersRoleAll: null | TPlatformMember[];
    tenantsList: null | GetTenantsListType[];
    allIncomingOrders: GetAllIncomingWithTotals | null;
    allIncomingOrdersAll: GetAllIncomingWithTotals | null;
    allOutgoingOrders: TAllOutgoingOrdersStateType;
    allContainers: null | GetAllContainersResult[];
    downloadingTotalFile: boolean;
    containerTariffsList: null | ContainerTariffItem[];
    orders_to_prep: TOrdersToPrepState;
    orders_to_prep_all: TOrdersToPrepStateAll;
    orders_to_ship: TOrdersToShipState;
    orders_to_ship_all: TOrdersToShipStateAll;
    quote_orders: TQuoteOrdersState;
    quote_orders_all: TQuoteOrdersState;
    tariff_management: TTariffManagementState;
    tariff_management_all: TTariffManagementState;
    warehouseFilters: TWarehouseFilters;
    loadingStates: TDashboardWarehouseLoadingStates;
    quote_orders_details: TQuoteOrdersDetailsState;
    all_shipment_orders_details: TAdminAllShipmentsDetailsState;
    tariff_filter_management: {
        uk: TUkContainerItemsToManage[] | null;
    };
    tariff_filter_management_all: {
        uk: TUkContainerItemsToManage[] | null;
    };
    selectedDashboardWarehouse: TSelectedDashboardWarehouseState;
    dashboard_warehouses: TDashboardWarehousesState;
    manage_permissions: TManagePermissionData;
    permissions: TWarehousePermissions | null;
    own_ff_orders_details: TOwnFFOrdersDetailsState;
};

let initialState: InitialStateType = {
    incomingShipments: null,
    incomingShipmentsAll: null,
    incomingShipmentsSuspended: null,
    incomingShipmentsSuspendedAll: null,
    productList: null,
    productListAll: null,
    isLoading: false,
    incomingPrepOrders: null,
    totalStoredPrepOrders: null,
    trackingList: {
        skudrop: null,
        quote_orders: null,
    },
    trackingListAll: {
        skudrop: null,
        quote_orders: null,
    },
    outgoingTotals: null,
    tenantTotalIncoming: null,
    tenantTotalStored: null,
    tenantTotalOutgoing: null,
    warehouseList: null,
    warehouseListAll: null,
    avaibleIdList: null,
    userList: null,
    userListAll: null,
    usersRole: null,
    usersRoleAll: null,
    tenantsList: null,
    allIncomingOrders: null,
    allIncomingOrdersAll: null,
    allOutgoingOrders: {
        skudrop: null,
        own_ff: null,
        quote_orders: null,
    },
    allContainers: null,
    downloadingTotalFile: false,
    containerTariffsList: null,
    orders_to_prep: {
        skudrop: null,
        own_ff: null,
        quote_orders: null,
    },
    orders_to_prep_all: {
        skudrop: null,
        own_ff: null,
        quote_orders: null,
        bulk_management: null,
    },
    orders_to_ship: {
        skudrop: null,
        own_ff: null,
        quote_orders: null,
    },
    orders_to_ship_all: {
        skudrop: null,
        own_ff: null,
        quote_orders: null,
        bulk_management: null,
        verification_file: null,
    },
    tariff_management: {
        skudrop: null,
        quote_orders: null,
    },
    tariff_management_all: {
        skudrop: null,
        quote_orders: null,
    },
    quote_orders: {
        quote_requests: null,
        pending_quote_requests: null,
    },
    quote_orders_all: {
        quote_requests: null,
        pending_quote_requests: null,
    },
    warehouseFilters: {
        markets: {
            incomingShipments: "ALL",
            ordersToPrep: "ALL",
            ordersToShip: "ALL",
            trackingIdEntry: "ALL",
            tariffManagement: "ALL",
            storedProducts: "ALL",
            allShipments: "ALL",
        },
        filtersFF: localStorage.getItem("filtersFF")
            ? { ...defaultDashboardWarehouseFilters, ...JSON.parse(localStorage.getItem("filtersFF") as string) }
            : defaultDashboardWarehouseFilters,
    },
    loadingStates: {
        isLoadingIncomingCartons: false,
        isLoadingOrdersToPrep: false,
        isLoadingOrdersToShip: false,
        isLoadingTrackingIdEntry: false,
        isLoadingTariffManagement: false,
        isLoadingStoredProducts: false,
        isLoadingQuoteOrders: false,
        // Components
        isLoadingQuoteTariffManagementModal: false,
        isLoadingTrackingIDsManagementModal: false,
        isLoadingStoredProductDetailsModal: false,
        // Past Orders Modal
        isLoadingAllIncomingOrdersDetails: false,
        isLoadingAllOwnFFOutgoingOrdersDetails: false,
        isLoadingAllSkudropOrdersDetails: false,
        isLoadingAllQuoteOutgoingOrderDetails: false,
        // Manual Quoting Details
        isLoadingQuotePrepOrderDetails: false,
        isLoadingQuoteShipOrderDetails: false,
        isLoadingQuoteTrackingOrderDetails: false,
        isLoadingQuoteTariffOrderDetails: false,
        isLoadingQuoteRequestDetails: false,
        // Outbound Shipments
        isLoadingPastQuoteOutgoingOrderDetails: false,
        // Warehouse Dashboard
        isLoadingDashboardWarehouses: false,
        // Manage Permissions
        isLoadingManagePermissions: false,
        // Modal
        isLoadingTenantsDetails: false,
        isLoadingBulkTrackingIdUpload: false,
        isLoadingWarehousesDetails: false,
        isLoadingTrackingFilesUpload: false,
        isLoadingAllShipmentTotals: false,
        isLoadingOwnFFOrderToPrepDetails: false,
        isLoadingOwnFFOrderToShipDetails: false,
        isLoadingUsersRoles: false,
    },
    quote_orders_details: {
        order_to_prep: null,
        order_to_ship: null,
        tracking_id_entry: null,
        tariff_management: null,
        quote_request: null,
        past_outgoing_order: null,
    },
    all_shipment_orders_details: {
        own_ff: null,
        incoming: null,
        quote: null,
        skudrop: null,
    },
    tariff_filter_management: {
        uk: null,
    },
    tariff_filter_management_all: {
        uk: null,
    },
    selectedDashboardWarehouse: {
        CN: null,
        US: null,
    },
    dashboard_warehouses: {},
    manage_permissions: {
        warehouse_permissions: {},
        portal_permissions: {},
    },
    permissions: null,
    own_ff_orders_details: {
        order_to_prep: null,
        order_to_ship: null,
    },
};

const warehouseReducer = (state = initialState, action: WarehouseActionTypes): InitialStateType => {
    switch (action.type) {
        case SET_CONTAINER_TARIFFS_LIST: {
            return {
                ...state,
                containerTariffsList: action.data,
            };
        }
        case SET_ALL_INCOMING_ORDERS: {
            return {
                ...state,
                allIncomingOrders: action.data,
            };
        }
        case SET_ALL_OUTGOING_ORDERS: {
            return {
                ...state,
                allOutgoingOrders: { ...state.allOutgoingOrders, [action.option]: action.data },
            };
        }
        case SET_TENANT_TOTAL_STORED: {
            return {
                ...state,
                tenantTotalStored: action.data,
            };
        }
        case SET_TENANT_TOTAL_INCOMING: {
            return {
                ...state,
                tenantTotalIncoming: action.data,
            };
        }
        case SET_TENANT_TOTAL_OUTGOING: {
            return {
                ...state,
                tenantTotalOutgoing: action.data,
            };
        }
        case SET_USER_ROLE_LIST: {
            return {
                ...state,
                usersRole: action.data,
                isLoading: false,
            };
        }
        case SET_USER_ROLE_LIST_ALL: {
            return {
                ...state,
                usersRoleAll: action.data,
                isLoading: false,
            };
        }
        case SET_USER_LIST: {
            return {
                ...state,
                userList: action.data,
                isLoading: false,
            };
        }
        case SET_USER_LIST_ALL: {
            return {
                ...state,
                userListAll: action.data,
                isLoading: false,
            };
        }
        case SET_WAREHOUSE_LIST: {
            return {
                ...state,
                warehouseList: action.data,
                isLoading: false,
            };
        }
        case SET_WAREHOUSE_LIST_ALL: {
            return {
                ...state,
                warehouseListAll: action.data,
                isLoading: false,
            };
        }
        case SET_AVAIBLE_ID_LIST: {
            return {
                ...state,
                avaibleIdList: action.data,
                isLoading: false,
            };
        }
        case SET_INCOMING_SHIPMENTS: {
            return {
                ...state,
                incomingShipments: action.incoming,
                incomingShipmentsSuspended: action.suspended,
            };
        }
        case SET_INCOMING_SHIPMENTS_ALL: {
            return {
                ...state,
                incomingShipmentsAll: action.incoming,
                incomingShipmentsSuspendedAll: action.suspended,
            };
        }
        case SET_PRODUCT_LIST: {
            return {
                ...state,
                productList: action.data,
            };
        }
        case SET_PRODUCT_LIST_ALL: {
            return {
                ...state,
                productListAll: action.data,
            };
        }
        case SET_TRACKING_ID_LIST: {
            return {
                ...state,
                trackingList: { ...state.trackingList, [action.option]: action.data },
            };
        }
        case SET_TRACKING_ID_LIST_ALL: {
            return {
                ...state,
                trackingListAll: { ...state.trackingList, [action.option]: action.data },
            };
        }
        case SET_OUTGOING_TOTAL: {
            return {
                ...state,
                outgoingTotals: action.data,
                isLoading: false,
            };
        }
        case SET_LOADING: {
            return {
                ...state,
                isLoading: action.data,
            };
        }
        case SET_INCOMING_PREP_ORDERS_LIST: {
            return {
                ...state,
                incomingPrepOrders: action.data,
            };
        }

        case SET_TOTAL_STORED_PREP_ORDERS_LIST: {
            return {
                ...state,
                totalStoredPrepOrders: action.data,
            };
        }
        case SET_TENANTS_LIST: {
            return {
                ...state,
                tenantsList: action.data,
            };
        }
        case SET_ALL_CONTAINERS: {
            return {
                ...state,
                allContainers: action.data,
            };
        }
        case SET_ORDERS_TO_PREP: {
            return {
                ...state,
                orders_to_prep: { ...state.orders_to_prep, [action.ffType]: action.data },
            };
        }
        case SET_ORDERS_TO_PREP_ALL: {
            return {
                ...state,
                orders_to_prep_all: { ...state.orders_to_prep_all, [action.ffType]: action.data },
            };
        }
        case SET_ORDERS_TO_SHIP: {
            return {
                ...state,
                orders_to_ship: { ...state.orders_to_ship, [action.ffType]: action.data },
            };
        }
        case SET_ORDERS_TO_SHIP_ALL: {
            return {
                ...state,
                orders_to_ship_all: { ...state.orders_to_ship_all, [action.ffType]: action.data },
            };
        }
        case SET_ORDERS_TO_SHIP_BULK_MANAGEMENT: {
            return {
                ...state,
                orders_to_ship_all: { ...state.orders_to_ship_all, [action.option]: action.data },
            };
        }
        case SET_ORDERS_TO_PREP_BULK_MANAGEMENT: {
            return {
                ...state,
                orders_to_prep_all: { ...state.orders_to_prep_all, bulk_management: action.data },
            };
        }
        case SET_TARIFF_MANAGEMENT: {
            return {
                ...state,
                tariff_management: { ...state.tariff_management, [action.option]: action.data },
            };
        }
        case SET_TARIFF_MANAGEMENT_ALL: {
            return {
                ...state,
                tariff_management_all: { ...state.tariff_management_all, [action.option]: action.data },
            };
        }
        case SET_QUOTE_ORDERS: {
            return {
                ...state,
                quote_orders: { ...state.quote_orders, [action.option]: action.data },
            };
        }
        case SET_QUOTE_ORDERS_ALL: {
            return {
                ...state,
                quote_orders_all: { ...state.quote_orders_all, [action.option]: action.data },
            };
        }
        case SET_WAREHOUSE_FILTERS: {
            const filtersFF = { ...state.warehouseFilters.filtersFF, ...action.data?.filtersFF };

            const updatedData: TWarehouseFilters = {
                markets: { ...state.warehouseFilters.markets, ...action.data?.markets },
                filtersFF,
            };

            localStorage.setItem("filtersFF", JSON.stringify(filtersFF));

            return {
                ...state,
                warehouseFilters: updatedData,
            };
        }
        case SET_LOADING_STATES: {
            return {
                ...state,
                loadingStates: { ...state.loadingStates, ...action.data },
            };
        }
        case SET_QUOTE_ORDERS_DETAILS: {
            return {
                ...state,
                quote_orders_details: { ...state.quote_orders_details, [action.option]: action.data },
            };
        }
        case SET_ALL_SHIPMENT_ORDERS_DETAILS: {
            return {
                ...state,
                all_shipment_orders_details: { ...state.all_shipment_orders_details, [action.option]: action.data },
            };
        }
        case SET_TARIFF_FILTER_MANAGEMENT: {
            return {
                ...state,
                tariff_filter_management: { ...state.tariff_filter_management, [action.option]: action.data },
            };
        }
        case SET_TARIFF_FILTER_MANAGEMENT_ALL: {
            return {
                ...state,
                tariff_filter_management_all: { ...state.tariff_filter_management_all, [action.option]: action.data },
            };
        }
        case SET_SELECTED_DASHBOARD_WAREHOUSE: {
            return {
                ...state,
                selectedDashboardWarehouse: { ...state.selectedDashboardWarehouse, ...action.data },
            };
        }
        case SET_DASHBOARD_WAREHOUSES: {
            return {
                ...state,
                dashboard_warehouses: action.data,
            };
        }
        case SET_MANAGE_PERMISSIONS: {
            return {
                ...state,
                manage_permissions: action.data,
            };
        }
        case SET_PERMISSIONS: {
            return {
                ...state,
                permissions: action.data,
            };
        }
        case SET_OWN_FF_ORDERS_DETAILS: {
            return {
                ...state,
                own_ff_orders_details: { ...state.own_ff_orders_details, ...action.data },
            };
        }
        default:
            return state;
    }
};
export const SetAllIncomingOrders = (data: GetAllIncomingWithTotals): TSetAllIncomingOrders => ({
    type: SET_ALL_INCOMING_ORDERS,
    data,
});
export const SetAllOutgoingOrders = <TData extends TOutgoingOrders>(data: TData, option: TFreightForwarderFilter): TSetAllOutgoingOrders<TData> => ({
    type: SET_ALL_OUTGOING_ORDERS,
    option,
    data,
});
export const SetTenantTotalStored = (data: StoredDict): SetTenantTotalStoredType => ({
    type: SET_TENANT_TOTAL_STORED,
    data,
});
export const SetTenantTotalIncoming = (data: IncomingDict): SetTenantTotalIncomingType => ({
    type: SET_TENANT_TOTAL_INCOMING,
    data,
});
export const SetTenantTotalOutgoing = (data: OutgoingDict): SetTenantTotalOutgoingType => ({
    type: SET_TENANT_TOTAL_OUTGOING,
    data,
});
export const SetUserRoleList = (data: TPlatformMember[]): SetUserRoleListType => ({
    type: SET_USER_ROLE_LIST,
    data,
});
export const SetUserRoleAll = (data: TPlatformMember[]): SetUserRoleListAllType => ({
    type: SET_USER_ROLE_LIST_ALL,
    data,
});
export const SetUserList = (data: GetUserListResult[]): SetUserListType => ({
    type: SET_USER_LIST,
    data,
});
export const SetUserListAll = (data: GetUserListResult[]): SetUserListAllType => ({
    type: SET_USER_LIST_ALL,
    data,
});
export const SetWarehouseList = (data: TWarehouse[]): SetWarehouseListType => ({
    type: SET_WAREHOUSE_LIST,
    data,
});
export const SetWarehouseListAll = (data: TWarehouse[]): SetWarehouseListAllType => ({
    type: SET_WAREHOUSE_LIST_ALL,
    data,
});
export const SetAvaibleIdList = (data: AvailableId[]): SetAvaibleIdListType => ({
    type: SET_AVAIBLE_ID_LIST,
    data,
});
export const SetIncomingShipments = (incoming: GetIncomingShipmentsResult[], suspended: GetIncomingShipmentsResult[]): SetIncomingShipmentsType => ({
    type: SET_INCOMING_SHIPMENTS,
    incoming,
    suspended,
});
export const SetIncomingShipmentsAll = (incoming: GetIncomingShipmentsResult[], suspended: GetIncomingShipmentsResult[]): SetIncomingShipmentsAllType => ({
    type: SET_INCOMING_SHIPMENTS_ALL,
    incoming,
    suspended,
});
export const SetProductList = (data: CartonsStoredResult[]): SetProductListType => ({
    type: SET_PRODUCT_LIST,
    data,
});
export const SetProductListAll = (data: CartonsStoredResult[]): SetProductListAllType => ({
    type: SET_PRODUCT_LIST_ALL,
    data,
});
export const SetTrackingIdList = <TData extends TOrdersToTrackStateItem<TGetTrackingIdResultsItem>>(option: TOrdersFilter, data: TData): TSetTrackingId<TData> => ({
    type: SET_TRACKING_ID_LIST,
    option,
    data,
});
export const SetTrackingIdListAll = <TData extends TOrdersToTrackStateItem<TGetTrackingIdResultsItem>>(option: TOrdersFilter, data: TData): TSetTrackingIdAll<TData> => ({
    type: SET_TRACKING_ID_LIST_ALL,
    option,
    data,
});
export const SetLoading = (loading: boolean): SetLoadingType => ({
    type: SET_LOADING,
    data: loading,
});
export const SetIncomingPrepOrder = (data: IncomingDict): SetIncomingPrepOrderType => ({
    type: SET_INCOMING_PREP_ORDERS_LIST,
    data: data,
});

export const SetStoredPrepOrder = (data: StoredDict): SetStoredPrepOrderType => ({
    type: SET_TOTAL_STORED_PREP_ORDERS_LIST,
    data: data,
});
export const SetOutgoingTotals = (data: OutgoingDict): SetOutgoingTotalsType => ({
    type: SET_OUTGOING_TOTAL,
    data: data,
});
export const SetTenantsList = (data: GetTenantsListType[]): SetTenantsListType => ({
    type: SET_TENANTS_LIST,
    data: data,
});
export const SetAllContainers = (data: GetAllContainersResult[]): SetAllContainersType => ({
    type: SET_ALL_CONTAINERS,
    data: data,
});

export const SetContainerTariffsList = (data: ContainerTariffItem[]): SetContainerTariffsListType => ({
    type: SET_CONTAINER_TARIFFS_LIST,
    data: data,
});

export const SetOrdersToPrep = <TData extends TOrdersToPrepStateItem<TGetOrdersToPrepResultsItem>>(ffType: TFreightForwarderFilter, data: TData): TSetOrdersToPrep<TData> => ({
    type: SET_ORDERS_TO_PREP,
    ffType,
    data,
});
export const SetOrdersToPrepAll = <TData extends TOrdersToPrepStateItem<TGetOrdersToPrepResultsItem>>(
    ffType: TFreightForwarderFilter,
    data: TData
): TSetOrdersToPrepAll<TData> => ({
    type: SET_ORDERS_TO_PREP_ALL,
    ffType,
    data,
});
export const SetOrdersToShip = <TData extends TOrdersToShipStateItem<TGetOrdersToShipResultsItem>>(ffType: TFreightForwarderFilter, data: TData): TSetOrdersToShip<TData> => ({
    type: SET_ORDERS_TO_SHIP,
    ffType,
    data,
});
export const SetOrdersToShipAll = <TData extends TOrdersToShipStateItem<TGetOrdersToShipResultsItem>>(
    ffType: TFreightForwarderFilter,
    data: TData
): TSetOrdersToShipAll<TData> => ({
    type: SET_ORDERS_TO_SHIP_ALL,
    ffType,
    data,
});
export const SetWarehouseFilters = (data: TSetWarehouseFiltersData): TSetWarehouseFilters => ({
    type: SET_WAREHOUSE_FILTERS,
    data,
});

export const SetLoadingStates = (data: { [key in keyof TDashboardWarehouseLoadingStates]?: boolean }): TSetLoadingStates => ({
    type: SET_LOADING_STATES,
    data,
});

export const SetOrdersToShipBulkManagement = (option: "bulk_management" | "verification_file", data: GetOrderToShipResult[]): TSetOrdersToShipBulkManagement => ({
    type: SET_ORDERS_TO_SHIP_BULK_MANAGEMENT,
    option,
    data,
});

export const SetOrdersToPrepBulkManagement = (data: TSKUdropOrderToPrepDetails[]): TSetOrdersToPrepBulkManagement => ({
    type: SET_ORDERS_TO_PREP_BULK_MANAGEMENT,
    data,
});

export const SetTariffManagement = <TData extends TTariffManagementStateItem<TTariffManagementResultItems>>(option: TOrdersFilter, data: TData): TSetTariffManagement<TData> => ({
    type: SET_TARIFF_MANAGEMENT,
    option,
    data,
});

export const SetTariffManagementAll = <TData extends TTariffManagementStateItem<TTariffManagementResultItems>>(
    option: TOrdersFilter,
    data: TData
): TSetTariffManagementAll<TData> => ({
    type: SET_TARIFF_MANAGEMENT_ALL,
    option,
    data,
});

export const SetQuoteOrders = <TData extends TQuoteOrdersStateItem<TQuoteOrder>>(option: TQuoteOrdersFilter, data: TData): TSetQuoteOrders<TData> => ({
    type: SET_QUOTE_ORDERS,
    option,
    data,
});

export const SetQuoteOrdersAll = <TData extends TQuoteOrdersStateItem<TQuoteOrder>>(option: TQuoteOrdersFilter, data: TData): TSetQuoteOrdersAll<TData> => ({
    type: SET_QUOTE_ORDERS_ALL,
    option,
    data,
});

export const SetQuoteOrdersDetails = (option: TQuoteOrdersDetailsOptions, data: TQuoteOrdersDetailsItems | null): TSetQuoteOrdersDetails => ({
    type: SET_QUOTE_ORDERS_DETAILS,
    option,
    data,
});

export const SetOwnFFOrdersDetails = (data: Partial<TOwnFFOrdersDetailsState>): TSetOwnFFOrdersDetails => ({
    type: SET_OWN_FF_ORDERS_DETAILS,
    data,
});

export const SetAdminAllShipmentsDetails = (option: TAdminAllShipmentsDetailsOption, data: TAdminAllShipmentsDetailsItems | null): TAdminAllShipmentsDetails => ({
    type: SET_ALL_SHIPMENT_ORDERS_DETAILS,
    option,
    data,
});

export const SetTariffFilterManagement = (option: "uk", data: TUkContainerItemsToManage[] | null): TSetTariffFilterManagement => ({
    type: SET_TARIFF_FILTER_MANAGEMENT,
    option,
    data,
});

export const SetTariffFilterManagementAll = (option: "uk", data: TUkContainerItemsToManage[] | null): TSetTariffFilterManagementAll => ({
    type: SET_TARIFF_FILTER_MANAGEMENT_ALL,
    option,
    data,
});

export const SetSelectedDashboardWarehouse = (data: Partial<TSelectedDashboardWarehouseState>): TSetSelectedDashboardWarehouse => ({
    type: SET_SELECTED_DASHBOARD_WAREHOUSE,
    data,
});

export const SetDashboardWarehouses = (data: TDashboardWarehousesState): TSetDashboardWarehouses => ({
    type: SET_DASHBOARD_WAREHOUSES,
    data,
});

export const SetManagePermissions = (data: TManagePermissionData): TSetManagePermissions => ({
    type: SET_MANAGE_PERMISSIONS,
    data,
});

export const SetPermissions = (data: TWarehousePermissions): TSetPermissions => ({
    type: SET_PERMISSIONS,
    data,
});

export const getAdminsTotals = (queryParams: { warehouse_custom_id: string }): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            dispatch(SetLoading(true));

            const response = await AdminTotals.getAdminTotals(cookies, queryParams);

            dispatch(SetIncomingPrepOrder(response.data.incoming_dict));
            dispatch(SetOutgoingTotals(response.data.prepared_shipment_dict));
            dispatch(SetStoredPrepOrder(response.data.stored_dict));

            dispatch(SetLoading(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response && e.response.status === 400) {
                displayError(e.response.data);
            }
        }
    };
};

export const ConfirmAuthorizedUserToTenant = (id: string, token: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await confirmAuthorizedUser.ConfirmAuthorizedUserToTenant(id, token);
            console.log(response);
            displaySuccess(response.data.text);
            dispatch(SetLoading(false));
        } catch (e) {
            console.log(e);
            if (axios.isAxiosError(e) && e.response && e.response.status === 400) {
                displayError(e.response.data);
            }
        }
    };
};

export const getLazyAllOrders = (
    option: TAllAdminShipmentsFilter,
    queryParams: TRequestLazySearchParams &
        Partial<{
            search: string;
            integration__country_code?: TSKUdropAllVendorMarkets;
            shipment_items__integration__country_code?: TSKUdropAllVendorMarkets;
            quote_country_code?: TSKUdropAllVendorMarkets;
            fromdate: string;
            todate: string;
            ordering: TWarehouseDashboardOrdering;
            warehouse_custom_id: string;
        }>,
    hasNextItems: (value: boolean) => void
): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            if (option === "skudrop") {
                const response = await orderToShip.getAllOutgoingOrders(cookies, queryParams);
                hasNextItems(!!response.data.next);
                dispatch(SetAllOutgoingOrders(response.data, option));
            } else if (option === "own_ff") {
                const response = await orderToShip.getAllOwnFFOutgoingOrders(cookies, queryParams);
                hasNextItems(!!response.data.next);
                dispatch(SetAllOutgoingOrders(response.data, option));
            } else if (option === "quote_orders") {
                const response = await orderToShip.getAllQuoteOutgoingOrders(cookies, queryParams);
                hasNextItems(!!response.data.next);
                dispatch(SetAllOutgoingOrders(response.data, option));
            } else if (option === "incoming") {
                const response = await incomingShipments.getAllIncomingOrders(cookies, queryParams);
                hasNextItems(!!response.data.next);
                dispatch(
                    SetAllIncomingOrders({
                        results: response.data.results,
                        total_cbm: response.data.total_cbm,
                        total_ctn: response.data.total_ctn,
                    })
                );
            }
        } catch (e) {
            console.log(e);
        }
    };
};

export const deleteUserFromTennant = (cookies: Cookies, id: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingTenantsDetails: true }));

            await tenantList.deleteUserFromTennant(cookies, id);
            dispatch(getUserList());

            dispatch(SetLoadingStates({ isLoadingTenantsDetails: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingTenantsDetails: false }));
                displayError(e.response.data, "Something went wrong while deleting the user");
            }
        }
    };
};

// Roles
export const getUserRoleList = (cookies: Cookies): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingUsersRoles: true }));

            const response = await roleList.getUserRoleList(cookies);

            dispatch(SetUserRoleList(response.data.results));

            dispatch(SetLoadingStates({ isLoadingUsersRoles: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getUserRoleListAll = (cookies: Cookies, items: number, offset: number, setProductAllNext: (value: boolean) => void, search: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            const response = await roleList.getUserRoleListAll(cookies, items, offset, search);
            dispatch(SetUserRoleAll(response.data.results));
            setProductAllNext(response.data.next !== null ? true : false);
        } catch (e) {
            console.log(e);
        }
    };
};

export const updateUserRole = (id: string, data: TEditRoleRequestData): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            dispatch(SetLoadingStates({ isLoadingUsersRoles: true }));

            await roleList.updateUserRole(cookies, id, data);

            dispatch(getUserRoleList(cookies));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingUsersRoles: false }));
                displayError(e.response.data, "Something went wrong while updating the user role");
            }
        }
    };
};

export const addUserRole = (data: TAssignRoleRequestData): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            dispatch(SetLoadingStates({ isLoadingUsersRoles: true }));

            const cookies = getState().auth.cookies;

            await roleList.addUserRole(cookies, data);

            dispatch(getUserRoleList(cookies));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingUsersRoles: false }));
                displayError(e.response.data);
            }
        }
    };
};
export const getUserList = (): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            dispatch(SetLoadingStates({ isLoadingTenantsDetails: true }));

            const response = await tenantList.getUserList(cookies);

            dispatch(SetUserList(response.data.results));

            dispatch(SetLoadingStates({ isLoadingTenantsDetails: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getUserListAll = (
    cookies: Cookies,
    items: number,
    offset: number,
    setUserListAllNext: (value: boolean) => void,
    search: string,
    sortBy?: string
): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            const response = await tenantList.getUserListAll(cookies, items, offset, search, sortBy);
            dispatch(SetUserListAll(response.data.results));
            setUserListAllNext(response.data.next !== null ? true : false);
        } catch (e) {
            console.log(e);
        }
    };
};

export const getPermissions = (userId: string, onSuccess?: () => void): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            dispatch(SetLoadingStates({ isLoadingManagePermissions: true }));

            const response = await tenantList.getPermissions(cookies, userId);

            dispatch(SetManagePermissions(response.data));

            if (onSuccess) {
                onSuccess();
            }

            dispatch(SetLoadingStates({ isLoadingManagePermissions: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingManagePermissions: false }));
                displayError(e.response.data, "Something went wrong while getting the permissions");
            }
        }
    };
};

export const patchUpdatePermissions = (data: TManagePermissionReqData, userId: string): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            dispatch(SetLoadingStates({ isLoadingManagePermissions: true }));

            await tenantList.patchUpdatePermissions(cookies, data, userId);

            dispatch(getPermissions(userId));

            displaySuccess("The permissions have been successfully updated");
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                displayError(e.response.data, "Something went wrong while updating the permissions");
            }
        }
    };
};

export const deleteTenantRole = (email: string): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            dispatch(SetLoading(true));

            await tenantList.deleteTenantRole(cookies, email);

            dispatch(getUserRoleList(cookies));

            dispatch(SetLoading(false));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getWarehouseList = (): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            dispatch(SetLoadingStates({ isLoadingWarehousesDetails: true }));

            const cookies = getState().auth.cookies;

            const response = await tenantList.getWarehouseList(cookies);

            dispatch(SetWarehouseList(response.data.results.warehouses_list));

            dispatch(SetTenantTotalStored(response.data.results.stored_dict));
            dispatch(SetTenantTotalIncoming(response.data.results.incoming_dict));
            dispatch(SetTenantTotalOutgoing(response.data.results.outgoing_dict));

            dispatch(SetLoadingStates({ isLoadingWarehousesDetails: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const PatchWarehouse = (cookies: Cookies, id: string, data: TPatchWarehouse): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingWarehousesDetails: true }));

            await dashboardWarehouseAPI.patchWarehouse(cookies, id, data);

            dispatch(getWarehouseList());
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingWarehousesDetails: false }));
                displayError(e.response.data, "Something went wrong during the warehouse update");
            }
        }
    };
};

export const deleteWarehouse = (cookies: Cookies, id: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingWarehousesDetails: true }));

            await dashboardWarehouseAPI.deleteWarehouse(cookies, id);

            dispatch(getWarehouseList());
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingWarehousesDetails: false }));
                displayError(e.response.data, "Something went wrong when deleting a warehouse");
            }
        }
    };
};

export const getDashboardWarehouses = (user: UserType, queryParams?: { country_code: TSKUdropWarehouseNodes }): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            dispatch(SetLoadingStates({ isLoadingDashboardWarehouses: true }));

            const response = await tenantList.getWarehouseList(cookies, queryParams);

            const separatedWarehouses: TDashboardWarehousesState = response.data.results.warehouses_list?.reduce((acc, warehouse) => {
                const countryCode = warehouse.warehouse_address.country_code;

                if (!acc[countryCode]) {
                    acc[countryCode] = [];
                }

                acc[countryCode].push(warehouse);

                return acc;
            }, {} as TDashboardWarehousesState);

            dispatch(SetSelectedDashboardWarehouse({ CN: separatedWarehouses.CN?.[0] || null, US: separatedWarehouses.US?.[0] || null }));

            dispatch(SetDashboardWarehouses(separatedWarehouses));

            dispatch(setWarehousePermission(user, { CN: separatedWarehouses.CN?.[0] || null, US: separatedWarehouses.US?.[0] || null }));

            dispatch(SetLoadingStates({ isLoadingDashboardWarehouses: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getWarehouseListAll = (cookies: Cookies, items: number, offset: number, setProductAllNext: (value: boolean) => void, search: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            const response = await tenantList.getWarehouseListAll(cookies, items, offset, search);
            dispatch(SetWarehouseListAll(response.data.results.warehouses_list));
            setProductAllNext(response.data.next !== null ? true : false);
        } catch (e) {
            console.log(e);
        }
    };
};

export const getIncomingShipments = (queryParams: {
    shipment_items__integration__country_code?: TSKUdropAllVendorMarkets;
    ordering: TWarehouseDashboardOrdering;
    warehouse_custom_id: string;
}): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            dispatch(SetLoadingStates({ isLoadingIncomingCartons: true }));

            const response = await incomingShipments.getIncomingShipments(cookies, queryParams);

            dispatch(SetIncomingShipments(response.data.incoming_shipments.results, response.data.suspended_incoming_shipments.results));

            dispatch(SetLoadingStates({ isLoadingIncomingCartons: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getIncomingShipmentsAll = (
    queryParams: TRequestLazySearchParams &
        Partial<{
            ordering: TWarehouseDashboardOrdering;
            shipment_items__integration__country_code: TSKUdropAllVendorMarkets;
            warehouse_custom_id: string;
        }>,
    setProductAllNext: (value: boolean) => void,
    setProductAllSuspendedNext: (value: boolean) => void
): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            const response = await incomingShipments.getIncomingShipments(cookies, queryParams);

            setProductAllNext(!!response.data.incoming_shipments.next);
            setProductAllSuspendedNext(!!response.data.suspended_incoming_shipments.next);

            dispatch(SetIncomingShipmentsAll(response.data.incoming_shipments.results, response.data.suspended_incoming_shipments.results));
        } catch (e) {
            console.log(e);
        }
    };
};
export const deleteIncomingShipments = (id: string, incomingMarket: TSKUdropAllVendorMarkets, warehouseCustomID: string): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;
            dispatch(SetLoadingStates({ isLoadingIncomingCartons: true }));
            await incomingShipments.deleteIncomingShipments(cookies, id, { warehouse_custom_id: warehouseCustomID });
            dispatch(
                getIncomingShipments({
                    shipment_items__integration__country_code: incomingMarket === "ALL" ? undefined : incomingMarket,
                    ordering: ADMIN_TABS_DEFAULT_ORDERING,
                    warehouse_custom_id: warehouseCustomID,
                })
            );
        } catch (e) {
            if (axios.isAxiosError(e) && e.response && e.response.status === 400) {
                dispatch(SetLoadingStates({ isLoadingIncomingCartons: false }));
                displayError("You cannot delete this shipment because it is already partially delivered");
            } else if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingIncomingCartons: false }));
                displayError(e.response.data);
            }
        }
    };
};

export const getOrdersToShip = (queryParams: Partial<{ warehouse_custom_id: string; integration__country_code: string }>): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            dispatch(SetLoadingStates({ isLoadingOrdersToShip: true }));
            const response = await orderToShip.getOrdersToShip(cookies, { ...queryParams, ordering: "-created_at" });
            dispatch(SetOrdersToShip("skudrop", { orders: response.data.completed_orders.results, suspended_orders: response.data.suspended_completed_orders.results }));

            dispatch(SetLoadingStates({ isLoadingOrdersToShip: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getOrdersToShipAll = (
    queryParams: TRequestLazySearchParams & Partial<{ ordering: string; integration__country_code: string; warehouse_custom_id: string }>,
    setProductAllNext: (value: boolean) => void,
    setProductAllSuspendedNext: (value: boolean) => void
): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;
            const response = await orderToShip.getOrdersToShipAll(cookies, queryParams);
            setProductAllNext(response.data.completed_orders.next !== null ? true : false);
            setProductAllSuspendedNext(response.data.suspended_completed_orders.next !== null ? true : false);
            dispatch(SetOrdersToShipAll("skudrop", { orders: response.data.completed_orders.results, suspended_orders: response.data.suspended_completed_orders.results }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getProductList = (storedMarket: string, queryParams: Partial<{ warehouse_custom_id: string }>): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;
            dispatch(SetLoadingStates({ isLoadingStoredProducts: true }));
            const response = await productList.getProductList(cookies, storedMarket, queryParams);
            dispatch(SetProductList(response.data.results));
            dispatch(SetLoadingStates({ isLoadingStoredProducts: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getProductListAll = (
    queryParams: TRequestLazySearchParams & Partial<{ ordering: string; integration__country_code: string; warehouse_custom_id: string }>,
    setProductListAllNext: (value: boolean) => void
): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            const response = await productList.getProductListAll(cookies, queryParams);
            dispatch(SetProductListAll(response.data.results));
            setProductListAllNext(response.data.next !== null ? true : false);
        } catch (e) {
            console.log(e);
        }
    };
};

export const patchProductList = (
    data: PatchProductListRequestType,
    carton_id: string,
    storedMarket: string,
    queryParams: Partial<{ warehouse_custom_id: string }>,
    onSuccess: () => void
): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            dispatch(SetLoadingStates({ isLoadingStoredProductDetailsModal: true }));

            await productList.patchProductList(cookies, data, carton_id, queryParams);

            onSuccess();

            dispatch(SetLoadingStates({ isLoadingStoredProductDetailsModal: false }));

            dispatch(getProductList(storedMarket, queryParams));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingStoredProductDetailsModal: false }));
                displayError(e.response.data);
            }
        }
    };
};

export const patchUpdateVendorProductCarton = (
    data: PatchUpdateVendorProductCartonReqData,
    carton_id: string,
    storedMarket: string,
    queryParams: Partial<{ warehouse_custom_id: string }>,
    onSuccess: () => void
): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            dispatch(SetLoadingStates({ isLoadingStoredProductDetailsModal: true }));

            await productList.patchProductList(cookies, data, carton_id, queryParams);

            onSuccess();

            dispatch(SetLoadingStates({ isLoadingStoredProductDetailsModal: false }));

            dispatch(getProductList(storedMarket, queryParams));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingStoredProductDetailsModal: false }));
                displayError(e.response.data);
            }
        }
    };
};

export const deleteHSCodeStoredProduct = (id: string, storedMarket: string, queryParams: Partial<{ warehouse_custom_id: string }>, onSuccess: () => void): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            dispatch(SetLoadingStates({ isLoadingStoredProductDetailsModal: true }));

            await tenantDashboardAPI.deleteProductHSCode(cookies, id);

            onSuccess();

            dispatch(SetLoadingStates({ isLoadingStoredProductDetailsModal: false }));

            dispatch(getProductList(storedMarket, { warehouse_custom_id: queryParams.warehouse_custom_id }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingStoredProductDetailsModal: false }));
                displayError(e.response.data);
            }
        }
    };
};

export const getTariffList = (
    queryParams: Partial<{ warehouse_custom_id: string; outgoing_orders__shipment_speed_type: string | null; outgoing_orders__integration__country_code: string }>,
    setFilterType?: (data: { type: string; id: string; country_code: string }) => void
): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;
            dispatch(SetLoadingStates({ isLoadingTariffManagement: true }));
            const response = await tariffList.getTariffList(cookies, queryParams);
            if (queryParams.outgoing_orders__shipment_speed_type === "AIR" && response.data.orders_to_manage.results.length === 0) {
                displayError("There are no Air Shipments");
            } else {
                dispatch(
                    SetTariffManagement("skudrop", {
                        orders: response.data.orders_to_manage.results,
                        suspended_orders: response.data.suspended_orders_to_manage.results,
                    })
                );
                setFilterType &&
                    setFilterType({
                        type: queryParams.outgoing_orders__shipment_speed_type ? queryParams.outgoing_orders__shipment_speed_type : "",
                        id: "1",
                        country_code: queryParams.outgoing_orders__integration__country_code ? queryParams.outgoing_orders__integration__country_code : "",
                    });
            }
            dispatch(SetLoadingStates({ isLoadingTariffManagement: false }));
        } catch (e) {
            console.log(e);
        }
    };
};
export const getTariffListAll = (
    queryParams: TRequestLazySearchParams &
        Partial<{
            ordering: TWarehouseDashboardOrdering;
            outgoing_orders__shipment_speed_type: string;
            warehouse_custom_id: string;
            outgoing_orders__integration__country_code: string;
        }>,
    SetTariffListAllNext: (data: boolean) => void,
    setTariffListAllSuspendedNext: (data: boolean) => void
): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            const response = await tariffList.getTariffListAll(cookies, queryParams);
            dispatch(
                SetTariffManagementAll("skudrop", {
                    orders: response.data.orders_to_manage.results,
                    suspended_orders: response.data.suspended_orders_to_manage.results,
                })
            );
            SetTariffListAllNext(response.data.orders_to_manage.next !== null ? true : false);
            setTariffListAllSuspendedNext(response.data.suspended_orders_to_manage.next !== null ? true : false);
        } catch (e) {
            console.log(e);
        }
    };
};

export const calculateTariff = (id: string[], filterType: FilterType, tariffMarket: string, warehouseCustomID: string): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;
            dispatch(SetLoadingStates({ isLoadingTariffManagement: true }));
            await dashboardAPI.calculateTariff(cookies, id, filterType?.type);
            if (filterType?.type === "AIR") {
                dispatch(
                    getTariffList({
                        warehouse_custom_id: warehouseCustomID,
                        outgoing_orders__shipment_speed_type: filterType.type,
                        outgoing_orders__integration__country_code: tariffMarket !== "ALL" ? tariffMarket : "",
                    })
                );
            } else if (filterType?.type.includes("Container")) {
                await dispatch(getTariffsForContainer(filterType.id, { warehouse_custom_id: warehouseCustomID }));
                dispatch(getItemsForContainer(filterType.id, { warehouse_custom_id: warehouseCustomID }));
            } else {
                dispatch(
                    getTariffList({
                        warehouse_custom_id: warehouseCustomID,
                        outgoing_orders__integration__country_code: tariffMarket !== "ALL" ? tariffMarket : "",
                        outgoing_orders__shipment_speed_type: null,
                    })
                );
            }
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingTariffManagement: false }));
                displayError(e.response.data, "Something went wrong while calculating the tariff");
            }
        }
    };
};

export const calculateUkTariff = (id: string[], filterType: FilterType, tariffMarket: string, warehouseCustomID: string): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;
            dispatch(SetLoadingStates({ isLoadingTariffManagement: true }));
            await dashboardAPI.calculateTariff(cookies, id, filterType?.type);
            if (filterType?.type === "AIR") {
                dispatch(
                    getTariffList({
                        warehouse_custom_id: warehouseCustomID,
                        outgoing_orders__shipment_speed_type: filterType.type,
                        outgoing_orders__integration__country_code: tariffMarket !== "ALL" ? tariffMarket : "",
                    })
                );
            } else if (filterType?.type.includes("Container")) {
                await dispatch(getTariffsForContainer(filterType.id, { warehouse_custom_id: warehouseCustomID }));
                dispatch(getItemsForContainer(filterType.id, { warehouse_custom_id: warehouseCustomID }));
            } else {
                dispatch(
                    getTariffList({
                        warehouse_custom_id: warehouseCustomID,
                        outgoing_orders__integration__country_code: tariffMarket !== "ALL" ? tariffMarket : "",
                        outgoing_orders__shipment_speed_type: null,
                    })
                );
            }
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingTariffManagement: false }));
                displayError(e.response.data, "Something went wrong while calculating the tariff");
            }
        }
    };
};

export const patchTariffAmount = (id: string, tariff_amount: string, tariffMarket: string, warehouseCustomID: string, filterType?: FilterType): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;
            dispatch(SetLoadingStates({ isLoadingTariffManagement: true }));
            let response = await dashboardAPI.patchTariffAmount(cookies, id, tariff_amount, { warehouse_custom_id: warehouseCustomID });
            if (response.data && filterType?.type === "AIR") {
                dispatch(
                    getTariffList({
                        warehouse_custom_id: warehouseCustomID,
                        outgoing_orders__shipment_speed_type: filterType.type,
                        outgoing_orders__integration__country_code: tariffMarket !== "ALL" ? tariffMarket : "",
                    })
                );
            } else if (response.data && filterType?.type.includes("Container")) {
                await dispatch(getTariffsForContainer(filterType.id, { warehouse_custom_id: warehouseCustomID }));
                dispatch(getItemsForContainer(filterType.id, { warehouse_custom_id: warehouseCustomID }));
            } else {
                dispatch(
                    getTariffList({
                        warehouse_custom_id: warehouseCustomID,
                        outgoing_orders__integration__country_code: tariffMarket !== "ALL" ? tariffMarket : "",
                        outgoing_orders__shipment_speed_type: null,
                    })
                );
            }
        } catch (e) {
            if (axios.isAxiosError(e) && e.response && e.response.status === 400) {
                dispatch(SetLoadingStates({ isLoadingTariffManagement: false }));
                e.response.data["tariff_amount"] && displayError(e.response.data["tariff_amount"][0]);
                e.response.data[0] && displayError(e.response.data[0]);
            } else if (axios.isAxiosError(e) && e.response && e.response.status === 404) {
                await dispatch(
                    getTariffList({
                        warehouse_custom_id: warehouseCustomID,
                        outgoing_orders__integration__country_code: tariffMarket !== "ALL" ? tariffMarket : "",
                        outgoing_orders__shipment_speed_type: null,
                    })
                );
                displayError(e.response.data, "Something went wrong while updating the tariff amount");
            }
        }
    };
};

export const postUkTariffAmount = (data: TPostUkTariffAmountReqData, warehouseCustomID: string): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;
            dispatch(SetLoadingStates({ isLoadingTariffManagement: true }));
            await dashboardAPI.postTariffUkAmount(cookies, data, { warehouse_custom_id: warehouseCustomID });

            await dispatch(getItemsForUkContainer(data.container_id, { warehouse_custom_id: warehouseCustomID }));
            await dispatch(getTariffsForContainer(data.container_id, { warehouse_custom_id: warehouseCustomID }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(getItemsForUkContainer(data.container_id, { warehouse_custom_id: warehouseCustomID }));
                displayError(e.response.data, "Something went wrong while updating the tariff amount");
            }
        }
    };
};

export const createAndConfirmPaymentIntent = (tariffMarket: string, warehouseCustomID: string, formData: FormData): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;
            dispatch(SetLoadingStates({ isLoadingTariffManagement: true }));
            await dashboardAPI.createAndConfirmPaymentIntent(cookies, formData, { warehouse_custom_id: warehouseCustomID });
            await dispatch(
                getTariffList({
                    warehouse_custom_id: warehouseCustomID,
                    outgoing_orders__integration__country_code: tariffMarket !== "ALL" ? tariffMarket : "",
                    outgoing_orders__shipment_speed_type: null,
                })
            );
            if (tariffMarket === "US" || tariffMarket === "UK") {
                await dispatch(getAllContainers({ country_code: tariffMarket, warehouse_custom_id: warehouseCustomID, limit: 1444 }));
            }

            displaySuccess("Charge successful");
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                await dispatch(
                    getTariffList({
                        warehouse_custom_id: warehouseCustomID,
                        outgoing_orders__integration__country_code: tariffMarket !== "ALL" ? tariffMarket : "",
                        outgoing_orders__shipment_speed_type: null,
                    })
                );
                displayError(e.response.data, "Something went wrong while creating payment intent");
            }
        }
    };
};

export const postChargeUKContainerTariff = (tariffMarket: string, data: FormData, warehouseCustomID: string): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            dispatch(SetLoadingStates({ isLoadingTariffManagement: true }));

            await dashboardAPI.postChargeUKContainerTariff(cookies, data, { warehouse_custom_id: warehouseCustomID });
            await dispatch(
                getTariffList({
                    warehouse_custom_id: warehouseCustomID,
                    outgoing_orders__integration__country_code: tariffMarket !== "ALL" ? tariffMarket : "",
                    outgoing_orders__shipment_speed_type: null,
                })
            );
            if (tariffMarket === "US" || tariffMarket === "UK") {
                await dispatch(getAllContainers({ country_code: tariffMarket, warehouse_custom_id: warehouseCustomID, limit: 1444 }));
            }

            displaySuccess("Charge successful");
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                const { cookies } = getState().auth;
                await dispatch(
                    getTariffList({
                        warehouse_custom_id: warehouseCustomID,
                        outgoing_orders__integration__country_code: tariffMarket !== "ALL" ? tariffMarket : "",
                        outgoing_orders__shipment_speed_type: null,
                    })
                );
                displayError(e.response.data, "Something went wrong while creating payment intent");
            }
        }
    };
};

export const postChargeUKContainerTenantGroup = (
    formData: FormData,
    containerID: string,
    warehouseCustomID: string,
    setFilterType: (data: { type: string; id: string; country_code: string } | null) => void
): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            dispatch(SetLoadingStates({ isLoadingTariffManagement: true }));

            await dashboardAPI.createAndConfirmPaymentIntent(cookies, formData, { warehouse_custom_id: warehouseCustomID });

            await dispatch(getAllContainers({ country_code: "UK", warehouse_custom_id: warehouseCustomID, limit: 1444 }));

            const response1 = await tariffList.getItemsForUkContainer(cookies, containerID, {});

            const sortedOrders = (response1.data?.orders_to_manage?.results || []).map((tenant) => ({
                tenant_owner_schema: tenant.tenant_owner_schema,
                outgoing_orders: tenant.outgoing_orders.sort((a, b) => (a.tariff_payed && !b.tariff_payed ? 1 : -1)),
            }));

            dispatch(SetTariffFilterManagement("uk", sortedOrders));

            dispatch(SetLoadingStates({ isLoadingTariffManagement: false }));

            displaySuccess("Charge successful");
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                await dispatch(getAllContainers({ country_code: "UK", warehouse_custom_id: warehouseCustomID, limit: 1444 }));

                if (e.response.status === 404) {
                    setFilterType(null);

                    await dispatch(
                        getTariffList({ warehouse_custom_id: warehouseCustomID, outgoing_orders__integration__country_code: "UK", outgoing_orders__shipment_speed_type: null })
                    );

                    dispatch(SetLoadingStates({ isLoadingTariffManagement: false }));
                } else {
                    await dispatch(getItemsForUkContainer(containerID, { warehouse_custom_id: warehouseCustomID }));

                    displayError(e.response.data, "Something went wrong while creating payment intent");
                }
            }
        }
    };
};

export const getAllContainers = (queryParams: Partial<{ country_code: "US" | "UK"; warehouse_custom_id: string; limit: number }>): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;
            const response = await tariffList.getAllContainers(cookies, queryParams);
            dispatch(SetAllContainers(response.data.results));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getItemsForContainer = (containerId: string, queryParams: Partial<{ warehouse_custom_id: string }>): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;
            dispatch(SetLoadingStates({ isLoadingTariffManagement: true }));
            const response = await tariffList.getItemsForContainer(cookies, containerId, queryParams);

            const outgoingData = response.data.orders_to_manage.map((el) => {
                return { ...el, outgoing_orders: el.outgoing_orders.results };
            });
            const suspendedData = response.data.suspended_orders_to_manage.map((el) => {
                return { ...el, outgoing_orders: el.outgoing_orders.results };
            });

            dispatch(
                SetTariffManagement("skudrop", {
                    orders: outgoingData,
                    suspended_orders: suspendedData,
                })
            );
            dispatch(SetLoadingStates({ isLoadingTariffManagement: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getItemsForUkContainer = (containerId: string, queryParams: Partial<{ warehouse_custom_id: string }>): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            dispatch(SetLoadingStates({ isLoadingTariffManagement: true }));

            const response = await tariffList.getItemsForUkContainer(cookies, containerId, queryParams);

            const sortedOrders = (response.data?.orders_to_manage?.results || []).map((tenant) => ({
                tenant_owner_schema: tenant.tenant_owner_schema,
                outgoing_orders: tenant.outgoing_orders.sort((a, b) => (a.tariff_payed && !b.tariff_payed ? 1 : -1)),
            }));

            dispatch(SetTariffFilterManagement("uk", sortedOrders));
            dispatch(SetLoadingStates({ isLoadingTariffManagement: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getAllItemsForUkContainer = (
    queryParams: TRequestLazySearchParams & Partial<{ ordering: TWarehouseDashboardOrdering; warehouse_custom_id: string }>,
    containerId: string,
    setProductsAllNext: (value: boolean) => void
): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            const response = await tariffList.getItemsForUkContainer(cookies, containerId, queryParams);

            const sortedOrders = (response.data?.orders_to_manage?.results || []).map((tenant) => ({
                tenant_owner_schema: tenant.tenant_owner_schema,
                outgoing_orders: tenant.outgoing_orders.sort((a, b) => (a.tariff_payed && !b.tariff_payed ? 1 : -1)),
            }));

            dispatch(SetTariffFilterManagementAll("uk", sortedOrders));

            setProductsAllNext(!!response.data?.orders_to_manage?.next);
        } catch (e) {
            console.log(e);
        }
    };
};

export const getAllItemsForContainer = (
    containerId: string,
    queryParams: TRequestLazySearchParams & Partial<{ ordering: string; warehouse_custom_id: string }>,
    SetTariffListAllNext: (data: boolean) => void
): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;
            const response = await tariffList.getAllItemsForContainer(cookies, containerId, queryParams);

            const outgoingData = response.data.orders_to_manage.map((el) => {
                return { ...el, outgoing_orders: el.outgoing_orders.results };
            });
            const suspendedData = response.data.suspended_orders_to_manage.map((el) => {
                return { ...el, outgoing_orders: el.outgoing_orders.results };
            });

            dispatch(
                SetTariffManagementAll("skudrop", {
                    orders: outgoingData,
                    suspended_orders: suspendedData,
                })
            );
            SetTariffListAllNext(response.data.orders_to_manage[0].outgoing_orders.next !== null ? true : false);
        } catch (e) {
            console.log(e);
        }
    };
};

export const getTrackingIdList = (queryParams: Partial<{ warehouse_custom_id: string; integration__country_code: string; ordering: string }>): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            dispatch(SetLoadingStates({ isLoadingTrackingIdEntry: true }));

            const response = await trackingId.getTrackingIdList(cookies, queryParams);

            dispatch(SetTrackingIdList("skudrop", { orders: response.data.orders_to_track.results, suspended_orders: response.data.suspended_orders_to_track.results }));

            dispatch(SetLoadingStates({ isLoadingTrackingIdEntry: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getTrackingIdListAll = (
    queryParams: TRequestLazySearchParams & Partial<{ ordering: string; warehouse_custom_id: string; integration__country_code: string }>,
    setStoredProductAllNext: (value: boolean) => void,
    setProductAllSuspendedNext: (value: boolean) => void
): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            const response = await trackingId.getTrackingIdListAll(cookies, queryParams);

            dispatch(SetTrackingIdListAll("skudrop", { orders: response.data.orders_to_track.results, suspended_orders: response.data?.suspended_orders_to_track.results }));

            setStoredProductAllNext(!!response.data.orders_to_track.next);
            setProductAllSuspendedNext(!!response.data.suspended_orders_to_track.next);
        } catch (e) {
            console.log(e);
        }
    };
};

export const patchSetOrderTrackingIDs = (data: TPatchSetOrderTrackingIDsReqData, trackingMarket: string, warehouseCustomID: string): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            dispatch(SetLoadingStates({ isLoadingTrackingIDsManagementModal: true }));

            const { cookies } = getState().auth;

            await trackingId.patchSetOrderTrackingIDs(cookies, data);

            dispatch(SetLoadingStates({ isLoadingTrackingIDsManagementModal: false }));

            dispatch(getTrackingIdList({ integration__country_code: trackingMarket !== "ALL" ? trackingMarket : "", warehouse_custom_id: warehouseCustomID }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingTrackingIDsManagementModal: false }));

                await dispatch(getTrackingIdList({ integration__country_code: trackingMarket !== "ALL" ? trackingMarket : "", warehouse_custom_id: warehouseCustomID }));

                displayError(e.response.data);
            }
        }
    };
};

export const getDownloadBulkTrackingIDs = (queryParams: Partial<{ warehouse_custom_id: string; integration__country_code: string }>): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            dispatch(SetLoadingStates({ isLoadingBulkTrackingIdUpload: true }));

            const response = await trackingId.getDownloadBulkTrackingIDs(cookies, queryParams);

            window.open(response.data || "", "_blank");
            dispatch(SetLoadingStates({ isLoadingBulkTrackingIdUpload: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const postUploadBulkTrackingIDsFile = (data: FormData, trackingMarket: string, warehouseCustomID: string, onSuccess: () => void): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            dispatch(SetLoadingStates({ isLoadingBulkTrackingIdUpload: true }));

            const { cookies } = getState().auth;

            await trackingId.postUploadBulkTrackingIDsFile(cookies, data, {
                integration__country_code: trackingMarket !== "ALL" ? trackingMarket : "",
                warehouse_custom_id: warehouseCustomID,
            });

            dispatch(getTrackingIdList({ integration__country_code: trackingMarket !== "ALL" ? trackingMarket : "", warehouse_custom_id: warehouseCustomID }));
            onSuccess();
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                await dispatch(getTrackingIdList({ integration__country_code: trackingMarket !== "ALL" ? trackingMarket : "", warehouse_custom_id: warehouseCustomID }));
                dispatch(SetLoadingStates({ isLoadingBulkTrackingIdUpload: false }));
                displayError(e.response.data);
            }
        }
    };
};

export const setShippedDate = (cookies: Cookies, id: string, data: SetShippedDateType, trackingMarket: string, warehouseCustomID: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingTrackingIdEntry: true }));

            await trackingId.setShippedDate(cookies, id, data, { warehouse_custom_id: warehouseCustomID });

            dispatch(getTrackingIdList({ integration__country_code: trackingMarket !== "ALL" ? trackingMarket : "", warehouse_custom_id: warehouseCustomID }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingTrackingIdEntry: false }));
                displayError(e.response.data, "Something went wrong while updating the shipped date");
            }
        }
    };
};

export const patchUpdateDeliveryWindow = (
    activeWindowID: string,
    reqData: PatchUpdateDeliveryWindowReqData,
    trackingMarket: string,
    warehouseCustomID: string
): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            dispatch(SetLoadingStates({ isLoadingTrackingIdEntry: true }));

            await trackingId.patchUpdateDeliveryWindow(cookies, activeWindowID, reqData);

            await dispatch(getTrackingIdList({ integration__country_code: trackingMarket !== "ALL" ? trackingMarket : "", warehouse_custom_id: warehouseCustomID }));

            displaySuccess("Delivery window updated successfully");
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingTrackingIdEntry: false }));
                displayError(e.response.data, "Something went wrong while updating the shipped date");
            }
        }
    };
};

export const ConfirmProductAction = (
    formData: FormData,
    incomingMarket: TSKUdropAllVendorMarkets,
    prepMarket: string,
    storedMarket: string,
    warehouseCustomID: string,
    onFilesUpload?: () => void
): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;
            const { permissions } = getState().warehouse;

            dispatch(SetLoadingStates({ isLoadingIncomingCartons: true }));

            await dashboardAPI.ConfirmProductAction(formData, cookies, { warehouse_custom_id: warehouseCustomID });

            dispatch(
                getIncomingShipments({
                    warehouse_custom_id: warehouseCustomID,
                    shipment_items__integration__country_code: incomingMarket === "ALL" ? undefined : incomingMarket,
                    ordering: ADMIN_TABS_DEFAULT_ORDERING,
                })
            );
            if (permissions?.components.inventory !== "none") {
                dispatch(getProductList(storedMarket, { warehouse_custom_id: warehouseCustomID }));
            }
            if (permissions?.components.outbound_orders !== "none") {
                dispatch(
                    getOrdersToPrep({
                        warehouse_custom_id: warehouseCustomID,
                        ordering: ADMIN_TABS_DEFAULT_ORDERING,
                        integration__country_code: prepMarket !== "ALL" ? prepMarket : "",
                    })
                );
            }

            if (onFilesUpload) {
                onFilesUpload();
            }
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                await dispatch(
                    getIncomingShipments({
                        warehouse_custom_id: warehouseCustomID,
                        shipment_items__integration__country_code: incomingMarket === "ALL" ? undefined : incomingMarket,
                        ordering: ADMIN_TABS_DEFAULT_ORDERING,
                    })
                );
                displayError(e.response.data, "Something went wrong during receiving cartons");
            }
        }
    };
};

export const AddNotesIncomingShipments = (data: DataNotesType, cookies: Cookies, incomingMarket: TSKUdropAllVendorMarkets, warehouseCustomID: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingIncomingCartons: true }));
            await dashboardAPI.AddNotesIncomingShipments(data, cookies.get("token"));
            dispatch(
                getIncomingShipments({
                    warehouse_custom_id: warehouseCustomID,
                    shipment_items__integration__country_code: incomingMarket === "ALL" ? undefined : incomingMarket,
                    ordering: ADMIN_TABS_DEFAULT_ORDERING,
                })
            );
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingIncomingCartons: false }));
                displayError(e.response.data, "Something went wrong while adding notes");
            }
        }
    };
};

export const UpdateDownloadInfo = (id: string, data: UpdateDownloadInfoType, incomingMarket: TSKUdropAllVendorMarkets, warehouseCustomID: string): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;
            dispatch(SetLoadingStates({ isLoadingIncomingCartons: true }));
            await dashboardAPI.UpdateDownloadInfo(cookies, id, data, { warehouse_custom_id: warehouseCustomID });
            getIncomingShipments({
                warehouse_custom_id: warehouseCustomID,
                shipment_items__integration__country_code: incomingMarket === "ALL" ? undefined : incomingMarket,
                ordering: ADMIN_TABS_DEFAULT_ORDERING,
            });
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingIncomingCartons: false }));
                displayError(e.response.data, "Something went wrong while updating carton information");
            }
        }
    };
};

export const patchSubmitFinalWeightOrCbm = (
    cookies: Cookies,
    data: TPatchSubmitFinalWeightOrCbmReqData,
    prepMarket: string,
    shipMarket: string,
    trackingMarket: string,
    tariffMarket: string,
    warehouseCustomID: string
): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { permissions } = getState().warehouse;
            dispatch(SetLoadingStates({ isLoadingOrdersToShip: true }));
            await dashboardAPI.patchSubmitFinalWeightOrCbm(cookies, data, { warehouse_custom_id: warehouseCustomID });

            if (permissions?.components.outbound_orders !== "none") {
                dispatch(getOrdersToShip({ warehouse_custom_id: warehouseCustomID, integration__country_code: shipMarket !== "ALL" ? shipMarket : "" }));
                dispatch(getTrackingIdList({ integration__country_code: trackingMarket !== "ALL" ? trackingMarket : "", warehouse_custom_id: warehouseCustomID }));
                dispatch(
                    getOrdersToPrep({
                        warehouse_custom_id: warehouseCustomID,
                        ordering: ADMIN_TABS_DEFAULT_ORDERING,
                        integration__country_code: prepMarket !== "ALL" ? prepMarket : "",
                    })
                );
            }
            if (permissions?.components.tariff_charge !== "none") {
                dispatch(
                    getTariffList({
                        warehouse_custom_id: warehouseCustomID,
                        outgoing_orders__integration__country_code: tariffMarket !== "ALL" ? tariffMarket : "",
                        outgoing_orders__shipment_speed_type: null,
                    })
                );
            }
        } catch (e) {
            const { permissions } = getState().warehouse;
            if (axios.isAxiosError(e) && e.response && e.response.status === 400) {
                dispatch(SetLoadingStates({ isLoadingOrdersToShip: false }));
                displayError(e.response.data);
            } else if (axios.isAxiosError(e) && e.response && e.response.status === 404) {
                if (permissions?.components.outbound_orders !== "none") {
                    dispatch(getOrdersToShip({ warehouse_custom_id: warehouseCustomID, integration__country_code: shipMarket !== "ALL" ? shipMarket : "" }));
                }
                displayError(e.response.data);
            }
        }
    };
};

export const getOrdersToPrep = (
    queryParams: Partial<{ warehouse_custom_id: string; ordering: TWarehouseDashboardOrdering; integration__country_code: string }>
): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            dispatch(SetLoadingStates({ isLoadingOrdersToPrep: true }));

            let response = await orderToPrep.getOrdersToPrep(cookies, queryParams);

            dispatch(SetOrdersToPrep("skudrop", { orders: response.data.prep_orders.results, suspended_orders: response.data.suspended_prep_orders.results }));

            dispatch(SetLoadingStates({ isLoadingOrdersToPrep: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getOrdersToPrepAll = (
    queryParams: TRequestLazySearchParams & Partial<{ warehouse_custom_id: string; ordering: string; integration__country_code: string }>,
    setStoredProductAllNext: (value: boolean) => void,
    setProductAllSuspendedNext: (value: boolean) => void
): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;
            let response = await orderToPrep.getOrdersToPrepAll(cookies, queryParams);
            setStoredProductAllNext(!!response.data.prep_orders.next);
            setProductAllSuspendedNext(!!response.data.suspended_prep_orders.next);
            dispatch(SetOrdersToPrepAll("skudrop", { orders: response.data.prep_orders.results, suspended_orders: response.data.suspended_prep_orders.results }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const postOrderToPrep = (reqData: TPostPrepOrders, cookies: Cookies, prepMarket: string, shipMarket: string, warehouseCustomID: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingOrdersToPrep: true }));
            await orderToPrep.postOrderToPrep(cookies, reqData, { warehouse_custom_id: warehouseCustomID });
            dispatch(
                getOrdersToPrep({
                    warehouse_custom_id: warehouseCustomID,
                    ordering: ADMIN_TABS_DEFAULT_ORDERING,
                    integration__country_code: prepMarket !== "ALL" ? prepMarket : undefined,
                })
            );
            dispatch(getOrdersToShip({ warehouse_custom_id: warehouseCustomID, integration__country_code: shipMarket !== "ALL" ? shipMarket : undefined }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                await dispatch(
                    getOrdersToPrep({
                        warehouse_custom_id: warehouseCustomID,
                        ordering: ADMIN_TABS_DEFAULT_ORDERING,
                        integration__country_code: prepMarket !== "ALL" ? prepMarket : "",
                    })
                );
                displayError(e.response.data, "Something went wrong while saving available cartons");
            }
        }
    };
};

export const patchOrderToShip = (id: string, shipment_id: string, cookies: Cookies, prepMarket: string, shipMarket: string, warehouseCustomID: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingOrdersToShip: true }));
            await orderToShip.patchOrderToShip(id, shipment_id, cookies.get("token"), { warehouse_custom_id: warehouseCustomID });
            dispatch(getOrdersToShip({ warehouse_custom_id: warehouseCustomID, integration__country_code: shipMarket !== "ALL" ? shipMarket : undefined }));
            dispatch(
                getOrdersToPrep({
                    warehouse_custom_id: warehouseCustomID,
                    ordering: ADMIN_TABS_DEFAULT_ORDERING,
                    integration__country_code: prepMarket !== "ALL" ? prepMarket : "",
                })
            );
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                await dispatch(getOrdersToShip({ warehouse_custom_id: warehouseCustomID, integration__country_code: shipMarket !== "ALL" ? shipMarket : undefined }));
                displayError(e.response.data);
            }
        }
    };
};

export const checkIncomingShipment = (
    cookies: Cookies,
    item: GetIncomingShipmentsResult,
    incomingMarket: TSKUdropAllVendorMarkets,
    onSuccess: () => void,
    warehouseCustomID: string
): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            await incomingShipments.checkIncomingShipment(cookies.get("token"), item.id);
            onSuccess();
        } catch (e) {
            if (axios.isAxiosError(e) && e.response?.data) {
                displayError(e.response.data);
                dispatch(
                    getIncomingShipments({
                        warehouse_custom_id: warehouseCustomID,
                        shipment_items__integration__country_code: incomingMarket === "ALL" ? undefined : incomingMarket,
                        ordering: ADMIN_TABS_DEFAULT_ORDERING,
                    })
                );
            }
        }
    };
};

export const checkOutgoingShipment = (
    cookies: Cookies,
    item: TSKUdropOrderToPrepDetails,
    prepMarket: string,
    onSuccess: () => void,
    warehouseCustomID: string
): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            await dashboardAPI.checkOutgoingShipment(cookies.get("token"), item.id);
            onSuccess();
        } catch (e) {
            if (axios.isAxiosError(e) && e.response?.data) {
                dispatch(
                    getOrdersToPrep({
                        warehouse_custom_id: warehouseCustomID,
                        ordering: ADMIN_TABS_DEFAULT_ORDERING,
                        integration__country_code: prepMarket !== "ALL" ? prepMarket : "",
                    })
                );
                displayError(e.response.data);
            }
        }
    };
};

export const getTenantsList = (token: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const res = await userCommunicationAPI.getTenantsList(token);
            if (res.data) {
                dispatch(SetTenantsList(res.data));
                dispatch(SetLoading(false));
            }
        } catch (e) {
            console.log(e);
            dispatch(SetLoading(false));
        }
    };
};

export const createCustomerCommunication = (data: CreateCustomerCommunicationType, token: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            await userCommunicationAPI.createCustomerCommunication(data, token);
            dispatch(SetLoading(false));
            displaySuccess("Communication request was created successfully");
        } catch (e) {
            dispatch(SetLoading(false));
            displayError("Something went wrong during sending communication request");
        }
    };
};

export const postDownloadIncomingTotals = (
    queryParams: Partial<{
        search: string;
        country_code: TSKUdropVendorMarkets;
        date_from: string;
        date_to: string;
    }>
): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            dispatch(SetLoadingStates({ isLoadingAllShipmentTotals: true }));

            const response = await incomingShipments.postDownloadIncomingTotals(cookies, queryParams);

            window.open(response.data.url);

            dispatch(SetLoadingStates({ isLoadingAllShipmentTotals: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingAllShipmentTotals: false }));
                displayError(e.response.data, "Something went wrong while downloading the file");
            }
        }
    };
};

export const downloadPastOutgoingTotals = (
    type: TFreightForwarderFilter,
    queryParams: Partial<{
        search: string;
        country_code: TSKUdropAllVendorMarkets;
        date_from: string;
        date_to: string;
        ordering: TWarehouseDashboardOrdering;
    }>
): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            dispatch(SetLoadingStates({ isLoadingAllShipmentTotals: true }));

            if (type === "skudrop") {
                const response = await orderToShip.downloadSkudropOutgoingTotalFile(cookies, queryParams);

                window.open(response.data.url);
            } else if (type === "own_ff") {
                const response = await orderToShip.downloadOwnFFOutgoingTotalFile(cookies, queryParams);

                window.open(response.data.url);
            } else {
                const response = await orderToShip.downloadQuoteOutgoingTotalFile(cookies, queryParams);

                window.open(response.data.url);
            }

            dispatch(SetLoadingStates({ isLoadingAllShipmentTotals: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingAllShipmentTotals: false }));
                displayError(e.response.data, "Something went wrong while downloading the file");
            }
        }
    };
};

export const downloadProductListFile = (cookies: Cookies, queryParams: Partial<{ country_code: string; warehouse_custom_id: string }>): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingIncomingCartons: true }));
            const response = await AdminTotals.downloadProductListReport(cookies, queryParams);
            window.open(response.data.url);
            dispatch(SetLoadingStates({ isLoadingIncomingCartons: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingIncomingCartons: false }));
                displayError(e.response.data, "Something went wrong while downloading the file");
            }
        }
    };
};

export const getTariffsForContainer = (id: string, queryParams: Partial<{ warehouse_custom_id: string }>): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;
            const response = await dashboardAPI.getTariffsForContainer(cookies, id, queryParams);
            dispatch(SetContainerTariffsList(response.data));
        } catch (e) {
            console.log(e);
        }
    };
};

export const checkOrderLabels = (cookies: Cookies, id: string, prepMarket: string, warehouseCustomID: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingOrdersToPrep: true }));
            await orderToPrep.checkOrderLabels(cookies, id);
            dispatch(
                getOrdersToPrep({
                    warehouse_custom_id: warehouseCustomID,
                    ordering: ADMIN_TABS_DEFAULT_ORDERING,
                    integration__country_code: prepMarket !== "ALL" ? prepMarket : "",
                })
            );
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingOrdersToPrep: false }));
                displayError(e.response.data, "Something went wrong while checking the order labels");
            }
        }
    };
};

// Quote Orders

export const getQuoteOrders = (queryParams: { pending: boolean; warehouse_custom_id: string }): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            dispatch(SetLoadingStates({ isLoadingQuoteOrders: true }));

            const { cookies } = getState().auth;

            const response = await quoteOrders.getQuoteOrders(cookies, { ...queryParams, ordering: ADMIN_TABS_DEFAULT_ORDERING });

            const dataField = queryParams.pending ? "pending_quote_requests" : "quote_requests";

            dispatch(
                SetQuoteOrders(dataField, {
                    orders: response.data[dataField].quote_requests.results,
                    suspended_orders: response.data[dataField].suspended_quote_requests.results,
                })
            );

            dispatch(SetLoadingStates({ isLoadingQuoteOrders: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                displayError(e.response.data);
            }
        }
    };
};

export const getQuoteOrdersAll = (
    queryParams: Partial<TRequestLazySearchParams> & { pending: boolean; warehouse_custom_id: string; ordering: TWarehouseDashboardOrdering },
    setProductAllNext: (value: boolean) => void,
    setProductAllSuspendedNext: (value: boolean) => void
): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            const response = await quoteOrders.getQuoteOrders(cookies, queryParams);

            const dataField = queryParams.pending ? "pending_quote_requests" : "quote_requests";

            dispatch(
                SetQuoteOrdersAll(dataField, {
                    orders: response.data[dataField].quote_requests.results,
                    suspended_orders: response.data[dataField].suspended_quote_requests.results,
                })
            );

            setProductAllNext(!!response.data[dataField].quote_requests.next);
            setProductAllSuspendedNext(!!response.data[dataField].suspended_quote_requests.next);
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                displayError(e.response.data);
            }
        }
    };
};

export const patchConfirmQuoteOrderPrice = (data: TPatchQuoteOrders, id: string, pending: boolean, warehouseCustomID: string): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            dispatch(SetLoadingStates({ isLoadingQuoteOrders: true }));

            await quoteOrders.patchConfirmQuoteOrderPrice(cookies, data, id, { warehouse_custom_id: warehouseCustomID });

            dispatch(getQuoteOrders({ pending, warehouse_custom_id: warehouseCustomID }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                displayError(e.response.data);
            }
        }
    };
};

// Orders to Prep: Own FF

export const getOwnFFOrdersToPrep = (queryParams: Partial<{ warehouse_custom_id: string; ordering: string }>): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;
            dispatch(SetLoadingStates({ isLoadingOrdersToPrep: true }));
            let response = await orderToPrep.getOwnFFOrdersToPrep(cookies, queryParams);
            dispatch(SetOrdersToPrep("own_ff", { orders: response.data.prep_orders.results, suspended_orders: response.data.suspended_prep_orders.results }));
            dispatch(SetLoadingStates({ isLoadingOrdersToPrep: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getOwnFFOrdersToPrepAll = (
    queryParams: TRequestLazySearchParams & Partial<{ warehouse_custom_id: string; ordering: string; integration__country_code: string }>,
    setStoredProductAllNext: (value: boolean) => void,
    setProductAllSuspendedNext: (value: boolean) => void
): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;
            let response = await orderToPrep.getOwnFFOrdersToPrepAll(cookies, queryParams);
            setStoredProductAllNext(!!response.data.prep_orders.next);
            setProductAllSuspendedNext(!!response.data.suspended_prep_orders.next);
            dispatch(SetOrdersToPrepAll("own_ff", { orders: response.data.prep_orders.results, suspended_orders: response.data.suspended_prep_orders.results }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const patchPickUpOwnFFOrderToPrep = (cookies: Cookies, prepMarket: string, id: string, data: { pick_up_time: string }, warehouseCustomID: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingOrdersToPrep: true }));
            await orderToPrep.patchPickUpOwnFFOrderToPrep(cookies, id, data, { warehouse_custom_id: warehouseCustomID });
            dispatch(getOwnFFOrdersToPrep({ warehouse_custom_id: warehouseCustomID, ordering: ADMIN_TABS_DEFAULT_ORDERING }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response?.data) {
                dispatch(SetLoadingStates({ isLoadingOrdersToPrep: false }));
                displayError(e.response.data);
            }
        }
    };
};

export const postCompletedOwnFFOrderToPrep = (
    cookies: Cookies,
    data: TPostCompletedOrderToPrep,
    activeMarkets: { prepMarket: string; shipMarket: string },
    warehouseCustomID: string
): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { warehouseFilters, permissions } = getState().warehouse;

            dispatch(SetLoadingStates({ isLoadingOrdersToPrep: true }));

            await orderToPrep.postCompletedOwnFFOrderToPrep(cookies, data, { warehouse_custom_id: warehouseCustomID });

            dispatch(
                getOwnFFOrdersToPrep({
                    warehouse_custom_id: warehouseCustomID,
                    ordering: ADMIN_TABS_DEFAULT_ORDERING,
                })
            );

            if (warehouseFilters.filtersFF.ordersToShip !== "skudrop" && permissions?.components.shipping_charge !== "none") {
                dispatch(
                    getOwnFFOrdersToShip({
                        warehouse_custom_id: warehouseCustomID,
                        ordering: ADMIN_TABS_DEFAULT_ORDERING,
                    })
                );
            }
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingOrdersToPrep: false, isLoadingOrdersToShip: false }));
                displayError(e.response.data);
            }
        }
    };
};

// Orders to Ship: Own FF

export const getOwnFFOrdersToShip = (queryParams: Partial<{ warehouse_custom_id: string; ordering: string }>): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;
            dispatch(SetLoadingStates({ isLoadingOrdersToShip: true }));
            let response = await orderToShip.getOwnFFOrdersToShip(cookies, queryParams);
            dispatch(SetOrdersToShip("own_ff", { orders: response.data.completed_orders.results, suspended_orders: response.data.suspended_completed_orders.results }));
            dispatch(SetLoadingStates({ isLoadingOrdersToShip: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getOwnFFOrdersToShipAll = (
    queryParams: TRequestLazySearchParams & Partial<{ ordering: string; integration__country_code: string; warehouse_custom_id: string }>,
    setProductAllNext: (value: boolean) => void,
    setProductAllSuspendedNext: (value: boolean) => void
): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;
            let response = await orderToShip.getOwnFFOrdersToShipAll(cookies, queryParams);
            setProductAllNext(!!response.data.completed_orders.next);
            setProductAllSuspendedNext(!!response.data.suspended_completed_orders.next);
            dispatch(SetOrdersToShipAll("own_ff", { orders: response.data.completed_orders.results, suspended_orders: response.data.suspended_completed_orders.results }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const patchProofsOwnFFOrderToShip = (cookies: Cookies, orderId: string, data: FormData, warehouseCustomID: string, hideNotification?: () => void): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingOrdersToShip: true }));
            await orderToShip.patchProofsOwnFFOrderToShip(cookies, orderId, data, { warehouse_custom_id: warehouseCustomID });
            if (hideNotification) {
                hideNotification();
            }
            dispatch(getOwnFFOrdersToShip({ warehouse_custom_id: warehouseCustomID, ordering: ADMIN_TABS_DEFAULT_ORDERING }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingOrdersToShip: false }));
                if (hideNotification) {
                    hideNotification();
                }
                displayError(e.response.data);
            }
        }
    };
};

export const postCompletedOwnFFOrderToShip = (cookies: Cookies, data: TPostCompletedOwnFFOrderToShip, warehouseCustomID: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingOrdersToShip: true }));
            await orderToShip.postCompletedOwnFFOrderToShip(cookies, data, { warehouse_custom_id: warehouseCustomID });
            dispatch(getOwnFFOrdersToShip({ warehouse_custom_id: warehouseCustomID, ordering: ADMIN_TABS_DEFAULT_ORDERING }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingOrdersToShip: false }));
                displayError(e.response.data);
            }
        }
    };
};

// Orders to Ship: Bulk orders management

export const getOrdersToShipBulkManagement = (
    cookies: Cookies,
    mode: "picked_up" | "loaded" | "verification_file",
    queryParams: TRequestLazySearchParams &
        Partial<{ ordering: string; integration__country_code: string }> & {
            warehouse_custom_id: string;
        },
    setProductAllNext: (value: boolean) => void
): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            const response = await orderToShip.getOrdersToShipBulkManagement(cookies, mode, queryParams);
            setProductAllNext(response.data.next !== null ? true : false);
            dispatch(SetOrdersToShipBulkManagement(mode === "verification_file" ? "verification_file" : "bulk_management", response.data.results));
        } catch (e) {
            console.log(e);
        }
    };
};

// Orders to Ship: Verification file

export const postOrdersToShipVerificationFile = (data: TPostOrdersToShipVerificationFile): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            dispatch(SetLoadingStates({ isLoadingOrdersToShip: true }));

            const response = await orderToShip.postOrdersToShipVerificationFile(cookies, data);

            window.open(response.data.url, "_blank");

            dispatch(SetLoadingStates({ isLoadingOrdersToShip: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingOrdersToShip: false }));
                displayError(e.response.data);
            }
        }
    };
};

export const patchBulkPickedUpOrdersToShip = (shipMarket: string, data: TPatchBulkPickedUpOrdersToShipReqData, warehouseCustomID: string): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            dispatch(SetLoadingStates({ isLoadingOrdersToShip: true }));

            const response = await orderToShip.patchBulkPickedUpOrdersToShip(cookies, data, { warehouse_custom_id: warehouseCustomID });
            await dispatch(getOrdersToShip({ warehouse_custom_id: warehouseCustomID, integration__country_code: shipMarket !== "ALL" ? shipMarket : "" }));

            if (response.status === 207) {
                const error = extractRequestErrors(response.data);

                displayToastMessage({ data: error, tostOptions: { type: "warning" } });
            }
        } catch (e) {
            if (axios.isAxiosError(e) && e.response?.data) {
                dispatch(getOrdersToShip({ warehouse_custom_id: warehouseCustomID, integration__country_code: shipMarket !== "ALL" ? shipMarket : "" }));

                displayError(e.response.data);
            }
        }
    };
};

export const patchBulkLoadedOrdersToShip = (
    data: TPatchBulkLoadedOrdersToShipReqData,
    activeMarkets: {
        shipMarket: TWarehouseMarkets;
        trackingMarket: TWarehouseMarkets;
        tariffMarket: TWarehouseMarkets;
    },
    warehouseCustomID: string
): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;
            const { permissions } = getState().warehouse;

            dispatch(SetLoadingStates({ isLoadingOrdersToShip: true }));

            const response = await orderToShip.patchBulkLoadedOrdersToShip(cookies, data, { warehouse_custom_id: warehouseCustomID });
            dispatch(getOrdersToShip({ warehouse_custom_id: warehouseCustomID, integration__country_code: activeMarkets.shipMarket !== "ALL" ? activeMarkets.shipMarket : "" }));
            dispatch(
                getTrackingIdList({ warehouse_custom_id: warehouseCustomID, integration__country_code: activeMarkets.trackingMarket !== "ALL" ? activeMarkets.trackingMarket : "" })
            );
            if (permissions?.components.tariff_charge !== "none") {
                dispatch(
                    getTariffList({
                        warehouse_custom_id: warehouseCustomID,
                        outgoing_orders__integration__country_code: activeMarkets.tariffMarket !== "ALL" ? activeMarkets.tariffMarket : "",
                        outgoing_orders__shipment_speed_type: null,
                    })
                );
            }

            if (response.status === 207) {
                const error = extractRequestErrors(response.data);

                displayToastMessage({ data: error, tostOptions: { type: "warning" } });
            }
        } catch (e) {
            const { permissions } = getState().warehouse;
            if (axios.isAxiosError(e) && e.response?.data) {
                dispatch(
                    getOrdersToShip({ warehouse_custom_id: warehouseCustomID, integration__country_code: activeMarkets.shipMarket !== "ALL" ? activeMarkets.shipMarket : "" })
                );
                dispatch(
                    getTrackingIdList({
                        warehouse_custom_id: warehouseCustomID,
                        integration__country_code: activeMarkets.trackingMarket !== "ALL" ? activeMarkets.trackingMarket : "",
                        ordering: ADMIN_TABS_DEFAULT_ORDERING,
                    })
                );

                if (permissions?.components.tariff_charge !== "none") {
                    dispatch(
                        getTariffList({
                            warehouse_custom_id: warehouseCustomID,
                            outgoing_orders__integration__country_code: activeMarkets.tariffMarket !== "ALL" ? activeMarkets.tariffMarket : "",
                            outgoing_orders__shipment_speed_type: null,
                        })
                    );
                }

                displayError(e.response.data);
            }
        }
    };
};

// Orders to Prep: Bulk orders management

export const getOrdersToPrepBulkManagement = (
    queryParams: TRequestLazySearchParams & Partial<{ warehouse_custom_id: string; ordering: string; integration__country_code: string }>,
    productsAllNext: (value: boolean) => void
): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            let response = await orderToPrep.getOrdersToPrepDetails(cookies, { ...queryParams, details: true });

            productsAllNext(!!response.data.prep_orders.next);

            dispatch(SetOrdersToPrepBulkManagement(response.data.prep_orders.results));
        } catch (e) {
            console.log(e);
        }
    };
};

export const postBulkCompletedOrdersToPrep = (
    markets: {
        prepMarket: TWarehouseMarkets;
        shipMarket: TWarehouseMarkets;
    },
    data: TPostBulkCompletedOrdersToPrep,
    warehouseCustomID: string
): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            dispatch(SetLoadingStates({ isLoadingOrdersToPrep: true }));

            const response = await orderToPrep.postBulkCompletedOrdersToPrep(cookies, data, { warehouse_custom_id: warehouseCustomID });

            dispatch(
                getOrdersToPrep({
                    warehouse_custom_id: warehouseCustomID,
                    ordering: ADMIN_TABS_DEFAULT_ORDERING,
                    integration__country_code: markets.prepMarket !== "ALL" ? markets.prepMarket : "",
                })
            );
            dispatch(getOrdersToShip({ warehouse_custom_id: warehouseCustomID, integration__country_code: markets.shipMarket !== "ALL" ? markets.shipMarket : "" }));

            if (response.status === 207) {
                const error = extractRequestErrors(response.data);

                displayToastMessage({ data: error, tostOptions: { type: "warning" } });
            }
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(
                    getOrdersToPrep({
                        warehouse_custom_id: warehouseCustomID,
                        ordering: ADMIN_TABS_DEFAULT_ORDERING,
                        integration__country_code: markets.prepMarket !== "ALL" ? markets.prepMarket : "",
                    })
                );
                dispatch(getOrdersToShip({ warehouse_custom_id: warehouseCustomID, integration__country_code: markets.shipMarket !== "ALL" ? markets.shipMarket : "" }));

                displayError(e.response.data);
            }
        }
    };
};

// Orders to Prep: Manual Quoting

export const getOrdersToPrepQuoting = (queryParams: TRequestLazySearchParams & Partial<{ warehouse_custom_id: string }>): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            dispatch(SetLoadingStates({ isLoadingOrdersToPrep: true }));

            let response = await orderToPrep.getOrdersToPrepQuoting(cookies, { ...queryParams, ordering: ADMIN_TABS_DEFAULT_ORDERING });

            dispatch(SetOrdersToPrep("quote_orders", { orders: response.data.prep_orders.results, suspended_orders: response.data.suspended_prep_orders.results }));
            dispatch(SetLoadingStates({ isLoadingOrdersToPrep: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getOrdersToPrepQuotingAll = (
    queryParams: TRequestLazySearchParams & Partial<{ warehouse_custom_id: string; ordering: TWarehouseDashboardOrdering }>,
    setProductsAllNext: (value: boolean) => void,
    setProductsAllSuspendedNext: (value: boolean) => void
): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            let response = await orderToPrep.getOrdersToPrepQuotingAll(cookies, queryParams);

            setProductsAllNext(!!response.data.prep_orders.next);
            setProductsAllSuspendedNext(!!response.data.suspended_prep_orders.next);

            dispatch(SetOrdersToPrepAll("quote_orders", { orders: response.data.prep_orders.results, suspended_orders: response.data.suspended_prep_orders.results }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                displayError(e.response.data);
            }
        }
    };
};

export const postCompletedQuoteOrderToPrep = (data: TPostCompletedQuoteOrderToPrep, warehouseCustomID: string): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;
            const ordersToShipFilter = getState().warehouse.warehouseFilters.filtersFF.ordersToShip;

            dispatch(SetLoadingStates({ isLoadingOrdersToPrep: true }));

            await orderToPrep.postCompletedQuoteOrderToPrep(cookies, data, { warehouse_custom_id: warehouseCustomID });

            dispatch(getOrdersToPrepQuoting({ warehouse_custom_id: warehouseCustomID }));

            if (ordersToShipFilter === "quote_orders") {
                dispatch(getOrdersToShipQuoting({ warehouse_custom_id: warehouseCustomID }));
            }
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                displayError(e.response.data);
            }
        }
    };
};

// Orders to Ship: Manual Quoting

export const getOrdersToShipQuoting = (queryParams: TRequestLazySearchParams & Partial<{ warehouse_custom_id: string }>): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            dispatch(SetLoadingStates({ isLoadingOrdersToShip: true }));

            let response = await orderToShip.getOrdersToShipQuoting(cookies, { ...queryParams, ordering: ADMIN_TABS_DEFAULT_ORDERING });

            dispatch(SetOrdersToShip("quote_orders", { orders: response.data.orders_to_ship.results, suspended_orders: response.data.suspended_orders_to_ship.results }));
            dispatch(SetLoadingStates({ isLoadingOrdersToShip: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const patchQuotePickedUpOrdersToShip = (id: string, warehouseCustomID: string): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            dispatch(SetLoadingStates({ isLoadingOrdersToShip: true }));

            const { cookies } = getState().auth;

            await orderToShip.patchQuotePickedUpOrdersToShip(cookies, id, { warehouse_custom_id: warehouseCustomID });

            dispatch(getOrdersToShipQuoting({ warehouse_custom_id: warehouseCustomID }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const patchQuoteLoadedOrdersToShip = (data: TPatchQuoteLoadedOrdersToShipReqData, warehouseCustomID: string): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;
            const { warehouseFilters, permissions } = getState().warehouse;

            dispatch(SetLoadingStates({ isLoadingOrdersToShip: true }));

            await orderToShip.patchQuoteLoadedOrdersToShip(cookies, data, { warehouse_custom_id: warehouseCustomID });

            dispatch(getOrdersToShipQuoting({ warehouse_custom_id: warehouseCustomID }));

            if (warehouseFilters.filtersFF.trackingIdEntry === "quote_orders") {
                dispatch(getTrackingIdQuoting({ warehouse_custom_id: warehouseCustomID }));
            }

            if (warehouseFilters.filtersFF.tariffManagement === "quote_orders" && permissions?.components.tariff_charge !== "none") {
                dispatch(getTariffManagementQuoteOrders({ warehouse_custom_id: warehouseCustomID }));
            }
        } catch (e) {
            console.log(e);
        }
    };
};

export const getOrdersToShipQuotingAll = (
    queryParams: TRequestLazySearchParams & Partial<{ warehouse_custom_id: string; ordering: TWarehouseDashboardOrdering }>,
    setProductsAllNext: (value: boolean) => void,
    setProductsAllSuspendedNext: (value: boolean) => void
): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            let response = await orderToShip.getOrdersToShipQuoting(cookies, queryParams);

            setProductsAllNext(!!response.data.orders_to_ship.next);
            setProductsAllSuspendedNext(!!response.data.suspended_orders_to_ship.next);

            dispatch(SetOrdersToShipAll("quote_orders", { orders: response.data.orders_to_ship.results, suspended_orders: response.data.suspended_orders_to_ship.results }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                displayError(e.response.data);
            }
        }
    };
};

// Tracking ID: Manual Quoting

export const getTrackingIdQuoting = (queryParams: TRequestLazySearchParams & Partial<{ warehouse_custom_id: string }>): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            dispatch(SetLoadingStates({ isLoadingTrackingIdEntry: true }));

            let response = await trackingId.getTrackingIdQuoting(cookies, { ...queryParams, ordering: ADMIN_TABS_DEFAULT_ORDERING });

            dispatch(SetTrackingIdList("quote_orders", { orders: response.data.orders_to_track.results, suspended_orders: response.data.suspended_orders_to_track.results }));

            dispatch(SetLoadingStates({ isLoadingTrackingIdEntry: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getTrackingIdQuotingAll = (
    queryParams: TRequestLazySearchParams & Partial<{ warehouse_custom_id: string; ordering: TWarehouseDashboardOrdering }>,
    setProductsAllNext: (value: boolean) => void,
    setProductsAllSuspendedNext: (value: boolean) => void
): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            let response = await trackingId.getTrackingIdQuotingAll(cookies, queryParams);

            setProductsAllNext(!!response.data.orders_to_track.next);
            setProductsAllSuspendedNext(!!response.data.suspended_orders_to_track.next);

            dispatch(SetTrackingIdListAll("quote_orders", { orders: response.data.orders_to_track.results, suspended_orders: response.data.suspended_orders_to_track.results }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const setQuoteShippedDate = (id: string, data: SetShippedDateType, warehouseCustomID: string): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            dispatch(SetLoadingStates({ isLoadingTrackingIdEntry: true }));

            await trackingId.setQuoteShippedDate(cookies, id, data, { warehouse_custom_id: warehouseCustomID });

            dispatch(getTrackingIdQuoting({ warehouse_custom_id: warehouseCustomID }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingTrackingIdEntry: false }));
                displayError(e.response.data, "Something went wrong while updating the shipped date");
            }
        }
    };
};

export const setQuoteTrackingIdList = (data: SetQuoteTrackingIdListType, warehouseCustomID: string): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            dispatch(SetLoadingStates({ isLoadingTrackingIdEntry: true }));

            await trackingId.setQuoteTrackingIdList(cookies, data, { warehouse_custom_id: warehouseCustomID });

            dispatch(getTrackingIdQuoting({ warehouse_custom_id: warehouseCustomID }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingTrackingIdEntry: false }));
                displayError(e.response.data);
            }
        }
    };
};

export const updateQuoteTrackingIdList = (data: TPatchUpdateQuoteTrackingIdsReqData, warehouseCustomID: string): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            dispatch(SetLoadingStates({ isLoadingTrackingIdEntry: true }));

            await trackingId.updateQuoteTrackingIdLis(cookies, data, { warehouse_custom_id: warehouseCustomID });

            dispatch(getTrackingIdQuoting({ warehouse_custom_id: warehouseCustomID }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingTrackingIdEntry: false }));
                displayError(e.response.data);
            }
        }
    };
};

// Tariff Management: Manual Quoting

export const getTariffManagementQuoteOrders = (queryParams: TRequestLazySearchParams & Partial<{ warehouse_custom_id: string }>): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            dispatch(SetLoadingStates({ isLoadingTariffManagement: true }));

            let response = await tariffList.getTariffManagementQuoteOrders(cookies, { ...queryParams, ordering: ADMIN_TABS_DEFAULT_ORDERING });

            dispatch(SetTariffManagement("quote_orders", { orders: response.data.orders_to_manage.results, suspended_orders: response.data.suspended_orders_to_manage.results }));

            dispatch(SetLoadingStates({ isLoadingTariffManagement: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getTariffManagementQuoteOrdersAll = (
    queryParams: TRequestLazySearchParams & Partial<{ ordering: TWarehouseDashboardOrdering }>,
    setProductsAllNext: (value: boolean) => void,
    setProductsAllSuspendedNext: (value: boolean) => void
): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            let response = await tariffList.getTariffManagementQuoteOrders(cookies, queryParams);

            dispatch(
                SetTariffManagementAll("quote_orders", { orders: response.data.orders_to_manage.results, suspended_orders: response.data.suspended_orders_to_manage.results })
            );

            setProductsAllNext(!!response.data.orders_to_manage.next);
            setProductsAllSuspendedNext(!!response.data.suspended_orders_to_manage.next);
        } catch (e) {
            console.log(e);
        }
    };
};

export const postSetQuoteOrderTotalTariff = (
    reqData: { order_id: string; total_price: number },
    triggerInitialRequest: (() => void) | null,
    warehouseCustomID: string
): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            dispatch(SetLoadingStates({ isLoadingQuoteTariffManagementModal: true }));

            // update quote order total tariff

            await tariffList.postSetQuoteOrderTotalTariff(cookies, reqData, { warehouse_custom_id: warehouseCustomID });

            dispatch(getQuoteOrderDetails("tariff_management", { id: reqData.order_id, warehouse_custom_id: warehouseCustomID }));

            // get request to update base orders

            let response = await tariffList.getTariffManagementQuoteOrders(cookies, { warehouse_custom_id: warehouseCustomID, ordering: ADMIN_TABS_DEFAULT_ORDERING });

            dispatch(SetTariffManagement("quote_orders", { orders: response.data.orders_to_manage.results, suspended_orders: response.data.suspended_orders_to_manage.results }));

            // trigger initial request for lazy loading

            if (triggerInitialRequest) {
                triggerInitialRequest();
            }

            dispatch(SetLoadingStates({ isLoadingQuoteTariffManagementModal: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingQuoteTariffManagementModal: false }));
                displayError(e.response.data);
            }
        }
    };
};

export const postChargeQuoteOrderTariff = (reqData: { outgoing_shipment_ids: string[] }, warehouseCustomID: string): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            dispatch(SetLoadingStates({ isLoadingTariffManagement: true }));

            await tariffList.postChargeQuoteOrderTariff(cookies, reqData, { warehouse_custom_id: warehouseCustomID });

            dispatch(getTariffManagementQuoteOrders({ warehouse_custom_id: warehouseCustomID }));

            displaySuccess("The tariff was successfully charged");
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingTariffManagement: false }));
                displayError(e.response.data);
            }
        }
    };
};

// Manual Quoting: Orders Details

export const getQuoteOrderDetails = (option: TQuoteOrdersDetailsOptions, queryParams: { id: string; warehouse_custom_id: string }): WarehouseThunkType => {
    return async (dispatch, getState) => {
        const loadingStateMap: { [key in TQuoteOrdersDetailsOptions]: keyof TDashboardWarehouseLoadingStates } = {
            order_to_prep: "isLoadingQuotePrepOrderDetails",
            order_to_ship: "isLoadingQuoteShipOrderDetails",
            tracking_id_entry: "isLoadingQuoteTrackingOrderDetails",
            tariff_management: "isLoadingQuoteTariffOrderDetails",
            past_outgoing_order: "isLoadingAllQuoteOutgoingOrderDetails",
            quote_request: "isLoadingQuoteRequestDetails",
        };

        try {
            const { cookies } = getState().auth;

            const apiCallMap = {
                order_to_prep: orderToPrep.getQuotePrepOrderDetails,
                order_to_ship: orderToShip.getQuoteShipOrderDetails,
                tracking_id_entry: trackingId.getQuoteTrackingIdDetails,
                tariff_management: tariffList.getQuoteTariffOrderDetails,
                past_outgoing_order: orderToShip.getAllQuoteOutgoingOrderDetails,
                quote_request: quoteOrders.getQuoteRequestDetails,
            };

            const loadingState = loadingStateMap[option];
            const apiCall = apiCallMap[option];

            if (!loadingState || !apiCall) return;

            dispatch(SetLoadingStates({ [loadingState]: true }));

            const response = await apiCall(cookies, queryParams);

            dispatch(SetQuoteOrdersDetails(option, response.data));
            dispatch(SetLoadingStates({ [loadingState]: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                if (loadingStateMap[option]) {
                    dispatch(SetLoadingStates({ [loadingStateMap[option]]: false }));
                }

                displayError(e.response.data);
            }
        }
    };
};

export const getAllShipmentsRequestDetails = (option: TAdminAllShipmentsDetailsOption, queryParams: { id: string }): WarehouseThunkType => {
    return async (dispatch, getState) => {
        const loadingStateMap: { [key in TAdminAllShipmentsDetailsOption]: keyof TDashboardWarehouseLoadingStates } = {
            own_ff: "isLoadingAllOwnFFOutgoingOrdersDetails",
            quote: "isLoadingAllQuoteOutgoingOrderDetails",
            skudrop: "isLoadingAllSkudropOrdersDetails",
            incoming: "isLoadingAllIncomingOrdersDetails",
        };

        try {
            const { cookies } = getState().auth;

            const apiCallMap = {
                own_ff: quoteOrders.getOwnFFRequestDetails,
                quote: orderToShip.getAllQuoteOutgoingOrderDetails,
                skudrop: orderToPrep.getSKUdropRequestDetails,
                incoming: incomingShipments.getIncomingRequestDetails,
            };

            const loadingState = loadingStateMap[option];
            const apiCall = apiCallMap[option];

            if (!loadingState || !apiCall) return;

            dispatch(SetLoadingStates({ [loadingState]: true }));

            const response = await apiCall(cookies, queryParams);

            dispatch(SetAdminAllShipmentsDetails(option, response.data));
            dispatch(SetLoadingStates({ [loadingState]: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                if (loadingStateMap[option]) {
                    dispatch(SetLoadingStates({ [loadingStateMap[option]]: false }));
                }

                displayError(e.response.data);
            }
        }
    };
};

export const getOwnFFOrderDetails = (option: TOwnFFOrderDetailsOptions, queryParams: { search: string; warehouse_custom_id: string }): WarehouseThunkType => {
    return async (dispatch, getState) => {
        const loadingStateMap: { [key in TOwnFFOrderDetailsOptions]: keyof TDashboardWarehouseLoadingStates } = {
            order_to_prep: "isLoadingOwnFFOrderToPrepDetails",
            order_to_ship: "isLoadingOwnFFOrderToShipDetails",
        };

        try {
            const { cookies } = getState().auth;

            const loadingState = loadingStateMap[option];

            if (!loadingState) return;

            dispatch(SetLoadingStates({ [loadingState]: true }));

            if (option === "order_to_prep") {
                const response = await orderToPrep.getOwnFFOrdersToPrepDetails(cookies, { ...queryParams });

                dispatch(SetOwnFFOrdersDetails({ [option]: response.data.prep_orders.results[0] || response.data.suspended_prep_orders.results[0] || null }));
            } else if (option === "order_to_ship") {
                const response = await orderToShip.getOwnFFOrdersToShipDetails(cookies, { ...queryParams });

                dispatch(SetOwnFFOrdersDetails({ [option]: response.data.completed_orders.results[0] || response.data.suspended_completed_orders.results[0] || null }));
            }

            dispatch(SetLoadingStates({ [loadingState]: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                if (loadingStateMap[option]) {
                    dispatch(SetLoadingStates({ [loadingStateMap[option]]: false }));
                }

                displayError(e.response.data);
            }
        }
    };
};

export const patchTrackingFilesUpload = (formData: FormData, id: string): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            dispatch(SetLoadingStates({ isLoadingTrackingFilesUpload: true }));

            await trackingId.patchTrackingFilesUpload(cookies, formData, id);

            dispatch(getAllShipmentsRequestDetails("skudrop", { id: id }));

            dispatch(SetLoadingStates({ isLoadingTrackingFilesUpload: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingTrackingFilesUpload: false }));
                displayError(e.response.data);
            }
        }
    };
};

// permissions

export const setWarehousePermission = (user: UserType, selectedDashboardWarehouses: Partial<TSelectedDashboardWarehouseState>): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            let permissions: TWarehousePermission = {} as TWarehousePermission;

            if (user?.role === "MANAGER") {
                permissions = {
                    ...defaultManagerPermissions,
                    ...(user.warehouse_permissions?.[selectedDashboardWarehouses["CN"]?.custom_id || ""] || {}),
                };
            } else {
                permissions = adminPermissions;
            }

            const componentKeys: (keyof TWarehousePermissions["components"])[] = [
                "inbound_orders",
                "outbound_orders",
                "quotes_management",
                "inventory",
                "shipments_history",
                "tariff_charge",
                "shipping_charge",
                "off_outbound_orders",
            ];

            dispatch(
                SetPermissions({
                    marketplaces: Object.entries(permissions)
                        .filter(([_, value]) => value === true)
                        .map(([key]) => key) as TWarehousePermissions["marketplaces"],
                    components: Object.fromEntries(componentKeys.map((key) => [key, permissions[key] ?? "none"])) as TWarehousePermissions["components"],
                })
            );

            dispatch(setMarketByPermission());
        } catch (e) {
            console.log(e);
        }
    };
};

export const setMarketByPermission = (): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { permissions, warehouseFilters } = getState().warehouse;

            if (!permissions) return;

            const availableMarkets = Object.fromEntries(
                (["US", "UK", "FR", "DE", "VS"] as TSKUdropVendorMarkets[]).map((market) => [market, permissions.marketplaces.includes(market)])
            );
            const availableTariffMarkets = { US: permissions.marketplaces.includes("US"), UK: permissions.marketplaces.includes("UK") };
            const parsedMarkets = Object.entries(availableMarkets)
                .map(([key, value]) => (value ? key : null))
                .filter((el) => el !== null);

            const parsedTariffMarkets = Object.entries(availableTariffMarkets)
                .map(([key, value]) => (value ? key : null))
                .filter((el) => el !== null);

            const marketToApply: TSKUdropAllVendorMarkets = parsedMarkets.length === 5 ? "ALL" : (parsedMarkets[0] as TSKUdropAllVendorMarkets);
            const tariffMarketToApply: TWarehouseMarkets = parsedTariffMarkets.length === 2 ? "ALL" : (parsedTariffMarkets[0] as TWarehouseMarkets);

            dispatch(
                SetWarehouseFilters({
                    markets: {
                        incomingShipments: marketToApply,
                        ordersToPrep: marketToApply,
                        ordersToShip: marketToApply,
                        storedProducts: marketToApply,
                        tariffManagement: tariffMarketToApply,
                        trackingIdEntry: tariffMarketToApply,
                        allShipments: marketToApply,
                    },
                })
            );

            let filtersFF: Partial<TDashboardWarehouseFilters> = warehouseFilters.filtersFF;

            if (permissions.components.outbound_orders !== "none" && permissions.marketplaces.length > 0) {
                filtersFF = { ...filtersFF, ordersToPrep: "skudrop", ordersToShip: "skudrop", trackingIdEntry: "skudrop" };
            } else if (permissions.components.quotes_management !== "none") {
                filtersFF = { ...filtersFF, ordersToPrep: "quote_orders", ordersToShip: "quote_orders", trackingIdEntry: "quote_orders" };
            } else if (permissions.components.off_outbound_orders !== "none") {
                filtersFF = { ...filtersFF, ordersToPrep: "own_ff", ordersToShip: "own_ff" };
            }

            if (permissions.components.tariff_charge === "none") {
                filtersFF = { ...filtersFF, tariffManagement: "quote_orders" };
            } else if (permissions.components.quotes_management === "none") {
                filtersFF = { ...filtersFF, tariffManagement: "skudrop" };
            }

            if (!permissions.marketplaces.includes("UK") && !permissions.marketplaces.includes("US")) {
                filtersFF = { ...filtersFF, tariffManagement: "quote_orders", trackingIdEntry: "quote_orders" };
            }

            dispatch(SetWarehouseFilters({ filtersFF }));
        } catch (e) {
            console.log(e);
        }
    };
};

export default warehouseReducer;
