import axios from "axios";
import Cookies from "universal-cookie";

import {
    Factory,
    IncomingActionTypes,
    IncomingIntegration,
    IncomingThunkType,
    PrepareToShipResult,
    SetFactoriesType,
    SetIncomingIntegrationsType,
    SetLoadingIntegrationsType,
    TBulkCommercialInvoiceItem,
    TBulkInboundUploadFileData,
    TBulkInboundUploadState,
    TLoadingStates,
    TPrepareToShipState,
    TSetBulkSkuInboundTemplateProducts,
    TSetIncomingWarehouses,
    TSetLoading,
    TSetLoadingStates,
    TSetPrepareToShipProduct,
} from "./types/IncomingTypes/incoming.types";
import { TWarehouse } from "./types/WarehouseTypes/warehouse.types";
import { displayError } from "../hooks/useErrorHandler";
import { sortIntegrationList } from "../components/common/utils";
import { incomingProduct } from "../api/incomingProductApi";

export const SET_INCOMING_TOTAL = "SET_INCOMING_TOTAL";
export const SET_LOADING = "SET_LOADING";
export const SET_PREPARE_TO_SHIP_PRODUCT = "SET_PREPARE_TO_SHIP_PRODUCT";
export const SET_FACTORIES = "SET_FACTORIES";
export const SET_PREPARE_TO_SHIP_PRODUCT_NEW = "SET_PREPARE_TO_SHIP_PRODUCT_NEW";
export const SET_INCOMING_PRODUCT_ALL = "SET_INCOMING_PRODUCT_ALL";
export const SET_INCOMING_PRODUCT_DELETE = "SET_INCOMING_PRODUCT_DELETE";
export const SET_PRODUCT_REQUIRE_ACTION = "SET_PRODUCT_REQUIRE_ACTION";
export const SET_INCOMING_INTEGRATIONS = "SET_INCOMING_INTEGRATIONS";
export const SET_LOADING_INTEGRATIONS = "SET_LOADING_INTEGRATIONS";
export const SET_BULK_SKU_INBOUND_TEMPLATE_PRODUCTS = "SET_BULK_SKU_INBOUND_TEMPLATE_PRODUCTS";
export const SET_LOADING_STATES = "SET_LOADING_STATES";
export const SET_INCOMING_WAREHOUSES = "SET_INCOMING_WAREHOUSES";

type InitialStateType = {
    prepareToShipProduct: null | TPrepareToShipState;
    isLoading: boolean;
    searchFactoryValue: null | Factory[];
    incomingIntegrations: null | IncomingIntegration[];
    isLoadingIntegrations: boolean;
    bulkSkuInboundTemplateProducts: TBulkInboundUploadState | null;
    loadingStates: TLoadingStates;
    incomingWarehouses: TWarehouse[] | null;
};

let initialState: InitialStateType = {
    prepareToShipProduct: null,
    isLoading: false,
    searchFactoryValue: null,
    incomingIntegrations: null,
    isLoadingIntegrations: false,
    bulkSkuInboundTemplateProducts: null,
    loadingStates: {
        isLoadingBulkCommercialInvoice: false,
        isLoadingBulkSkuInboundModal: false,
        isLoadingIncomingAvailableWarehouses: false,
    },
    incomingWarehouses: null,
};

const incomingProductReducer = (state = initialState, action: IncomingActionTypes): InitialStateType => {
    switch (action.type) {
        case SET_INCOMING_INTEGRATIONS: {
            return {
                ...state,
                incomingIntegrations: action.data,
            };
        }
        case SET_PREPARE_TO_SHIP_PRODUCT: {
            return {
                ...state,
                prepareToShipProduct: { ...state?.prepareToShipProduct, [action.integrationId]: action.data },
            };
        }
        case SET_FACTORIES: {
            return {
                ...state,
                searchFactoryValue: action.data,
            };
        }
        case SET_LOADING: {
            return {
                ...state,
                isLoading: action.data,
            };
        }
        case SET_LOADING_INTEGRATIONS: {
            return {
                ...state,
                isLoadingIntegrations: action.data,
            };
        }
        case SET_BULK_SKU_INBOUND_TEMPLATE_PRODUCTS: {
            return {
                ...state,
                bulkSkuInboundTemplateProducts: { ...state?.bulkSkuInboundTemplateProducts, [action.integrationId]: action.data },
            };
        }
        case SET_LOADING_STATES: {
            return {
                ...state,
                loadingStates: { ...state.loadingStates, ...action.data },
            };
        }
        case SET_INCOMING_WAREHOUSES: {
            return {
                ...state,
                incomingWarehouses: action.data,
            };
        }
        default:
            return state;
    }
};

export const SetPrepareToShipProduct = (data: PrepareToShipResult[], integrationId: string): TSetPrepareToShipProduct => ({
    type: SET_PREPARE_TO_SHIP_PRODUCT,
    data,
    integrationId,
});
export const SetFactories = (data: Factory[]): SetFactoriesType => ({
    type: SET_FACTORIES,
    data,
});
export const SetLoading = (loading: boolean): TSetLoading => ({
    type: SET_LOADING,
    data: loading,
});
export const SetIncomingIntegrations = (data: IncomingIntegration[]): SetIncomingIntegrationsType => ({
    type: SET_INCOMING_INTEGRATIONS,
    data,
});
export const SetLoadingIntegrations = (loading: boolean): SetLoadingIntegrationsType => ({
    type: SET_LOADING_INTEGRATIONS,
    data: loading,
});

export const SetBulkSkuInboundTemplateProducts = (data: TBulkInboundUploadFileData | null, integrationId: string): TSetBulkSkuInboundTemplateProducts => ({
    type: SET_BULK_SKU_INBOUND_TEMPLATE_PRODUCTS,
    data,
    integrationId,
});

export const SetLoadingStates = (data: { [key in keyof TLoadingStates]?: boolean }): TSetLoadingStates => ({
    type: SET_LOADING_STATES,
    data,
});

export const SetIncomingWarehouses = (data: TWarehouse[]): TSetIncomingWarehouses => ({
    type: SET_INCOMING_WAREHOUSES,
    data,
});

export const getPrepareToShipProduct = (
    cookies: Cookies,
    integrationId: string,
    searchTerm: string,
    items: number,
    offset: number,
    setProductAllNext: (value: boolean) => void
): IncomingThunkType => {
    return async (dispatch) => {
        try {
            if (!offset) {
                dispatch(SetLoading(true));
            }

            const response = await incomingProduct.getPrepareToShip(cookies, integrationId, searchTerm, items, offset);

            setProductAllNext(!!response.data.next);

            dispatch(SetPrepareToShipProduct(response.data.results, integrationId));
            dispatch(SetFactories(response.data.factories));

            if (!offset) {
                dispatch(SetLoading(false));
            }
        } catch (e) {
            console.log(e);
        }
    };
};

export const postPrepareToShipProduct = (
    cookies: Cookies,
    data: FormData,
    integrationId: string,
    searchTerm: string,
    items: number,
    offset: number,
    setProductAllNext: (value: boolean) => void,
    onSuccess: () => void
): IncomingThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));

            await incomingProduct.postPrepareToShip(cookies, data);

            dispatch(getPrepareToShipProduct(cookies, integrationId, searchTerm, items, offset, setProductAllNext));

            onSuccess();

            dispatch(SetLoading(false));
        } catch (e) {
            if (axios.isAxiosError(e)) {
                displayError(e.response?.data, "Something went wrong...");
            }
        }
    };
};

export const getIncomingIntegrations = (cookies: Cookies): IncomingThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingIntegrations(true));
            const response = await incomingProduct.getIncomingIntegrations(cookies, { limit: 20 });
            dispatch(SetIncomingIntegrations(sortIntegrationList(response.data.results || [])));
            dispatch(SetLoadingIntegrations(false));
        } catch (e) {
            console.log(e);
        }
    };
};

export const postBulkCommercialInvoice = (
    productsToProcess: TBulkCommercialInvoiceItem[],
    invoiceFile: File,
    onSuccess: () => void,
    processUpdatedCommercialInvoice: (updatedProducts: PrepareToShipResult[]) => void
): IncomingThunkType => {
    return async (dispatch, getState) => {
        try {
            dispatch(SetLoadingStates({ isLoadingBulkCommercialInvoice: true }));

            const { cookies } = getState().auth;

            const formData = new FormData();

            formData.append("commercial_invoice", invoiceFile);

            productsToProcess.forEach((product) => {
                formData.append("product_ids", product.id);
            });

            const response = await incomingProduct.postBulkCommercialInvoice(cookies, formData);

            processUpdatedCommercialInvoice(response.data);

            onSuccess();

            dispatch(SetLoadingStates({ isLoadingBulkCommercialInvoice: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingBulkCommercialInvoice: false }));
                displayError(e.response.data);
            }
        }
    };
};

export const getBulkSkuInboundTemplate = (cookies: Cookies, integrationId: string): IncomingThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingBulkSkuInboundModal: true }));

            const response = await incomingProduct.getBulkSkuInboundTemplate(cookies, integrationId);

            window.open(response.data, "_blank");

            dispatch(SetLoadingStates({ isLoadingBulkSkuInboundModal: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingBulkSkuInboundModal: false }));
                displayError(e.response.data);
            }
        }
    };
};

export const postBulkSkuInboundTemplate = (cookies: Cookies, integrationId: string, formData: FormData, onSuccess: () => void): IncomingThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingBulkSkuInboundModal: true }));

            const response = await incomingProduct.postBulkSkuInboundTemplate(cookies, integrationId, formData);

            dispatch(SetBulkSkuInboundTemplateProducts(response.data, integrationId));

            onSuccess();

            dispatch(SetLoadingStates({ isLoadingBulkSkuInboundModal: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingBulkSkuInboundModal: false }));
                displayError(e.response.data);
            }
        }
    };
};

export const getIncomingAvailableWarehouses = (): IncomingThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            dispatch(SetLoadingStates({ isLoadingIncomingAvailableWarehouses: true }));

            const response = await incomingProduct.getIncomingAvailableWarehouses(cookies);

            dispatch(SetIncomingWarehouses(response.data.results));

            dispatch(SetLoadingStates({ isLoadingIncomingAvailableWarehouses: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export default incomingProductReducer;
