import React from "react";
import ReactGA from "react-ga";
import ReactDOM from "react-dom";

import * as Sentry from "@sentry/react";

import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { BrowserRouter, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from "react-router-dom";

import App from "./App";
import store from "./redux/ReduxStore";

import "./index.css";
import "./assets/fonts/AvenirLTStd-Black.ttf";
import "./assets/fonts/AvenirLTStd-Book.ttf";
import "./assets/fonts/AvenirLTStd-Roman.ttf";

import "react-tooltip/dist/react-tooltip.css";
import "react-toastify/dist/ReactToastify.css";

import "rc-slider/assets/index.css";

ReactGA.initialize("AW-11291460806");

window.recaptchaOptions = {
    useRecaptchaNet: true,
};

const ENV = window.location.hostname.includes(".dev.skudrop.com") ? "DEV" : "PROD";

if (ENV === "PROD") {
    console.log = () => {};
    console.warn = () => {};
    console.debug = () => {};
}

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    enabled: process.env.NODE_ENV !== "development",

    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
        Sentry.replayIntegration(),
    ],

    tracesSampleRate: 1.0,

    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

export let rerenderEntireTree = () => {
    ReactDOM.render(
        <React.StrictMode>
            <BrowserRouter>
                <Provider store={store}>
                    <App />
                    <ToastContainer />
                </Provider>
            </BrowserRouter>
        </React.StrictMode>,
        document.getElementById("root")
    );
};

rerenderEntireTree();

store.subscribe(() => {
    rerenderEntireTree();
});
